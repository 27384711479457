import axios from "axios"
import { Dispatch } from "redux"
import Endpoints from "../environments/endpoints"
import { AuthHeader } from "../utils/AuthHeader"
import { Token } from "../utils/StorageKeys"
import {
    getAllQuestionProposalsAction,
    getReportedQuestionsAction,
    getPopularQuestionsAction
} from "../actions/QuestionsActions"
import { QuestionProposalRequest, MinimalQuestionTypeResponse } from "../types/QuestionTypes"
import { UploadedImage } from "../utils/ImageUtils"
import { getEditorsQuestionsAction } from "../actions/QuestionsActions"
import { ExplanationTypeRequest } from "../types/FlagTypes"

const getAllQuestionProposals = (dispatch: Dispatch<any>) => () => {
    axios
        .get(`${Endpoints.apiEndpoint}/proposals`, AuthHeader(localStorage.getItem(Token)))
        .then(response => dispatch(getAllQuestionProposalsAction(response.data)))
        .catch(error => {})
}

const getAllImagesFromQuestionProposal = (dispatch: Dispatch<any>) => (
    images: string[],
    onSuccess: (images?: UploadedImage[]) => void
) => {
    axios
        .all(
            images.map(image =>
                axios.get(image, {
                    responseType: "arraybuffer"
                })
            )
        )
        .then(responses => {
            onSuccess(
                responses.map((response, index) => {
                    const b64image = new Buffer(response.data, "binary").toString("base64")
                    const extension = response.headers["content-type"].split("/")[1]
                    return {
                        filename: `image-${index}.${extension}`,
                        presentation: `data:${response.headers["content-type"]};base64,${b64image}`,
                        b64value: b64image
                    }
                })
            )
        })
        .catch(error => {})
}

const updateQuestionProposal = (dispatch: Dispatch<any>) => (
    proposalId: number,
    questionProposal: QuestionProposalRequest,
    additionalApprove: boolean,
    onSuccess: () => void
) => {
    axios
        .put(
            `${Endpoints.apiEndpoint}/proposals/${proposalId}`,
            questionProposal,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => {
            if (!additionalApprove) {
                getAllQuestionProposals(dispatch)()
            }
            onSuccess()
        })
        .catch(error => {})
}

const approveQuestionProposal = (dispatch: Dispatch<any>) => (proposalId: number, onSuccess: () => void) => {
    axios
        .post(
            `${Endpoints.apiEndpoint}/proposals/${proposalId}/approve`,
            undefined,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => {
            getAllQuestionProposals(dispatch)()
            onSuccess()
        })
        .catch(error => {})
}

const declineQuestionProposal = (dispatch: Dispatch<any>) => (
    proposalId: number,
    payload: ExplanationTypeRequest,
    onSuccess: () => void
) => {
    axios
        .post(
            `${Endpoints.apiEndpoint}/proposals/${proposalId}/decline`,
            payload,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => {
            getAllQuestionProposals(dispatch)()
            onSuccess()
        })
        .catch(error => {})
}

const getQuestionReports = (dispatch: Dispatch<any>) => () => {
    axios
        .get(`${Endpoints.apiEndpoint}/flags/question`, AuthHeader(localStorage.getItem(Token)))
        .then(response => dispatch(getReportedQuestionsAction(response.data)))
        .catch(error => {})
}

const approveQuestionReport = (dispatch: Dispatch<any>) => (
    flagId: number,
    payload: ExplanationTypeRequest,
    onSuccess: () => void
) => {
    axios
        .post(
            `${Endpoints.apiEndpoint}/flags/question/${flagId}/approve`,
            payload,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => {
            getQuestionReports(dispatch)()
            onSuccess()
        })
        .catch(error => {})
}

const dismissQuestionReport = (dispatch: Dispatch<any>) => (
    flagId: number,
    payload: ExplanationTypeRequest,
    onSuccess: () => void
) => {
    axios
        .post(
            `${Endpoints.apiEndpoint}/flags/question/${flagId}/dismiss`,
            payload,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => {
            getQuestionReports(dispatch)()
            onSuccess()
        })
        .catch(error => {})
}

const getPopularQuestions = (dispatch: Dispatch<any>) => () => {
    axios
        .get(`${Endpoints.apiEndpoint}/questions/popular?count=250`, AuthHeader(localStorage.getItem(Token)))
        .then(response => dispatch(getPopularQuestionsAction(response.data)))
        .catch(error => {})
}

const getEditorsQuestions = (dispatch: Dispatch<any>) => () => {
    axios
        .get(`${Endpoints.apiEndpoint}/questions/editors`, AuthHeader(localStorage.getItem(Token)))
        .then(response => dispatch(getEditorsQuestionsAction(response.data)))
        .catch(error => {})
}

const setEditorsChoiceQuestion = (dispatch: Dispatch<any>) => (questionIds: number[], onSuccess: () => void) => {
    axios
        .put(`${Endpoints.apiEndpoint}/questions/editors`, questionIds, AuthHeader(localStorage.getItem(Token)))
        .then(response => {
            getPopularQuestions(dispatch)()
            getEditorsQuestions(dispatch)()
            onSuccess()
        })
        .catch(error => {})
}

const searchQuestionByText = (dispatch: Dispatch<any>) => (
    searchQuery: string,
    onSuccess: (results: MinimalQuestionTypeResponse[]) => void
) => {
    axios
        .get(`${Endpoints.apiEndpoint}/search/questions/${searchQuery}`, AuthHeader(localStorage.getItem(Token)))
        .then(response => onSuccess(response.data))
        .catch(error => {})
}

export default {
    getAllQuestionProposals,
    getAllImagesFromQuestionProposal,
    updateQuestionProposal,
    approveQuestionProposal,
    declineQuestionProposal,
    getQuestionReports,
    approveQuestionReport,
    dismissQuestionReport,
    getPopularQuestions,
    getEditorsQuestions,
    setEditorsChoiceQuestion,
    searchQuestionByText
}
