import React, { useState } from "react"
import { useDispatch } from "react-redux"
import TextField from "@material-ui/core/TextField"
import Styles from "./SchoolContent.style"
import Locales from "../../../../localization/Localization"
import DialogFromMUI from "@material-ui/core/Dialog"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import CloseButton from "../../CloseButton/CloseButton"
import {
    EducationTypeResponse,
    PRIMARY_EDUCATION_ID,
    SECONDARY_EDUCATION_ID,
    TERTIARY_EDUCATION_ID
} from "../../../../types/EducationTypes"
import DialogButton from "../../DialogButton/DialogButton"
import EducationDataAccess from "../../../../data_access/EducationDataAccess"
import { EducationTypeRequest } from "../../../../types/EducationTypes"

interface SchoolContentProps {
    open: boolean
    data?: EducationTypeResponse
    onClose: () => void
    showLoader: () => void
}

const SchoolContent: React.FC<SchoolContentProps> = props => {
    const dispatch = useDispatch()

    const styles = Styles.styles({})
    const textFieldStyle = Styles.textFieldStyle({})
    const smallTextFieldStyle = Styles.smallTextFieldStyle({})

    const [schoolData, setSchoolData] = useState<EducationTypeResponse>({
        id: props.data ? props.data.id : -1,
        name: props.data ? props.data.name : "",
        location: props.data ? props.data.location : "",
        address: props.data ? props.data.address : "",
        type: props.data ? props.data.type : PRIMARY_EDUCATION_ID
    })

    const handleTypeChange = (event: any) => {
        setSchoolData({ ...schoolData, type: event.target.value })
    }

    const handleTextInput = (name: keyof EducationTypeResponse) => (e: any) => {
        setSchoolData({ ...schoolData, [name]: e.target.value })
    }

    const onSave = () => {
        props.showLoader()
        const educationFacilityData: EducationTypeRequest = {
            name: schoolData.name,
            location: schoolData.location,
            address: schoolData.address,
            type: schoolData.type
        }
        if (schoolData.id !== -1) {
            EducationDataAccess.updateEducationalFacility(dispatch)(schoolData.id, educationFacilityData, props.onClose)
        } else {
            EducationDataAccess.createEducationalFacility(dispatch)(educationFacilityData, props.onClose)
        }
    }

    return (
        <DialogFromMUI maxWidth={"lg"} open={props.open}>
            <div className={styles.mainContainer}>
                <CloseButton onClose={props.onClose} />
                <div className={styles.nameContainer}>
                    <TextField
                        classes={textFieldStyle}
                        variant="outlined"
                        label={Locales.institutionName}
                        value={schoolData.name}
                        onChange={handleTextInput("name")}
                        margin="normal"
                    />
                    <TextField
                        classes={textFieldStyle}
                        variant="outlined"
                        label={Locales.address}
                        value={schoolData.address}
                        onChange={handleTextInput("address")}
                        margin="normal"
                    />
                </div>
                <div className={styles.rowContainer}>
                    <TextField
                        classes={smallTextFieldStyle}
                        variant="outlined"
                        label={Locales.town}
                        value={schoolData.location}
                        onChange={handleTextInput("location")}
                    />
                    <Select
                        value={schoolData.type}
                        onChange={handleTypeChange}
                        input={<OutlinedInput labelWidth={0} />}
                    >
                        <MenuItem value={PRIMARY_EDUCATION_ID}>{Locales.elementarySchool}</MenuItem>
                        <MenuItem value={SECONDARY_EDUCATION_ID}>{Locales.highSchool}</MenuItem>
                        <MenuItem value={TERTIARY_EDUCATION_ID}>{Locales.university}</MenuItem>
                    </Select>
                </div>
                <div className={styles.btnContainer}>
                    <DialogButton label={Locales.save} onClick={onSave} />
                </div>
            </div>
        </DialogFromMUI>
    )
}

export default SchoolContent
