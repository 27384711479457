const API_URL = "https://kvizovi.srednja.hr"
const API_PORT = 8080
const APP_URL = "https://cmskvizovi.srednja.hr"
const APP_PORT = 3001
const USERS_APP_URL = "https://kvizovi.srednja.hr"
const USERS_APP_PORT = 3000

const Endpoints = {
    apiEndpoint: `${API_URL}:${API_PORT}`,
    userAppEndpoint: `${USERS_APP_URL}`,
    appEndpoint: `${APP_URL}`,
    appEndpoints: {
        root: "/",
        schools: "/schools",
        questionProposals: "/questionproposals",
        editQuestionProposal: "/editquestionproposals/:proposalid",
        reportedQuestions: "/reportedquestions",
        reportedComments: "/reportedcomments",
        modertorManagement: "/moderatormanagement",
        editorsChoice: "/editorschoice",
        feedbacks: "/feedbacks",
        statistics: "/statistics",
        adminMessage: "/adminmessage"
    }
}

export default Endpoints
