import React, { useState, useEffect } from "react"
import Styles from "./DecisionModal.style"
import {
    TextField,
    Dialog,
    DialogTitle,
    DialogContentText,
    FormControlLabel,
    Radio,
    IconButton
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/CloseRounded"

import DialogButton from "../DialogButton/DialogButton"
import Locales from "../../../localization/Localization"
import { MinimalUserTypeResponse } from "../../../types/UserTypes"
import { withStyles } from "@material-ui/styles"
import { RadioProps } from "@material-ui/core/Radio"
import { DecisionModalProps, GenericResponse } from "../../../types/ModalTypes"
import Endpoints from "../../../environments/endpoints"

const DecisionModal: React.FC<DecisionModalProps> = props => {
    const styles = Styles.styles({})
    const container = Styles.container({})
    const title = Styles.title({})
    const text = Styles.text({})
    const otherInput = Styles.otherInput({})
    const formContainer = Styles.formContainer({})
    const radioLabel = Styles.radioLabel({})
    const radioInputLabel = Styles.radioInputLabel({})

    const radio = Styles.radio
    const CustomRadio = withStyles(radio)((p: RadioProps) => <Radio {...p} />)

    const [option, setOption] = useState<GenericResponse>(undefined)
    const [input, setInput] = useState<string>("")

    useEffect(() => {
        if (!props.open) {
            setInput("")
            setOption(undefined)
        }
    }, [props.open])

    const submit = () => {
        if (props.accept && props.flagId !== undefined) {
            switch (option) {
                case "option1":
                    props.callback(props.flagId, {
                        explanation:
                            props.type === "question" ? Locales.reportQuestionReasonA : Locales.reportCommentReasonA
                    })
                    break
                case "option2":
                    props.callback(props.flagId, {
                        explanation:
                            props.type === "question" ? Locales.reportQuestionReasonB : Locales.reportCommentReasonB
                    })
                    break
                case "option3":
                    props.callback(props.flagId, {
                        explanation:
                            props.type === "question" ? Locales.reportQuestionReasonC : Locales.reportCommentReasonC
                    })
                    break
                case "other":
                    props.callback(props.flagId, { explanation: input })
                    break
                default:
                    break
            }
        } else if (!props.accept && props.flagId !== undefined && props.type !== "questionProposal") {
            props.callback(props.flagId, { explanation: input })
        } else if (props.type === "questionProposal") {
            if (props.flagId !== undefined) {
                switch (option) {
                    case "option1":
                        props.callback(props.flagId, { explanation: Locales.reportQuestionReasonA })
                        break
                    case "option2":
                        props.callback(props.flagId, { explanation: Locales.reportQuestionReasonB })
                        break
                    case "option3":
                        props.callback(props.flagId, { explanation: Locales.reportQuestionReasonC })
                        break
                    case "other":
                        props.callback(props.flagId, { explanation: input })
                        break
                    default:
                        break
                }
            }
        }
    }

    // Converts a person string to <a>
    const createLink = (person: MinimalUserTypeResponse) => (
        <a href={`${Endpoints.userAppEndpoint}/profile/${person.id}`} target="blank">
            {`${person.firstName} ${person.lastName}`}
        </a>
    )

    // Accepts a list of <a> reporters and separates them with ", " unless its the last member of the array
    const printReporters = (reporters: Array<JSX.Element>) => {
        if (reporters.length > 1) {
            return reporters.map(reporter => {
                if (reporter !== reporters[reporters.length - 1]) {
                    return (
                        <>
                            {reporter}
                            {", "}
                        </>
                    )
                }
                return reporter
            })
        }
        return reporters[0]
    }

    const singleOrPlural = (reporters: Array<JSX.Element>, text: string) => {
        if (reporters.length > 1) {
            return text.replace("REPORTER", Locales.reporterPlural)
        }
        return text.replace("REPORTER", Locales.reporterSingle)
    }

    const setTextType = (text: string) => {
        if (props.type === "question") {
            return text.split("TYPE").join(Locales.reportTypeQuestion)
        }
        return text.split("TYPE").join(Locales.reportTypeComment)
    }

    const resolveTitle = () => {
        switch (props.type) {
            case "question":
                return props.accept ? Locales.reportQuestionAcceptTitle : Locales.reportQuestionDismissTitle
            case "comment":
                return props.accept ? Locales.reportCommentAcceptTitle : Locales.reportCommentDismissTitle
            case "questionProposal":
                return Locales.questionProposalDismissTitle
            default:
                break
        }
    }

    const resolveText = () => {
        const reporters = props.reporter.map(reporter => createLink(reporter.flaggedBy))
        if (props.accept && props.reported) {
            const textType = setTextType(Locales.reportAcceptText)
            const splitText = singleOrPlural(reporters, textType).split("%")
            return (
                <>
                    {splitText[0]}
                    {createLink(props.reported)}
                    {splitText[1]}
                    {printReporters(reporters)}
                    {splitText[2]}
                </>
            )
        } else if (!props.accept && props.type !== "questionProposal") {
            const textType = setTextType(Locales.reportDismissText)
            const splitText = singleOrPlural(reporters, textType).split("%")
            return (
                <>
                    {splitText[0]}
                    {printReporters(reporters)}
                    {splitText[1]}
                </>
            )
        }
        return (
            <>
                {Locales.questionProposalDismissText}
                {props.reported ? createLink(props.reported) : ""}
            </>
        )
    }

    const resolveButtonCaption = () => {
        if (props.type === "questionProposal") {
            return Locales.questionProposalDismiss
        }
        return props.accept ? Locales.reportAccept : Locales.reportDismiss
    }

    const isButtonDisabled = () => {
        if (props.type !== "questionProposal" && !props.accept) {
            return input === ""
        }
        return option == null || (option === "other" && input === "")
    }

    const handleRadioChange = (e: any, checked: boolean) => {
        setOption(e.target.value as GenericResponse)
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value)
    }

    const handleInputFocus = (e: any) => {
        setOption("other")
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} id="DecisionModal" classes={container}>
            <div className={styles.closeBtnContainer}>
                <IconButton onClick={props.handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <DialogTitle classes={title}>{resolveTitle()}</DialogTitle>
            <DialogContentText classes={text}>{resolveText()}</DialogContentText>

            {(props.accept || props.type === "questionProposal") && (
                <div className={formContainer.root}>
                    <FormControlLabel
                        value="option1"
                        checked={option === "option1"}
                        control={<CustomRadio />}
                        onChange={handleRadioChange}
                        label={
                            props.type === "question" || props.type === "questionProposal"
                                ? Locales.reportQuestionReasonA
                                : Locales.reportCommentReasonA
                        }
                        classes={radioLabel}
                    />
                    <FormControlLabel
                        value="option2"
                        checked={option === "option2"}
                        control={<CustomRadio />}
                        onChange={handleRadioChange}
                        label={
                            props.type === "question" || props.type === "questionProposal"
                                ? Locales.reportQuestionReasonB
                                : Locales.reportCommentReasonB
                        }
                        classes={radioLabel}
                    />
                    <FormControlLabel
                        value="option3"
                        checked={option === "option3"}
                        control={<CustomRadio />}
                        onChange={handleRadioChange}
                        label={
                            props.type === "question" || props.type === "questionProposal"
                                ? Locales.reportQuestionReasonC
                                : Locales.reportCommentReasonC
                        }
                        classes={radioLabel}
                    />
                    <FormControlLabel
                        value="other"
                        checked={option === "other"}
                        control={<CustomRadio />}
                        classes={radioLabel}
                        label={
                            <TextField
                                variant="outlined"
                                placeholder={Locales.reportReasonOtherPlaceholder}
                                value={input}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                classes={radioInputLabel}
                            />
                        }
                    />
                </div>
            )}
            {!props.accept && props.type !== "questionProposal" && (
                <TextField
                    variant="outlined"
                    value={input}
                    onChange={handleInputChange}
                    classes={otherInput}
                    placeholder={Locales.reportDismissalPlaceHolder}
                />
            )}

            <div className={styles.buttonContainer}>
                <DialogButton alert label={resolveButtonCaption()} onClick={submit} disabled={isButtonDisabled()} />
            </div>
        </Dialog>
    )
}

export default React.memo(DecisionModal)
