import ActionTypes from "../utils/ActionTypes"
import { Cache } from "../types/CacheType"
import { ReportedCommentActionType } from "../actions/CommentsActions"
import { ReportedCommentTypeResponse } from "../types/FlagTypes"

export interface CommentsReducerStateTypes {
    reportedComments: ReportedCommentTypeResponse[]
    reportedCommentsCache: Cache
}

const initialState: CommentsReducerStateTypes = {
    reportedComments: [],
    reportedCommentsCache: undefined
}

const CommentsReducer = (state = initialState, action: ReportedCommentActionType): CommentsReducerStateTypes => {
    switch (action.type) {
        case ActionTypes.getReportedComments: {
            return Object.assign({}, state, {
                reportedComments: action.reportedComments,
                reportedCommentsCache: new Date()
            })
        }
        default: {
            return state
        }
    }
}

export default CommentsReducer
