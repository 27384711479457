import React, { ChangeEvent } from "react"
import TextField from "@material-ui/core/TextField"
import SearchIcon from "@material-ui/icons/SearchRounded"
import Locales from "../../../localization/Localization"
import Styles from "./SearchBar.style"

interface SearchBarProps {
    onChange: (text: string) => void
}

const SearchBar: React.FC<SearchBarProps> = (props) => {
    const inputStyle = Styles.inputStyle({})
    const iconStyle = Styles.iconStyle({})

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value)
    }

    return (
        <div>
            <TextField
                variant="outlined"
                placeholder={Locales.searchPlaceholder}
                classes={inputStyle}
                onChange={handleTextChange}
                InputProps={{ endAdornment: <SearchIcon classes={iconStyle}/> }}
            />
        </div>
    )
}

export default SearchBar
