import React, { useEffect, useState } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { RouteComponentProps } from "react-router"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import Button from "@material-ui/core/Button"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import Typography from "@material-ui/core/Typography"
import Styles from "./FeedbackScreen.style"
import Locales from "../../../localization/Localization"
import { FeedbackReducerState } from "../../../reducers/FeedbackReducer"
import FeedbackDataAccess from "../../../data_access/FeedbackDataAccess"
import Loading from "../../common_components/Loading/Loading";

const FeedbackScreen: React.FC<RouteComponentProps> = props => {
    const dispatch = useDispatch()
    const feedbacks: FeedbackReducerState = useSelector((state: any) => state.FeedbackReducer, shallowEqual)

    const styles = Styles.styles({})
    const questionTypoStyle = Styles.questionTypoStyle({})
    const authorTypoStyle = Styles.authorTypoStyle({})
    const reportTitleTypoStyle = Styles.reportTitleTypoStyle({})
    const dismissButtonStyle = Styles.dismissButtonStyle({})
    const [loading, setLoading] = useState<boolean>(false)

    // const [pageState, setPageState] = useState({
    //     page: 0,
    //     rowsPerPage: 8
    // })

    // const handleChangePage = (event: unknown, newPage: number) => {
    //     setPageState({ ...pageState, page: newPage })
    // }

    // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setPageState({ rowsPerPage: +event.target.value, page: 0 })
    // }

    // const [state, setState] = useState<UserManagementState>({
    //     openDialog: false,
    //     dialogContent: undefined
    // })


    useEffect(() => {
        FeedbackDataAccess.getFeedbacks(dispatch)()
        //eslint-disable-next-line
    }, [])

    const showLoader = () => setLoading(true)
    const hideLoader = () => setLoading(false)

    const onResolve = (feedbackId: number) => () => {
        showLoader()
        FeedbackDataAccess.resolveFeedback(dispatch)(feedbackId, hideLoader)
    }

    return (
        <div>
            {feedbacks.feedbacks.length > 0 ? (
                feedbacks.feedbacks.map((feedback, index) => {
                    return (
                        <ExpansionPanel key={index}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                                <Typography
                                    classes={questionTypoStyle}
                                >{`${feedback.firstName} ${feedback.lastName}`}</Typography>
                                <Typography classes={authorTypoStyle}>{feedback.email}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className={styles.detailsContainer}>
                                    <Typography classes={reportTitleTypoStyle}>{`${Locales.message}:`}</Typography>
                                    <Typography>{feedback.message}</Typography>
                                    <div className={styles.actionsContainer}>
                                        <Button classes={dismissButtonStyle} onClick={onResolve(feedback.id)}>
                                            {Locales.delete}
                                        </Button>
                                    </div>
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )
                })
            ) : (
                <Typography classes={questionTypoStyle}>{Locales.thereIsNoFeedbacks}</Typography>
            )}
            {loading && <Loading fullScreen />}
        </div>
    )
}

export default FeedbackScreen
