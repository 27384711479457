import { makeStyles, Theme, createStyles } from "@material-ui/core"

const imageStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%"
        }
    })
)

export default {
    imageStyle
}
