import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import FontWeights from "../../../utils/FontWeights"
import { OrangeButtonProps } from "./OrangeButton"

const buttonStyles = (props: OrangeButtonProps) => {
    return makeStyles((theme: Theme) =>
        createStyles({
            root: {
                height: props.height,
                width: props.fullWidth ? "100%" : "unset",
                minWidth: 120,
                display: props.centered ? "flex" : "unset",
                margin: props.centered ? "0 auto" : "0"
            },
            label: {
                textTransform: "none",
                fontSize: props.fontSize || "1rem",
                color: theme.palette.common.white,
                fontWeight: props.bold ? FontWeights.BOLD : "unset"
            }
        })
    )
}

export default {
    buttonStyles
}
