import { UserTypeResponse } from "../types/UserTypes"
import ActionTypes from "../utils/ActionTypes"
import { ActionType } from "../types/ActionType"

export interface MyUserActionType extends ActionType {
    user: UserTypeResponse
}

export const getMyUserAction = (user: UserTypeResponse): MyUserActionType => {
    return { type: ActionTypes.getMyUser, user }
}
