import { makeStyles, createStyles, Theme } from "@material-ui/core"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            display: "flex",
            flexDirection: "column",
            paddingLeft: 16,
            paddingRight: 16
        },
        closeBtnContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
        },
        nameContainer: {
            width: "100%"
        },
        btnContainer: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            paddingTop: 36,
            paddingBottom: 20
        },
        rowContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "space-between"
        }
    })
)

const textFieldStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "inherit"
        }
    })
)

const smallTextFieldStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "50%",
            marginTop: 28
        }
    })
)

export default {
    styles,
    textFieldStyle,
    smallTextFieldStyle
}
