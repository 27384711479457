import axios from "axios"
import { Dispatch } from "redux"
import Endpoints from "../environments/endpoints"
import { AuthHeader } from "../utils/AuthHeader"
import { Token } from "../utils/StorageKeys"
import { getMyUserAction } from "../actions/UsersActions"
import { PaginatedUsersResponse } from "../types/UserTypes"

const getAllUsers = (dispatch: Dispatch<any>) => (
    page: number,
    pageSize: number,
    onSuccess: (users: PaginatedUsersResponse) => void
) => {
    axios
        .get(
            `${Endpoints.apiEndpoint}/users?page=${page}&pageSize=${pageSize}`,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => onSuccess(response.data))
        .catch(error => {})
}

const searchUsers = (dispatch: Dispatch<any>) => (
    query: string,
    page: number,
    pageSize: number,
    onSuccess: (users: PaginatedUsersResponse) => void
) => {
    axios
        .get(
            `${Endpoints.apiEndpoint}/search/users/${query}?page=${page}&pageSize=${pageSize}`,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => onSuccess(response.data))
        .catch(error => {})
}

const getMyUser = (dispatch: Dispatch<any>) => () => {
    axios
        .get(`${Endpoints.apiEndpoint}/users/me`, AuthHeader(localStorage.getItem(Token)))
        .then(response => dispatch(getMyUserAction(response.data)))
        .catch(error => {})
}

const setUserRoles = (dispatch: Dispatch<any>) => (userId: number, roles: string[], onSuccess: () => void) => {
    axios
        .put(
            `${Endpoints.apiEndpoint}/users/roles/${userId}`,
            {
                roles: roles
            },
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => onSuccess())
        .catch(error => {})
}

const deleteUser = (dispatch: Dispatch<any>) => (
    userId: number,
    onSuccess: (users: PaginatedUsersResponse) => void
) => {
    axios
        .delete(`${Endpoints.apiEndpoint}/users/${userId}`, AuthHeader(localStorage.getItem(Token)))
        .then(response => onSuccess(response.data))
        .catch(error => {})
}

export default {
    getAllUsers,
    getMyUser,
    setUserRoles,
    searchUsers,
    deleteUser
}
