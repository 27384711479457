import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            justifyContent: "flex-end"
        }
    })
)
export default {
    styles
}
