import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import Colors from "../../../../utils/Colors"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        btnContainer: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            paddingTop: 36,
            paddingBottom: 20
        }
    })
)

const infoLabelStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.dialogInfoLabelFont
        }
    })
)

export default {
    styles,
    infoLabelStyle
}
