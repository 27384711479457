import ActionTypes from "../utils/ActionTypes"
import { EducationTypeResponse } from "../types/EducationTypes"
import { ActionType } from "../types/ActionType"

export interface EducationalFacilitiesAction extends ActionType {
    educationalFacilities: EducationTypeResponse[]
}

export const getAllEducationalFacilitiesAction = (
    educationalFacilities: EducationTypeResponse[]
): EducationalFacilitiesAction => {
    return { type: ActionTypes.getAllEducationalFacilities, educationalFacilities }
}
