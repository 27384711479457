import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

const styles = (helperText?: string) => {
    return makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: "100%"
            },
            input: {
                display: "flex"
            },
            valueContainer: {
                display: "flex",
                flexWrap: "wrap",
                flex: 1,
                alignItems: "center"
            },
            noOptionsMessage: {
                padding: theme.spacing(1, 2)
            },
            singleValue: {
                fontSize: 14
            },
            paper: {
                position: "absolute",
                marginTop: helperText ? -24 : -8,
                zIndex: 2,
                left: 0,
                right: 0
            },
            imageIcon: {
                marginRight: 8
            },
            subText: {
                fontSize: 10,
                marginLeft: 10,
            }
        })
    )
}

export default {
    styles
}
