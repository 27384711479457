import { makeStyles, createStyles, Theme } from "@material-ui/core"
import Colors from "../../../utils/Colors"
import FontWeights from "../../../utils/FontWeights"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        actionsContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: 12
        },
        searchContainer: {
            display: "flex",
            alignItems: "center"
        },
        buttonContainer: {
            marginLeft: 12
        }
    })
)

const headerCellStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 16,
            color: Colors.headerCellFont
        }
    })
)

const headerTopicCellStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 16,
            color: Colors.headerCellFont,
            width: "25%"
        }
    })
)

const headerDifficultyCellStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 16,
            color: Colors.headerCellFont,
            width: "6%"
        }
    })
)

const authorCellStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 16,
            color: Colors.headerCellFont,
            width: "15%"
        }
    })
)

const checkBoxCellStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "6%",
            color: Colors.checkbox,
            paddingRight: 0
        }
    })
)

const checkBoxStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.checkbox
        }
    })
)

const picIconCellStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "6%",
            color: Colors.picIcon
        }
    })
)

const difficultyTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.difficultyFont,
            fontWeight: FontWeights.BOLD
        }
    })
)

const topicTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.topicFont,
            padding: "unset"
        }
    })
)

const subtopicTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.subtopicFont,
            padding: "unset",
            paddingLeft: 10
        }
    })
)

const authorTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.authorFont,
            fontWeight: FontWeights.BOLD
        }
    })
)

export default {
    styles,
    headerCellStyle,
    checkBoxCellStyle,
    picIconCellStyle,
    authorCellStyle,
    authorTypoStyle,
    topicTypoStyle,
    subtopicTypoStyle,
    difficultyTypoStyle,
    checkBoxStyle,
    headerTopicCellStyle,
    headerDifficultyCellStyle
}
