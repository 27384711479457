import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import { DialogButtonProps } from "./DialogButton"
import Colors from "../../../utils/Colors"
import FontWeights from "../../../utils/FontWeights"

const buttonStyle = (props: DialogButtonProps) => {
    return makeStyles((theme: Theme) =>
        createStyles({
            root: {
                backgroundColor: props.alert ? Colors.alertButton : Colors.normalButton,
                fontWeight: FontWeights.BOLD,
                color: Colors.buttonFont
            }
        })
    )
}

export default {
    buttonStyle
}
