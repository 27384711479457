import React from "react"
import DialogFromMUI from "@material-ui/core/Dialog"
import Styles from "./ImageDialog.style"

interface ImageDialogProps {
    open: boolean
    imagePath: string
    onClose: () => void
}

const ImageDialog: React.FC<ImageDialogProps> = props => {
    const imageStyle = Styles.imageStyle({})

    return (
        <DialogFromMUI maxWidth={"lg"} open={props.open} onClose={props.onClose}>
            <div>
                <img src={props.imagePath} alt="question" className={imageStyle.root} />
            </div>
        </DialogFromMUI>
    )
}

export default ImageDialog
