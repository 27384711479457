import axios from "axios"
import { Dispatch } from "redux"
import Endpoints from "../environments/endpoints"
import { AuthHeader } from "../utils/AuthHeader"
import { Token } from "../utils/StorageKeys"
import { getReportedCommentsAction } from "../actions/CommentsActions"
import { ExplanationTypeRequest } from "../types/FlagTypes"

const getCommentReports = (dispatch: Dispatch<any>) => () => {
    axios
        .get(`${Endpoints.apiEndpoint}/flags/comment`, AuthHeader(localStorage.getItem(Token)))
        .then(response => dispatch(getReportedCommentsAction(response.data)))
        .catch(error => {})
}

const approveCommentReport = (dispatch: Dispatch<any>) => (
    flagId: number,
    payload: ExplanationTypeRequest,
    onSuccess: () => void
) => {
    axios
        .post(
            `${Endpoints.apiEndpoint}/flags/comment/${flagId}/approve`,
            payload,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => {
            getCommentReports(dispatch)()
            onSuccess()
        })
        .catch(error => {})
}

const dismissCommentReport = (dispatch: Dispatch<any>) => (
    flagId: number,
    payload: ExplanationTypeRequest,
    onSuccess: () => void
) => {
    axios
        .post(
            `${Endpoints.apiEndpoint}/flags/comment/${flagId}/dismiss`,
            payload,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => {
            getCommentReports(dispatch)()
            onSuccess()
        })
        .catch(error => {})
}

export default {
    getCommentReports,
    approveCommentReport,
    dismissCommentReport
}
