import React from "react"
import Style from "./SideDrawer.style"
import Locales from "../../../localization/Localization"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Endpoints from "../../../environments/endpoints"
import { RouteComponentProps, withRouter } from "react-router"

interface MenuItem {
    text: string
    route: string
}

interface SideDrawerProps extends RouteComponentProps {
    role: "ADMIN" | "MODERATOR" | "EDITOR"
    toggleDrawer: () => void
    open: boolean
}

const SideDrawer: React.FC<SideDrawerProps> = props => {
    const styles = Style.styles({})
    const listStyle = Style.listStyle({})

    const theme = useTheme()
    const matchesSmallerThanXS = useMediaQuery(theme.breakpoints.down("xs"))

    const adminMenu: MenuItem[] = [
        { text: Locales.userManagement, route: Endpoints.appEndpoints.root },
        { text: Locales.schoolManagement, route: Endpoints.appEndpoints.schools },
        { text: Locales.questionManagement, route: Endpoints.appEndpoints.questionProposals },
        { text: Locales.reportedQuestions, route: Endpoints.appEndpoints.reportedQuestions },
        { text: Locales.reportedComments, route: Endpoints.appEndpoints.reportedComments },
        { text: Locales.editorsChoice, route: Endpoints.appEndpoints.editorsChoice },
        { text: Locales.feedbacks, route: Endpoints.appEndpoints.feedbacks },
        { text: Locales.statistics, route: Endpoints.appEndpoints.statistics },
        { text: Locales.adminMessage, route: Endpoints.appEndpoints.adminMessage }
    ]

    const moderatorMenu: MenuItem[] = [
        { text: Locales.questionManagement, route: Endpoints.appEndpoints.questionProposals },
        { text: Locales.reportedQuestions, route: Endpoints.appEndpoints.reportedQuestions },
        { text: Locales.reportedComments, route: Endpoints.appEndpoints.reportedComments }
    ]

    const editorMenu: MenuItem[] = [{ text: Locales.editorsChoice, route: Endpoints.appEndpoints.editorsChoice }]

    const goToComponent = (route: string) => (event: any) => {
        props.history.push(route)
        if (matchesSmallerThanXS) {
            props.toggleDrawer()
        }
    }

    const menu: MenuItem[] =
        props.role === "ADMIN" ? adminMenu : props.role === "MODERATOR" ? moderatorMenu : editorMenu

    return (
        <Drawer open={props.open} className={styles.drawer} anchor="left" variant="persistent">
            <div className={styles.toolbarSpace} />
            <List classes={listStyle} disablePadding>
                {menu.map((field, index) => (
                    <ListItem key={index} onClick={goToComponent(field.route)} button>
                        <ListItemText primary={field.text} />
                        <Divider />
                    </ListItem>
                ))}
            </List>
        </Drawer>
    )
}
export default withRouter(SideDrawer)
