import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import FontWeights from "../../../utils/FontWeights"
import Colors from "../../../utils/Colors"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            flex: "1 0 auto"
        },
        upperContainer: {
            display: "flex",
            flexDirection: "row",
            padding: 24,
            [theme.breakpoints.down("md")]: {
                padding: 12
            },
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column"
            }
        },
        leftContainer: {
            display: "flex",
            flexDirection: "column",
            width: "60%",
            [theme.breakpoints.down("sm")]: {
                width: "unset",
                marginBottom: 32
            }
        },
        rightContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "auto"
        },
        topicSubtopicContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 48,
            [theme.breakpoints.down("xs")]: {
                marginBottom: 24,
                flexDirection: "column"
            }
        },
        topicSubtopicInputContainer: {
            width: "50%",
            [theme.breakpoints.down("xs")]: {
                width: "100%"
            }
        },
        dot: {
            width: 8,
            height: 8,
            borderRadius: 4,
            backgroundColor: Colors.questionCreationBullet,
            marginTop: 8,
            marginLeft: 12,
            marginRight: 12,
            [theme.breakpoints.down("xs")]: {
                display: "none"
            }
        },
        imageInput: {
            display: "none"
        },
        imageInQuestionContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            height: "100%"
        },
        imageInQuestionStyle: {
            height: 100,
            width: 100,
            objectFit: "cover",
            borderRadius: 4
        },
        answersContainer: {
            display: "flex",
            flexDirection: "column"
        },
        textAnswersInputContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
        },
        imageAnswersInputContainer: {
            display: "flex",
            flexDirection: "row"
        },
        buttonContainer: {
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: 32,
            marginBottom: 32
        },
        saveButtonContainer: {
            marginRight: 8
        }
    })
)

const answersInputStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%"
        }
    })
)

const correctTextAnswerTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignSelf: "flex-end",
            fontSize: 16,
            fontWeight: FontWeights.BOLD,
            color: Colors.questionCreationCorrectAnswerFont,
            margin: "8px 0px -8px 0px",
            [theme.breakpoints.down("xs")]: {
                fontSize: 12,
                width: 50,
                textAlign: "center"
            }
        }
    })
)

const correctImageAnswerTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 16,
            fontWeight: FontWeights.BOLD,
            color: Colors.questionCreationCorrectAnswerFont,
            marginTop: 8,
            marginLeft: 136,
            [theme.breakpoints.down("xs")]: {
                alignSelf: "flex-end",
                margin: "8px 0px -8px 0px",
                fontSize: 12,
                width: 50,
                textAlign: "center"
            }
        }
    })
)

const correctAnswerRadioStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            marginTop: 8,
            marginLeft: 40,
            marginRight: 40,
            [theme.breakpoints.down("xs")]: {
                marginLeft: 12,
                marginRight: 0
            }
        }
    })
)

const imageInQuestionAdornment = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxHeight: "100px",
            height: "100px"
        }
    })
)

const iconButtonStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: "-45px 0px",
            color: Colors.questionCreationDeleteImageActive
        }
    })
)

const cancelButtonStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginRight: 8
        }
    })
)

export default {
    styles,
    answersInputStyle,
    correctTextAnswerTypoStyle,
    correctImageAnswerTypoStyle,
    correctAnswerRadioStyle,
    imageInQuestionAdornment,
    iconButtonStyle,
    cancelButtonStyle
}
