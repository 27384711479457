import { Cache } from "../types/CacheType"

const CACHE_TIMEOUT_IN_MINS = 5

const cacheTimeout = (cache: Cache): boolean => {
    if (cache === undefined) {
        return true
    }
    return Date.now() - cache.getTime() > 1000 * 60 * CACHE_TIMEOUT_IN_MINS
}

export const executeCachedRequest = (request: (...args: any) => void, cache: Cache, ...args: any) => {
    if (cacheTimeout(cache)) {
        request(...args)
    }
}
