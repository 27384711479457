import ActionTypes from "../utils/ActionTypes"
import { FeedbackActionType } from "../actions/FeedbacksActions"
import { FeedbackTypeResponse } from "../types/FeedbackType"

export interface FeedbackReducerState {
    feedbacks: FeedbackTypeResponse[]
}

const initialState: FeedbackReducerState = {
    feedbacks: []
}

const FeedbackReducer = (state = initialState, action: FeedbackActionType): FeedbackReducerState => {
    switch (action.type) {
        case ActionTypes.getFeedbacks: {
            return Object.assign({}, state, {
                feedbacks: action.feedbacks
            })
        }
        default: {
            return state
        }
    }
}

export default FeedbackReducer
