import { MinimalSubtopicTypeResponse } from "../types/SubtopicTypes"
import ActionTypes from "../utils/ActionTypes"
import { ActionType } from "../types/ActionType"

export interface SubtopicActionType extends ActionType {
    subtopics: MinimalSubtopicTypeResponse[]
}

export const getAllSubtopicsAction = (subtopics: MinimalSubtopicTypeResponse[]): SubtopicActionType => {
    return { type: ActionTypes.getAllSubtopics, subtopics }
}
