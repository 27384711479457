import ImagesDataAccess from "../data_access/ImagesDataAccess"
import { Image } from "../types/ImageType"

export interface UploadedImage {
    filename: string
    presentation: string
    b64value: string
}

export const handleImageUpload = (callback: (image: UploadedImage) => void) => (e: any) => {
    if (e.target.files.length) {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.onloadend = () => {
            if (reader && reader.result && typeof reader.result === "string") {
                const b64image = reader.result.split("base64,")[1]
                const image = reader.result
                callback({
                    filename: file.name,
                    presentation: image,
                    b64value: b64image
                })
            }
        }
        reader.readAsDataURL(file)
    }
}

export const downloadImage = (url: string, filename: string, onSuccess: (image: UploadedImage) => void) => {
    const formatObject = (response: string, mime: string) => {
        const b64image = response.split("base64,")[1]
        const extension = mime.split("/")[1]
        onSuccess({
            filename: `${filename}.${extension}`,
            presentation: response,
            b64value: b64image
        })
    }
    ImagesDataAccess.getExternalImage(url, formatObject)
}

export const uploadedImageToImageFormat = (image: UploadedImage) => {
    const formattedImage: Image = {
        filename: image.filename,
        bytesB64: image.b64value
    }
    return formattedImage
}

export const imageToUploadedImageFormat = (image: Image) => {
    const splittedFilename = image.filename.split(".")
    const mime = `image/${splittedFilename[splittedFilename.length - 1]}`
    debugger
    const formattedImage: UploadedImage = {
        filename: image.filename,
        presentation: `data:${mime};base64,${image.bytesB64}`,
        b64value: image.bytesB64
    }
    return formattedImage
}
