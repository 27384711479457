import { MinimalTopicTypeResponse } from "../types/TopicTypes"
import { TopicActionType } from "../actions/TopicsActions"
import ActionTypes from "../utils/ActionTypes"
import { Cache } from "../types/CacheType"

export interface TopicReducerStateTypes {
    topics: MinimalTopicTypeResponse[]
    topicsCache: Cache
}
const initialState: TopicReducerStateTypes = {
    topics: [],
    topicsCache: undefined
}

const TopicsReducer = (state = initialState, action: TopicActionType): TopicReducerStateTypes => {
    switch (action.type) {
        case ActionTypes.getAllTopics: {
            const castedAction = action as TopicActionType
            return Object.assign({}, state, {
                topics: [...castedAction.topics],
                topicsCache: new Date()
            })
        }
        default: {
            return state
        }
    }
}

export default TopicsReducer
