import React from "react"
import { AnswerComponentProps } from "../../../types/AnswerTypes"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Styles from "./AnswerText.style"
import { addStyles, StaticMathField } from "react-mathquill"
import { checkIfMathExpression, unwrapMathInputFromTags } from "../../../utils/MathLatexHandlers"

addStyles()

const AnswerText: React.FC<AnswerComponentProps> = props => {
    const styles = Styles.styles(props.correct)({})
    const buttonStyle = Styles.buttonStyle(props.correct)({})
    const answerLetterTypoStyle = Styles.answerLetterTypoStyle(props.correct)({})
    const answerTypoStyle = Styles.answerTypoStyle(props.correct)({})

    return (
        <Button classes={buttonStyle} disabled>
            <Typography classes={answerLetterTypoStyle} noWrap>
                {props.answerLetter}
            </Typography>
            {checkIfMathExpression(props.answer) ? (
                <div className={styles.mathInputContainer}>
                    <StaticMathField>{unwrapMathInputFromTags(props.answer)}</StaticMathField>
                </div>
            ) : (
                <Typography classes={answerTypoStyle}>{props.answer}</Typography>
            )}
        </Button>
    )
}

export default AnswerText
