import ColorPalette from "./ColorPalette"

const Colors = {
    white: ColorPalette.WHITE,
    primary: ColorPalette.BLUE1,
    secondary: ColorPalette.ORANGE1,
    alertButton: ColorPalette.RED2,
    normalButton: ColorPalette.ORANGE1,
    buttonFont: ColorPalette.WHITE,
    deleteIcon: ColorPalette.RED1,
    schoolLocationFont: ColorPalette.GREY7,
    acceptIcon: ColorPalette.GREEN1,
    appBarIconStyle: ColorPalette.WHITE,
    dialogInfoLabelFont: ColorPalette.BLUE1,
    dialogLocationLabelFont: ColorPalette.GREY7,
    paginationButtonBorder: ColorPalette.BLUE1,
    paginationButtonFont: ColorPalette.BLUE1,
    searchBarInputBorder: ColorPalette.ORANGE1,
    reportedCommentAuthorFont: ColorPalette.BLUE1,
    headerCellFont: ColorPalette.BLUE1,
    searchIcon: ColorPalette.BLUE1,
    difficultyFont: ColorPalette.ORANGE1,
    topicFont: ColorPalette.ORANGE1,
    subtopicFont: ColorPalette.GREY3,
    authorFont: ColorPalette.BLUE1,
    checkbox: ColorPalette.BLUE1,
    alert: ColorPalette.RED2,
    red: ColorPalette.RED1,
    grey: ColorPalette.GREY7,
    green: ColorPalette.GREEN1,
    modGrey: ColorPalette.GREY3,
    picIcon: ColorPalette.GREY7,
    question: ColorPalette.BLACK,
    difficultyAuthor: ColorPalette.GREY3,
    answerLetter: ColorPalette.BLACK,
    answerText: ColorPalette.GREY3,
    answerCorrect: ColorPalette.GREEN1,
    answerWrong: ColorPalette.RED1,
    answerNeutralBorder: ColorPalette.GREY6,
    dismissButton: ColorPalette.RED2,
    acceptButton: ColorPalette.GREEN4,
    questionDifficultyPickerNeedle: ColorPalette.ORANGE1,
    questionDifficultyPickerTextFont: ColorPalette.WHITE,
    questionDifficultyPickerElementarySchoolLevel: ColorPalette.GREEN1,
    questionDifficultyPickerUpperElementarySchoolLevel: ColorPalette.GREEN3,
    questionDifficultyPickerHighSchoolLevel: ColorPalette.YELLOW4,
    questionDifficultyPickerUpperHighSchoolLevel: ColorPalette.ORANGE2,
    questionDifficultyPickerUniversityLevel: ColorPalette.RED2,
    questionDifficultyPickerElementarySchoolFont: ColorPalette.GREEN2,
    questionDifficultyPickerHighSchoolFont: ColorPalette.YELLOW3,
    questionDifficultyPickerUniversityFont: ColorPalette.RED2,
    questionCreation: ColorPalette.GREY4,
    questionCreationBullet: ColorPalette.BLACK,
    questionCreationAnswerBorder: ColorPalette.GREY6,
    questionCreationAnswerLetter: ColorPalette.BLACK,
    questionCreationEmptyImageBackground: ColorPalette.YELLOW1,
    questionCreationEmptyImageIcon: ColorPalette.YELLOW2,
    questionCreationDeleteImageDisabled: ColorPalette.GREY1,
    questionCreationDeleteImageActive: ColorPalette.RED1,
    questionCreationCorrectAnswerFont: ColorPalette.BLUE2,
    reportBackground: ColorPalette.GREY4,
    reportTitleFont: ColorPalette.BLACK
}

export default Colors
