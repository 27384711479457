import React from "react"
import DialogFromMUI from "@material-ui/core/Dialog"
import Styles from "./Dialog.style"
import CloseButton from "../CloseButton/CloseButton"

interface DialogProps {
    open: boolean
    content?: JSX.Element
    onClose: () => void
    showLoader?: () => void
}

const Dialog: React.FC<DialogProps> = props => {
    const style = Styles.styles({})

    return (
        <DialogFromMUI maxWidth={"lg"} open={props.open}>
            <div className={style.mainContainer}>
                <CloseButton onClose={props.onClose} />
                <div className={style.dataContainer}>
                    <div>{props.content}</div>
                </div>
            </div>
        </DialogFromMUI>
    )
}

export default Dialog
