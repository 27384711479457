import { UserTypeResponse } from "../types/UserTypes"
import { MyUserActionType } from "../actions/UsersActions"
import ActionTypes from "../utils/ActionTypes"

export interface UserReducerStateTypes {
    me: UserTypeResponse | undefined
}

const initialState: UserReducerStateTypes = {
    me: undefined
}

const UserReducer = (state = initialState, action: MyUserActionType): UserReducerStateTypes => {
    switch (action.type) {
        case ActionTypes.getMyUser: {
            const castedAction = action as MyUserActionType
            return Object.assign({}, state, {
                me: castedAction.user
            })
        }
        default: {
            return state
        }
    }
}

export default UserReducer
