import axios from "axios"
import { Dispatch } from "redux"
import Endpoints from "../environments/endpoints"
import { getAllEducationalFacilitiesAction } from "../actions/EducationActions"
import { AuthHeader } from "../utils/AuthHeader"
import { Token } from "../utils/StorageKeys"
import { EducationTypeRequest } from "../types/EducationTypes"

const getAllEducationalFacilities = (dispatch: Dispatch<any>) => () => {
    axios
        .get(`${Endpoints.apiEndpoint}/education`, AuthHeader(localStorage.getItem(Token)))
        .then(response => dispatch(getAllEducationalFacilitiesAction(response.data)))
        .catch(error => {})
}

const createEducationalFacility = (dispatch: Dispatch<any>) => (
    educationFacilityData: EducationTypeRequest,
    onSuccess: () => void
) => {
    axios
        .post(`${Endpoints.apiEndpoint}/education`, educationFacilityData, AuthHeader(localStorage.getItem(Token)))
        .then(response => {
            getAllEducationalFacilities(dispatch)()
            onSuccess()
        })
        .catch(error => {})
}

const updateEducationalFacility = (dispatch: Dispatch<any>) => (
    educationFacilityId: number,
    educationFacilityData: EducationTypeRequest,
    onSuccess: () => void
) => {
    axios
        .put(
            `${Endpoints.apiEndpoint}/education/${educationFacilityId}`,
            educationFacilityData,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => {
            getAllEducationalFacilities(dispatch)()
            onSuccess()
        })
        .catch(error => {})
}

const deleteEducationalFacility = (dispatch: Dispatch<any>) => (educationFacilityId: number, onSuccess: () => void) => {
    axios
        .delete(`${Endpoints.apiEndpoint}/education/${educationFacilityId}`, AuthHeader(localStorage.getItem(Token)))
        .then(response => {
            getAllEducationalFacilities(dispatch)()
            onSuccess()
        })
        .catch(error => {})
}

export default {
    getAllEducationalFacilities,
    createEducationalFacility,
    updateEducationalFacility,
    deleteEducationalFacility
}
