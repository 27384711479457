import React from "react"
import { AnswerComponentProps } from "../../../types/AnswerTypes"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Styles from "./AnswerImage.style"

const AnswerImage: React.FC<AnswerComponentProps> = props => {
    const styles = Styles.styles({})
    const buttonStyle = Styles.buttonStyle(props.correct)({})
    const answerLetterTypoStyle = Styles.answerLetterTypoStyle(props.correct)({})

    return (
        <Button classes={buttonStyle} disabled>
            <div>
                <img className={styles.imageStyle} src={props.answer} alt={props.answerLetter} />
                <Typography classes={answerLetterTypoStyle} noWrap>
                    {props.answerLetter}
                </Typography>
            </div>
        </Button>
    )
}

export default AnswerImage
