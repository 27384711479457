import React, { useState, useEffect } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import PaginationComponent from "../../common_components/PaginationComponent/PaginationComponent"
import Styles from "./EditorsChoiceScreen.style"
import Checkbox from "@material-ui/core/Checkbox"
import ImageIcon from "@material-ui/icons/ImageRounded"
import IconButton from "@material-ui/core/IconButton"
import ImageDialog from "../../common_components/ImageDialog/ImageDialog"
import QuestionsDataAccess from "../../../data_access/QuestionsDataAccess"
import Locales from "../../../localization/Localization"
import { QuestionsReducerStateTypes } from "../../../reducers/QuestionsReducer"
import { executeCachedRequest } from "../../../utils/CacheValidator"
import OrangeButton from "../../common_components/OrangeButton/OrangeButton"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { MinimalQuestionTypeResponse } from "../../../types/QuestionTypes"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import SearchIcon from "@material-ui/icons/SearchRounded"
import Loading from "../../common_components/Loading/Loading"
import { addStyles, StaticMathField } from "react-mathquill"
import { checkIfMathExpression, unwrapMathInputFromTags } from "../../../utils/MathLatexHandlers"

addStyles()

interface EditorsChoiceState {
    openDialog: boolean
    dialogImagePath: string
}

interface EditorsChoicePaginationState {
    editorsChoicePage: number
    popularQuestionsPage: number
    searchQuestionPage: number
    rowsPerPage: number
}

const MINIMAL_INPUT_TO_SEARCH = 1

const ACTIVE_EDITORS_CHOICE_INDEX = 0
const POPULAR_QUESTIONS_INDEX = 1
const SEARCH_INDEX = 2

const EditorsChoiceScreen: React.FC = () => {
    const dispatch = useDispatch()
    const questions: QuestionsReducerStateTypes = useSelector((state: any) => state.QuestionsReducer, shallowEqual)

    const styles = Styles.styles({})
    const headerCellStyle = Styles.headerCellStyle({})
    const checkBoxCellStyle = Styles.checkBoxCellStyle({})
    const checkBoxStyle = Styles.checkBoxStyle({})
    const picIconCellStyle = Styles.picIconCellStyle({})
    // const difficultyTypoStyle = Styles.difficultyTypoStyle({})
    const topicTypoStyle = Styles.topicTypoStyle({})
    const subtopicTypoStyle = Styles.subtopicTypoStyle({})
    const headerAuthorCellStyle = Styles.authorCellStyle({})
    const authorTypoStyle = Styles.authorTypoStyle({})
    const headerTopicCellStyle = Styles.headerTopicCellStyle({})
    // const headerDifficultyCellStyle = Styles.headerDifficultyCellStyle({})

    const [value, setValue] = useState(ACTIVE_EDITORS_CHOICE_INDEX)

    const [pageState, setPageState] = useState<EditorsChoicePaginationState>({
        editorsChoicePage: 0,
        popularQuestionsPage: 0,
        searchQuestionPage: 0,
        rowsPerPage: 8
    })

    const [questionsToRemove, setQuestionsToRemove] = useState<number[]>([])
    const [questionsToAdd, setQuestionsToAdd] = useState<number[]>([])
    const [questionsToAddFromSearch, setQuestionsToAddSearch] = useState<number[]>([])

    const [searchQuery, setSearchQuery] = useState<string>("")
    const [searchResults, setSearchResults] = useState<MinimalQuestionTypeResponse[]>([])

    const [state, setState] = useState<EditorsChoiceState>({
        openDialog: false,
        dialogImagePath: ""
    })

    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        executeCachedRequest(QuestionsDataAccess.getEditorsQuestions(dispatch), questions.editorsQuestionsCache)
        executeCachedRequest(QuestionsDataAccess.getPopularQuestions(dispatch), questions.popularQuestionsCache)
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        setQuestionsToAdd(questions.editorsQuestionIds)
        setQuestionsToAddSearch(questions.editorsQuestionIds)
        //eslint-disable-next-line
    }, [questions.editorsQuestionIds])

    const openImageDialog = (questionImage: string) => (e: any) => {
        setState({
            openDialog: true,
            dialogImagePath: questionImage
        })
    }

    const closeDialog = () => {
        setState({
            openDialog: false,
            dialogImagePath: ""
        })
    }

    const showLoader = () => setLoading(true)
    const hideLoader = () => setLoading(false)

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue)
    }

    const handleChangePage = (name: keyof EditorsChoicePaginationState) => (event: unknown, newPage: number) => {
        setPageState({ ...pageState, [name]: newPage })
    }

    const handleChangeRowsPerPage = (name: keyof EditorsChoicePaginationState) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPageState({ ...pageState, rowsPerPage: +event.target.value, [name]: 0 })
    }

    const handleClick = (
        id: number,
        decideFunction: (id: number) => boolean,
        stateArray: number[],
        stateCallback: React.Dispatch<React.SetStateAction<number[]>>
    ) => () => {
        if (decideFunction(id)) {
            stateCallback(stateArray.filter(selectedId => selectedId !== id))
        } else {
            stateCallback([...stateArray, id])
        }
    }

    const handleChangeSearchQuery = (e: any) => {
        setSearchQuery(e.target.value)
    }

    const isSelectedToRemove = (id: number) => questionsToRemove.indexOf(id) !== -1
    const isSelectedToAdd = (id: number) => questionsToAdd.indexOf(id) !== -1
    const isSelectedToAddFromSearch = (id: number) => questionsToAddFromSearch.indexOf(id) !== -1

    const editorsChoice = questions.editorsQuestions.slice(
        pageState.editorsChoicePage * pageState.rowsPerPage,
        pageState.editorsChoicePage * pageState.rowsPerPage + pageState.rowsPerPage
    )

    const popularQuestions = questions.popularQuestions.slice(
        pageState.popularQuestionsPage * pageState.rowsPerPage,
        pageState.popularQuestionsPage * pageState.rowsPerPage + pageState.rowsPerPage
    )

    const searchedQuestions = searchResults.slice(
        pageState.searchQuestionPage * pageState.rowsPerPage,
        pageState.searchQuestionPage * pageState.rowsPerPage + pageState.rowsPerPage
    )

    const saveEditorsQuestions = (type: "editorsChoicePage" | "popularQuestionsPage" | "searchQuestionPage") => () => {
        showLoader()
        const onSuccess = () => {
            hideLoader()
        }
        if (type === "editorsChoicePage") {
            let editorsChoiceQuestions = questions.editorsQuestionIds.filter(
                (id: number) => !questionsToRemove.includes(id)
            )
            QuestionsDataAccess.setEditorsChoiceQuestion(dispatch)(editorsChoiceQuestions, onSuccess)
        } else if (type === "popularQuestionsPage") {
            QuestionsDataAccess.setEditorsChoiceQuestion(dispatch)(questionsToAdd, onSuccess)
        } else {
            QuestionsDataAccess.setEditorsChoiceQuestion(dispatch)(questionsToAddFromSearch, onSuccess)
        }
    }

    const onSearchSuccess = (results: MinimalQuestionTypeResponse[]) => {
        setPageState({ ...pageState, searchQuestionPage: 0 })
        setSearchResults(results)
        hideLoader()
    }

    const searchQuestion = () => {
        showLoader()
        QuestionsDataAccess.searchQuestionByText(dispatch)(searchQuery, onSearchSuccess)
    }

    const searchComponent = (
        <div className={styles.searchContainer}>
            <TextField
                variant="outlined"
                placeholder={Locales.searchByQuestionText}
                InputProps={{ endAdornment: <SearchIcon /> }}
                value={searchQuery}
                onChange={handleChangeSearchQuery}
            />
            <div className={styles.buttonContainer}>
                <OrangeButton
                    height={36}
                    fontSize={14}
                    bold
                    disabled={searchQuery.length < MINIMAL_INPUT_TO_SEARCH}
                    label={Locales.search}
                    onClick={searchQuestion}
                />
            </div>
        </div>
    )

    const tableContent = (index: number, type: "editorsChoicePage" | "popularQuestionsPage" | "searchQuestionPage") => {
        let data: MinimalQuestionTypeResponse[]
        let dataLength: number
        let page: number
        let actionButtonCaption: string
        let checkedRow: (id: number) => boolean
        let handleClickMethod: (id: number) => () => void

        if (type === "editorsChoicePage") {
            data = editorsChoice
            dataLength = questions.editorsQuestions.length
            page = pageState.editorsChoicePage
            actionButtonCaption = Locales.removeFromEditorsChoice
            checkedRow = (id: number) => isSelectedToRemove(id)
            handleClickMethod = (id: number) =>
                handleClick(id, isSelectedToRemove, questionsToRemove, setQuestionsToRemove)
        } else if (type === "popularQuestionsPage") {
            data = popularQuestions
            dataLength = questions.popularQuestions.length
            page = pageState.popularQuestionsPage
            actionButtonCaption = Locales.addToEditorsChoice
            checkedRow = (id: number) => isSelectedToAdd(id) || questions.editorsQuestionIds.includes(id)
            handleClickMethod = (id: number) => handleClick(id, isSelectedToAdd, questionsToAdd, setQuestionsToAdd)
        } else {
            data = searchedQuestions
            dataLength = searchResults.length
            page = pageState.searchQuestionPage
            actionButtonCaption = Locales.addToEditorsChoice
            checkedRow = (id: number) => isSelectedToAddFromSearch(id) || questions.editorsQuestionIds.includes(id)
            handleClickMethod = (id: number) =>
                handleClick(id, isSelectedToAddFromSearch, questionsToAddFromSearch, setQuestionsToAddSearch)
        }

        return (
            <TabPanel value={value} index={index}>
                <div className={styles.actionsContainer}>
                    {index === SEARCH_INDEX ? searchComponent : <div />}
                    <OrangeButton
                        height={36}
                        fontSize={14}
                        bold
                        label={actionButtonCaption.toUpperCase()}
                        onClick={saveEditorsQuestions(type)}
                    />
                </div>
                {data.length > 0 && (
                    <>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell classes={headerCellStyle} colSpan={3}>
                                        {Locales.questionText}
                                    </TableCell>
                                    {/* <TableCell classes={headerDifficultyCellStyle}>{Locales.difficulty}</TableCell> */}
                                    <TableCell classes={headerTopicCellStyle} colSpan={2} align="center">
                                        {Locales.topicSubtopic}
                                    </TableCell>
                                    <TableCell classes={headerAuthorCellStyle}>{Locales.author}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        <TableCell classes={checkBoxCellStyle}>
                                            <Checkbox
                                                classes={checkBoxStyle}
                                                checked={checkedRow(row.id)}
                                                onClick={handleClickMethod(row.id)}
                                            />
                                        </TableCell>
                                        {checkIfMathExpression(row.questionText) ? (
                                            <TableCell>
                                                <StaticMathField>
                                                    {unwrapMathInputFromTags(row.questionText)}
                                                </StaticMathField>
                                            </TableCell>
                                        ) : (
                                            <TableCell> {row.questionText}</TableCell>
                                        )}
                                        <TableCell classes={picIconCellStyle}>
                                            {row.questionImage && (
                                                <>
                                                    <IconButton onClick={openImageDialog(row.questionImage)}>
                                                        <ImageIcon />
                                                    </IconButton>
                                                </>
                                            )}
                                        </TableCell>
                                        {/* <TableCell classes={difficultyTypoStyle}>{row.}</TableCell> */}
                                        <TableCell classes={topicTypoStyle} align="right">
                                            {row.topicName}
                                        </TableCell>
                                        <TableCell classes={subtopicTypoStyle}>{row.subtopicName}</TableCell>
                                        <TableCell classes={authorTypoStyle}>
                                            {row.author != null
                                                ? `${row.author.firstName} ${row.author.lastName}`
                                                : "Srednja.hr"}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <PaginationComponent
                            rows={dataLength}
                            page={page}
                            rowsPerPage={pageState.rowsPerPage}
                            onChangePage={handleChangePage(type)}
                            onChangeRowsPerPage={handleChangeRowsPerPage(type)}
                        />
                    </>
                )}
            </TabPanel>
        )
    }

    return (
        <div>
            <Paper>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="secondary"
                    textColor="secondary"
                >
                    <Tab label={Locales.activeEditorChoice} />
                    <Tab label={Locales.popularQuestions} />
                    <Tab label={Locales.searchQuestions} />
                </Tabs>
                {tableContent(ACTIVE_EDITORS_CHOICE_INDEX, "editorsChoicePage")}
                {tableContent(POPULAR_QUESTIONS_INDEX, "popularQuestionsPage")}
                {tableContent(SEARCH_INDEX, "searchQuestionPage")}
            </Paper>
            <ImageDialog open={state.openDialog} imagePath={state.dialogImagePath} onClose={closeDialog} />
            {loading && <Loading fullScreen />}
        </div>
    )
}

export default EditorsChoiceScreen

interface TabPanelProps {
    children?: React.ReactNode
    index: any
    value: any
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    )
}
