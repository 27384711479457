export const AuthHeader = (token: string | null) => {
    if (token == null) {
        return
    }
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}
