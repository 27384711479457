import React, { useEffect, useState } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { Switch, Route, Redirect, RouteProps } from "react-router-dom"
import Endpoints from "../environments/endpoints"
import AppBarComponent from "./common_components/AppBar/AppBarComponent"
import SideDrawer from "./common_components/SideDrawer/SideDrawer"
import UserManagementScreen from "./screens/UserManagementScreen/UserManagementScreen"
import SchoolManagementScreen from "./screens/SchoolManagementScreen/SchoolManagementScreen"
import QuestionProposals from "./screens/QuestionProposals/QuestionProposals"
import EditQuestionProposal from "./screens/EditQuestionProposal/EditQuestionProposal"
import ReportedQuestions from "./screens/ReportedQuestions/ReportedQuestions"
import ReportedComments from "./screens/ReportedComments/ReportedComments"
import EditorsChoiceScreen from "./screens/EditorsChoiceScreen/EditorsChoiceScreen"
import FeedbackScreen from "./screens/FeedbackScreen/FeedbackScreen"
import UsersDataAccess from "../data_access/UsersDataAccess"
import Styles from "./AppHolder.style"
import { UserReducerStateTypes } from "../reducers/UserReducer"
import { Token } from "../utils/StorageKeys"
import StatisticsScreen from "./screens/StatisticsScreen/StatisticsScreen"
import AdminMessage from "./screens/AdminMessage/AdminMessage"

const AppHolder: React.FC<RouteProps> = props => {
    const dispatch = useDispatch()
    const users: UserReducerStateTypes = useSelector((state: any) => state.UserReducer, shallowEqual)

    const [openDrawer, setOpenDrawer] = useState<boolean>(true)

    const toggleDrawer = () => {
        if (openDrawer) {
            setOpenDrawer(false)
        } else {
            setOpenDrawer(true)
        }
    }

    const styles = Styles.styles({})

    if (props.location && props.location.search.startsWith("?token=")) {
        const token = props.location.search.split("=")[1]
        localStorage.setItem(Token, token)
    }
    useEffect(() => {
        UsersDataAccess.getMyUser(dispatch)()
        // eslint-disable-next-line
    }, [])

    const adminRoutes = (
        <>
            <Route exact path={Endpoints.appEndpoints.root} component={UserManagementScreen} />
            <Route path={Endpoints.appEndpoints.schools} component={SchoolManagementScreen} />
            <Route path={Endpoints.appEndpoints.questionProposals} component={QuestionProposals} />
            <Route path={Endpoints.appEndpoints.editQuestionProposal} component={EditQuestionProposal} />
            <Route path={Endpoints.appEndpoints.reportedQuestions} component={ReportedQuestions} />
            <Route path={Endpoints.appEndpoints.reportedComments} component={ReportedComments} />
            <Route path={Endpoints.appEndpoints.editorsChoice} component={EditorsChoiceScreen} />
            <Route path={Endpoints.appEndpoints.feedbacks} component={FeedbackScreen} />
            <Route path={Endpoints.appEndpoints.statistics} component={StatisticsScreen} />
            <Route path={Endpoints.appEndpoints.adminMessage} component={AdminMessage} />
        </>
    )

    const moderatorRoutes = (
        <>
            <Redirect from={Endpoints.appEndpoints.root} to={Endpoints.appEndpoints.questionProposals} />
            <Route path={Endpoints.appEndpoints.questionProposals} component={QuestionProposals} />
            <Route path={Endpoints.appEndpoints.editQuestionProposal} component={EditQuestionProposal} />
            <Route path={Endpoints.appEndpoints.reportedQuestions} component={ReportedQuestions} />
            <Route path={Endpoints.appEndpoints.reportedComments} component={ReportedComments} />
        </>
    )

    const editorRoutes = (
        <>
            <Redirect from={Endpoints.appEndpoints.root} to={Endpoints.appEndpoints.editorsChoice} />
            <Route path={Endpoints.appEndpoints.editorsChoice} component={EditorsChoiceScreen} />
        </>
    )

    window.scrollTo(0, 0)

    const resolveRole = (): "ADMIN" | "MODERATOR" | "EDITOR" => {
        if (users!.me!.roles.includes("ADMIN")) {
            return "ADMIN"
        }
        if (users!.me!.roles.includes("MODERATOR")) {
            return "MODERATOR"
        }
        return "EDITOR"
    }

    const resolveRoleRoutes = () => {
        if (users!.me!.roles.includes("ADMIN")) {
            return adminRoutes
        }
        if (users!.me!.roles.includes("MODERATOR")) {
            return moderatorRoutes
        }
        return editorRoutes
    }

    return (
        <div>
            <AppBarComponent toggleDrawer={toggleDrawer} />
            {users.me != null && <SideDrawer open={openDrawer} toggleDrawer={toggleDrawer} role={resolveRole()} />}
            <div className={openDrawer ? styles.container : styles.shiftedContainer}>
                {users.me != null && <Switch>{resolveRoleRoutes()}</Switch>}
            </div>
        </div>
    )
}

export default AppHolder
