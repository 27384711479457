import LocalizedStrings from "react-localization"

const Locales = new LocalizedStrings({
    hr: {
        userManagement: "Korisnici",
        schoolManagement: "Škole",
        questionManagement: "Predložena pitanja",
        reportedQuestions: "Prijavljena pitanja",
        reportedComments: "Prijavljeni komentari",
        editorsChoice: "Urednikov odabir",
        moderatorManagement: "Moderatori",
        statistics: "Statistika",
        feedbacks: "Prijavljene pogreške",
        searchPlaceholder: "Potraži korisnika",
        suspendUser: "Suspendiranje korisnika",
        periodOf: "na period od",
        hour: "sata",
        week: "tjedan",
        month: "mjesec",
        removeFromSystem: "Želite li ukloniti iz sustava",
        banUserMessage: "Želite li zabraniti pristup korisiku",
        unbanUserMessage: "Želite li omogućiti pristup korisniku",
        name: "Ime",
        surname: "Prezime",
        email: "Email",
        rowsPerPage: "Redova po stranici",
        previousPage: "prethodna stranica",
        nextPage: "sljedeća stranica",
        suspend: "Suspendiraj",
        ban: "Zabrani pristup",
        remove: "Ukloni",
        institutionName: "Ime obrazovne ustanove",
        address: "Adresa",
        town: "Grad/Mjesto",
        country: "Država",
        elementarySchool: "Osnovna škola",
        highSchool: "Srednja škola",
        university: "Fakultet",
        elementarySchools: "Osnovne škole",
        highSchools: "Srednje škole",
        universities: "Fakulteti",
        croatia: "Hrvatska",
        cancel: "Odustani",
        save: "Spremi",
        saveAndApprove: "Spremi i prihvati",
        wishToDeleteComment: "Želite li izbrisati komentar?",
        comment: "Komentar",
        explanation: "Objašnjenje",
        author: "Autor",
        add: "Dodaj",
        delete: "Izbriši",
        questionText: "Tekst Pitanja",
        difficulty: "Težina",
        topicSubtopic: "Tema i podtema",
        sendRequest: "Pošalji zahtjev na:",
        role: "Uloga",
        moderator: "Moderator",
        editor: "Urednik",
        admin: "Admin",
        chosenModerators: "Odabrani modaratori i urednici",
        edit: "Uredi",
        dismiss: "Odbaci",
        accept: "Prihvati",
        questionContainsImage: "Pitanje sadrži sliku",
        questionDifficulty: "Težina pitanja",
        difficultyForElementarySchool: "težina za osnovnu školu",
        difficultyBetweenElementaryAndHighSchool: "težina između osnovne i srednje škole",
        difficultyForHighSchool: "težina za srednju školu",
        difficultyBetweenHighSchoolAndUniversity: "težina između srednje škole i fakulteta",
        difficultyForUniversity: "težina za fakultet",
        topic: "Tema",
        subtopic: "Podtema",
        question: "Pitanje",
        correctAnswer: "Točan odgovor",
        answerX: "Odgovor #answerLetter#",
        thereIsNoProposedQuestions: "Trenutno nema predloženih pitanja.",
        thereIsNoReportedQuestions: "Trenutno nema prijavljenih pitanja.",
        thereIsNoReportedComments: "Trenutno nema prijavljenih komentara.",
        thereIsNoFeedbacks: "Trenutno nema prijavljenih pogrešaka.",
        questionReportedBy: "Pitanje prijavio",
        commentReportedBy: "Komentar prijavio",
        reason: "Razlog",
        description: "Obrazloženje",
        incorrect: "Netočne informacije",
        inappropriate: "Neprimjereno",
        spam: "Spam",
        hateSpeech: "Govor mržnje",
        harassment: "Zlostavljanje",
        message: "Poruka",
        numberOfAnswers: "Broj odgovora na pitanja u intervalu",
        numberOfAddedQuestions: "Broj dodanih pitanja u intervalu",
        numberOfAddedTopics: "Broj dodanih tema u intervalu",
        numberOfAddedSubtopics: "Broj dodanih podtema u intervalu",
        numberOfUsers: "Broj korisnika koji zadovoljavaju pretragu",
        usersAverageScoreForTopic: "Prosječna ocjena korisnika koji odgovara na temu i koji zadovoljava pretragu",
        usersAverageScoreForSubtopic: "Prosječna ocjena korisnika koji odgovara na podtemu i koji zadovoljava pretragu",
        compareUsersAverageScoreForTopic:
            "Usporedba prosječnih ocjena korisnika koji odgovaraju na temu i koji zadovoljava pretragu",
        compareUsersAverageScoreForSubtopic:
            "Usporedba prosječnih ocjena korisnika koji odgovaraju na podtemu i koji zadovoljava pretragu",
        compareUsersAverageScoreForTopicFromLocation: "Usporedba prosječnih ocjena korisnika iz teme prema lokacijama",
        compareUsersAverageScoreForSubtopicFromLocation:
            "Usporedba prosječnih ocjena korisnika iz podteme prema lokacijama",
        location: "Mjesto",
        from: "Od:",
        to: "Do:",
        dateOfBirthFrom: "Datum rođenja od:",
        dateOfBirthTo: "Datum rođenja do:",
        educationalFacility: "Obrazovna ustanova",
        getStatisticsData: "Dohvati statistiku",
        activeEditorChoice: "Aktivni Urednikov odabir",
        popularQuestions: "Popularna pitanja",
        searchQuestions: "Pretraga pitanja",
        removeFromEditorsChoice: "Makni iz urednikovog odabira",
        addToEditorsChoice: "Dodaj u urednikov odabir",
        searchByQuestionText: "Pretraži po tekstu pitanja",
        search: "Pretraži",
        numberOfQuestions: "Broj pitanja",
        reportAccept: "Prihvati prijavu",
        reportDismiss: "Odbaci prijavu",
        reportQuestionAcceptTitle: "Prihvaćanje prijave pitanja",
        reportQuestionDismissTitle: "Odbacivanje prijave pitanja",
        reportCommentAcceptTitle: "Prihvaćanje prijave komentara",
        reportCommentDismissTitle: "Odbacivanje prijave komentara",
        reportAcceptText:
            "Obrazloženje prihvaćanja prijave i brisanja TYPE će se poslati autoru TYPE (%) i REPORTER (%).",
        reportDismissText: "Obrazloženje odbijanja prijave TYPE će se poslati REPORTER (%)",
        questionProposalDismissText: "Obrazloženje odbijanja pitanja će se poslati autoru: ",
        reportTypeQuestion: "pitanja",
        reportTypeComment: "komentara",
        reporterSingle: "prijavitelju",
        reporterPlural: "prijaviteljima",
        reportQuestionReasonA: "Pitanje i/ili predloženi ispravan odgovor su netočni",
        reportQuestionReasonB: "Pitanje i/ili predloženi ispravan odgovor su neprimjereni",
        reportQuestionReasonC: "Pitanje i/ili predloženi ispravan odgovor su spam",
        reportCommentReasonA: "Komentar sadrži govor mržnje",
        reportCommentReasonB: "Komentar sadrži elemente zlostavljanja",
        reportCommentReasonC: "Komentar je spam",
        reportReasonOtherPlaceholder: "Nešto drugo",
        reportDismissalPlaceHolder: "Obrazloženje za odbijanje",
        questionProposalDismissTitle: "Odbijanje pitanja",
        questionProposalDismiss: "Odbaci pitanje",
        adminMessage: "Slanje obavijesti",
        adminMessageTitle: "Slanje obavijesti svim korisnicima",
        adminMessageText: "Ovo je globalna obavijest koju će primiti svi korisnici:",
        adminMessageWarning: "(Ova akcija se ne može poništiti)",
        adminMessageButton: "POŠALJI",
        confirmationDialogButton: "POTVRDI",
        adminMessagePlaceholder: "Sadržaj obavijesti",
        notificationSuccess: "Uspješno poslano!",
        notificationError: "Došlo je do pogreške."
    }
})

export default Locales
