import React from "react"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import ImageIcon from "@material-ui/icons/ImageRounded"
import DeleteIcon from "@material-ui/icons/DeleteRounded"
import Styles from "./ImageAnswerUpload.style"

interface ImageAnswerUploadProps {
    answerLetter: "A" | "B" | "C" | "D"
    image?: string
    onUploadImage: (event: React.ChangeEvent<HTMLInputElement>) => void
    onDiscardImage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const ImageAnswerUpload: React.FC<ImageAnswerUploadProps> = props => {
    const styles = Styles.styles({})
    const buttonStyle = Styles.buttonStyle({})
    const answerLetterTypoStyle = Styles.answerLetterTypoStyle({})
    const emptyImageIconStyle = Styles.emptyImageIconStyle({})
    const disabledIconStyle = Styles.disabledIconStyle({})
    const iconButtonStyle = Styles.iconButtonStyle({})

    const renderImage = (
        <div className={styles.container}>
            <img className={styles.imageStyle} src={props.image} alt={props.answerLetter} />
            <div className={styles.footerContainer}>
                <Typography classes={answerLetterTypoStyle} noWrap>
                    {props.answerLetter}
                </Typography>
                <IconButton onClick={props.onDiscardImage}>
                    <DeleteIcon classes={iconButtonStyle} />
                </IconButton>
            </div>
        </div>
    )

    const renderEmptyImage = (
        <>
            <input
                accept="image/*"
                className={styles.imageInput}
                id={"image-input-" + props.answerLetter}
                type="file"
                onChange={props.onUploadImage}
            />
            <label htmlFor={"image-input-" + props.answerLetter} className={styles.imageInputLabelElement}>
                <Button classes={buttonStyle} component="span">
                    <div className={styles.contentContainer}>
                        <div className={styles.emptyImageContainer}>
                            <ImageIcon classes={emptyImageIconStyle} />
                        </div>
                        <div className={styles.footerContainer}>
                            <Typography classes={answerLetterTypoStyle} noWrap>
                                {props.answerLetter}
                            </Typography>
                            <DeleteIcon classes={disabledIconStyle} />
                        </div>
                    </div>
                </Button>
            </label>
        </>
    )

    return props.image ? renderImage : renderEmptyImage
}

export default ImageAnswerUpload
