import { MinimalTopicTypeResponse } from "../types/TopicTypes"
import ActionTypes from "../utils/ActionTypes"
import { ActionType } from "../types/ActionType"

export interface TopicActionType extends ActionType {
    topics: MinimalTopicTypeResponse[]
}

export const getAllTopicsAction = (topics: MinimalTopicTypeResponse[]): TopicActionType => {
    return { type: ActionTypes.getAllTopics, topics }
}
