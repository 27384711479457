import Colors from "./Colors"
import { createMuiTheme } from "@material-ui/core/styles"

const Theme = createMuiTheme({
    palette: {
        primary: {
            main: Colors.primary,
            contrastText: Colors.white
        },
        secondary: {
            main: Colors.secondary
        }
    },
    typography: {
        fontFamily: ['"Noto Sans"', '"Roboto"', "sans-serif"].join(",")
    }
})

export default Theme
