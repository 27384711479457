import React, { useState } from "react"
import { useDispatch } from "react-redux"
import Typography from "@material-ui/core/Typography"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Styles from "./RolesContent.style"
import Locales from "../../../../localization/Localization"
import { MinimalUserTypeResponse } from "../../../../types/UserTypes"
import DialogButton from "../../DialogButton/DialogButton"
import UsersDataAccess from "../../../../data_access/UsersDataAccess"

interface RolesContentProps {
    data: MinimalUserTypeResponse
    onSuccess: () => void
    showLoader: () => void
}

const RolesContent: React.FC<RolesContentProps> = props => {
    const dispatch = useDispatch()
    const styles = Styles.styles({})

    const [value, setValue] = useState<string[]>(props.data.roles)

    const handleChange = (role: string) => (event: any, checked: boolean) => {
        if (checked) {
            setValue([...value, role])
        } else {
            setValue(values => values.filter(value => value !== role))
        }
    }

    const onSave = () => {
        props.showLoader()
        UsersDataAccess.setUserRoles(dispatch)(props.data.id, value, props.onSuccess)
    }

    return (
        <div className={styles.mainContainer}>
            <Typography>{`${Locales.role}:`}</Typography>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value.includes("MODERATOR")}
                        onChange={handleChange("MODERATOR")}
                        value="MODERATOR"
                    />
                }
                label={Locales.moderator}
            />
            <FormControlLabel
                control={
                    <Checkbox checked={value.includes("EDITOR")} onChange={handleChange("EDITOR")} value="EDITOR" />
                }
                label={Locales.editor}
            />
            <FormControlLabel
                control={<Checkbox checked={value.includes("ADMIN")} onChange={handleChange("ADMIN")} value="ADMIN" />}
                label={Locales.admin}
            />
            <div className={styles.btnContainer}>
                <DialogButton label={Locales.save} onClick={onSave} />
            </div>
        </div>
    )
}

export default RolesContent
