import axios from "axios"

const getExternalImage = (url: string, onSuccess: (b64image: string, mime: string) => void) => {
    axios
        .get(url, {
            responseType: "arraybuffer"
        })
        .then(response => {
            const b64image = new Buffer(response.data, "binary").toString("base64")
            onSuccess(`data:${response.headers["content-type"]};base64,${b64image}`, response.headers["content-type"])
        })
        .catch(error => {})
}

export default { getExternalImage }
