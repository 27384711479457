const ColorPalette = {
    BLACK: "#34383f",
    WHITE: "#ffffff",
    BLUE1: "#67a0f1",
    BLUE2: "#90beff",
    BLUE3: "#bdd9ff",
    BLUE4: "#d6e2f3",
    ORANGE1: "#ff911f",
    ORANGE2: "#ffbf75",
    YELLOW1: "#ffecb0",
    YELLOW2: "#fdca27",
    YELLOW3: "#ddab0d",
    YELLOW4: "#ffe07f",
    GREY1: "#d8d8d8",
    GREY2: "#455a64",
    GREY3: "#6f7786",
    GREY4: "#fafafa",
    GREY5: "#6c6c6c",
    GREY6: "#d3d3d3",
    GREY7: "#c7c7c7",
    RED1: "#fc633e",
    RED2: "#f17252",
    GREEN1: "#68dd42",
    GREEN2: "#76c95c",
    GREEN3: "#d6ec6a",
    GREEN4: "#64c744"
}

export default ColorPalette
