import { makeStyles, Theme, createStyles } from "@material-ui/core"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            display: "flex",
            flexDirection: "column"
        },
        dataContainer: {
            paddingLeft: 16,
            paddingRight: 16,
            display: "flex",
            flexDirection: "column"
        },
        closeBtnContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
        }
    })
)

export default {
    styles
}
