import ActionTypes from "../utils/ActionTypes"
import { ActionType } from "../types/ActionType"
import { QuestionProposal, MinimalQuestionTypeResponse } from "../types/QuestionTypes"
import { ReportedQuestionTypeResponse } from "../types/FlagTypes"

export interface QuestionProposalActionType extends ActionType {
    proposals: QuestionProposal[]
}

export interface ReportedQuestionActionType extends ActionType {
    reportedQuestions: ReportedQuestionTypeResponse[]
}

export interface QuestionsActionType extends ActionType {
    questions: MinimalQuestionTypeResponse[]
}

export interface EditorsQuestionsActionType extends ActionType {
    editorsQuestions: MinimalQuestionTypeResponse[]
}

export const getAllQuestionProposalsAction = (proposals: QuestionProposal[]): QuestionProposalActionType => {
    return { type: ActionTypes.getAllQuestionProposals, proposals }
}

export const getReportedQuestionsAction = (
    reportedQuestions: ReportedQuestionTypeResponse[]
): ReportedQuestionActionType => {
    return { type: ActionTypes.getReportedQuestions, reportedQuestions }
}

export const getPopularQuestionsAction = (questions: MinimalQuestionTypeResponse[]): QuestionsActionType => {
    return { type: ActionTypes.getPopularQuestions, questions }
}

export const getEditorsQuestionsAction = (
    editorsQuestions: MinimalQuestionTypeResponse[]
): EditorsQuestionsActionType => {
    return { type: ActionTypes.getEditorsQuestions, editorsQuestions }
}
