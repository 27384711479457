import { makeStyles, createStyles, Theme } from "@material-ui/core"
import Colors from "../../../../utils/Colors"
import FontWeights from "../../../../utils/FontWeights"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            display: "flex",
            flexDirection: "column",
            paddingLeft: 16,
            paddingRight: 16,
            width: 250
        },
        closeBtnContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
        },
        container: {
            display: "flex",
            flexDirection: "column",
            paddingLeft: 24,
            paddingTop: 28
        },
        btnContainer: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            paddingTop: 36,
            paddingBottom: 20
        }
    })
)

const textFieldStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "inherit",
            marginLeft: 24
        }
    })
)

const modTextFieldStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "inherit",
            marginLeft: 24,
            marginTop: 28
        }
    })
)

const ddlStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: 28,
            marginLeft: 24,
            flexGrow: 1
        }
    })
)

const smallTextFieldStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "50%",
            marginTop: 28,
            marginLeft: 24
        }
    })
)

const selectStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            flexGrow: 1,
            marginLeft: 24,
            marginTop: 28
        }
    })
)

const infoLabelStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.primary
        }
    })
)

const infoLabelTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: 16
        }
    })
)

const infoLabelLocationStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.grey
        }
    })
)

const deleteLabelStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontWeight: FontWeights.BOLD,
            paddingTop: 16
        }
    })
)

const modRequestTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontWeight: FontWeights.BOLD,
            paddingBottom: 8
        }
    })
)

export default {
    styles,
    textFieldStyle,
    smallTextFieldStyle,
    ddlStyle,
    selectStyle,
    infoLabelTypoStyle,
    infoLabelLocationStyle,
    infoLabelStyle,
    deleteLabelStyle,
    modTextFieldStyle,
    modRequestTypoStyle
}
