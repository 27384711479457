import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            flexGrow: 1,
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            marginTop: 100,
            marginRight: 50,
            marginLeft: 250,
            marginBottom: 50,
            [theme.breakpoints.down("xs")]: {
                marginRight: 12,
                marginLeft: 12
            }
        },
        shiftedContainer: {
            flexGrow: 1,
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            }),
            marginTop: 100,
            marginRight: 50,
            marginLeft: 50,
            marginBottom: 50,
            [theme.breakpoints.down("xs")]: {
                marginRight: 12,
                marginLeft: 12
            }
        }
    })
)

export default {
    styles
}
