import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Paper from "@material-ui/core/Paper"
import TableBody from "@material-ui/core/TableBody"
import Styles from "./UserManagementScreen.style"
import Locales from "../../../localization/Localization"
import Dialog from "../../common_components/Dialog/Dialog"
import PaginationComponent from "../../common_components/PaginationComponent/PaginationComponent"
import UsersDataAccess from "../../../data_access/UsersDataAccess"
import { MinimalUserTypeResponse, PaginatedUsersResponse } from "../../../types/UserTypes"
import RolesContent from "../../common_components/DialogContent/RolesContent/RolesContent"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/DeleteRounded"
import SecurityIcon from "@material-ui/icons/SecurityRounded"
import SearchBar from "../../common_components/SearchBar/SearchBar"
import { debounce } from "../../../utils/TimingUtils"
import Loading from "../../common_components/Loading/Loading"
import DeleteUserContent from "../../common_components/DialogContent/DeleteUserContent/DeleteUserContent"

interface UserManagementState {
    openDialog: boolean
    dialogContent?: JSX.Element
}

export interface UserRowType {
    name: string
    surname: string
    email: string
}

const UserManagementScreen: React.FC = () => {
    const dispatch = useDispatch()

    const headerCellStyle = Styles.headerCellStyle({})
    const headerCellWidthStyle = Styles.headerCellWidthStyle({})

    const [users, setUsers] = useState<PaginatedUsersResponse>()
    const [pageState, setPageState] = useState({
        page: 0,
        rowsPerPage: 8
    })
    const [loading, setLoading] = useState<boolean>(false)
    const [query, setQuery] = useState<string>("")

    const onUsersLoaded = (users: PaginatedUsersResponse) => {
        setUsers(users)
    }

    const getAllUsers = (page: number, pageSize: number) => {
        UsersDataAccess.getAllUsers(dispatch)(page, pageSize, onUsersLoaded)
    }

    const searchUsers = (query: string, page: number, pageSize: number) => {
        UsersDataAccess.searchUsers(dispatch)(query, page, pageSize, onUsersLoaded)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPageState({ ...pageState, page: newPage })
        if (query.length > 0) {
            searchUsers(query, newPage, pageState.rowsPerPage)
        } else {
            getAllUsers(newPage, pageState.rowsPerPage)
        }
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const pageSize = +event.target.value
        setPageState({ rowsPerPage: pageSize, page: 0 })
        getAllUsers(0, pageSize)
    }

    const [state, setState] = useState<UserManagementState>({
        openDialog: false,
        dialogContent: undefined
    })

    useEffect(() => {
        getAllUsers(pageState.page, pageState.rowsPerPage)
        //eslint-disable-next-line
    }, [])

    const openRolesDialog = (row: MinimalUserTypeResponse) => (e: any) => {
        const reloadUsers = () => {
            getAllUsers(pageState.page, pageState.rowsPerPage)
            closeDialog()
            hideLoader()
        }
        setState({
            openDialog: true,
            dialogContent: <RolesContent data={row} onSuccess={reloadUsers} showLoader={showLoader} />
        })
    }

    const openDeleteDialog = (row: MinimalUserTypeResponse) => (e: any) => {
        const reloadUsers = () => {
            getAllUsers(pageState.page, pageState.rowsPerPage)
            closeDialog()
            hideLoader()
        }
        setState({
            openDialog: true,
            dialogContent: <DeleteUserContent data={row} onSuccess={reloadUsers} showLoader={showLoader} />
        })
    }

    const handleSearchBarChange = debounce((query: string) => {
        setQuery(query)
        if (query.length > 0) {
            searchUsers(query, pageState.page, pageState.rowsPerPage)
        } else {
            getAllUsers(pageState.page, pageState.rowsPerPage)
        }
        setPageState({ ...pageState, page: 0 })
    }, 500)

    const closeDialog = () => {
        setState({
            openDialog: false,
            dialogContent: undefined
        })
    }

    const showLoader = () => setLoading(true)
    const hideLoader = () => setLoading(false)

    return (
        <div>
            <Paper>
                {users != null && (
                    <>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell classes={headerCellStyle}>{Locales.name}</TableCell>
                                    <TableCell classes={headerCellStyle}>{Locales.surname}</TableCell>
                                    <TableCell classes={headerCellWidthStyle}>{Locales.email}</TableCell>
                                    <TableCell align="right">
                                        {<SearchBar onChange={handleSearchBarChange} />}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.items.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{row.firstName}</TableCell>
                                        <TableCell>{row.lastName}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={openRolesDialog(row)}>
                                                <SecurityIcon />
                                            </IconButton>
                                            <IconButton onClick={openDeleteDialog(row)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <PaginationComponent
                            rows={users.totalElements}
                            page={pageState.page}
                            rowsPerPage={pageState.rowsPerPage}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </Paper>
            <Dialog
                open={state.openDialog}
                content={state.dialogContent}
                onClose={closeDialog}
                showLoader={showLoader}
            />
            {loading && <Loading fullScreen />}
        </div>
    )
}

export default UserManagementScreen
