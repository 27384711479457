import React from "react"
import { useDispatch } from "react-redux"
import Typography from "@material-ui/core/Typography"
import DialogFromMUI from "@material-ui/core/Dialog"
import Locales from "../../../../localization/Localization"
import Styles from "./DeleteSchoolContent.style"
import { EducationTypeResponse } from "../../../../types/EducationTypes"
import DialogButton from "../../DialogButton/DialogButton"
import EducationDataAccess from "../../../../data_access/EducationDataAccess"
import CloseButton from "../../CloseButton/CloseButton"

interface DeleteSchoolContentProps {
    open: boolean
    infoLabel: EducationTypeResponse
    onClose: () => void
    showLoader: () => void
}

const DeleteSchoolContent: React.FC<DeleteSchoolContentProps> = props => {
    const dispatch = useDispatch()

    const styles = Styles.styles({})
    const labelStyle = Styles.infoLabelStyle({})
    const locationLabelStyle = Styles.infoLabelLocationStyle({})
    const infoLabelTypoStyle = Styles.infoLabelTypoStyle({})

    const onDelete = () => {
        props.showLoader()
        EducationDataAccess.deleteEducationalFacility(dispatch)(props.infoLabel.id, props.onClose)
    }

    return (
        <DialogFromMUI maxWidth={"lg"} open={props.open}>
            <div className={styles.mainContainer}>
                <CloseButton onClose={props.onClose} />
                <div className={styles.dataContainer}>
                    <Typography classes={infoLabelTypoStyle}>{`${Locales.removeFromSystem}:`}</Typography>
                    <Typography classes={labelStyle}>{props.infoLabel.name}</Typography>
                    <Typography classes={locationLabelStyle}>{props.infoLabel.location}</Typography>
                    <div className={styles.btnContainer}>
                        <DialogButton alert label={Locales.delete} onClick={onDelete} />
                    </div>
                </div>
            </div>
        </DialogFromMUI>
    )
}

export default DeleteSchoolContent
