const ActionTypes = {
    getMyUser: "GET_MY_USER",
    getAllEducationalFacilities: "GET_ALL_EDUCATIONAL_FACILITIES",
    getAllQuestionProposals: "GET_ALL_QUESTION_PROPOSALS",
    getAllTopics: "GET_ALL_TOPICS",
    getAllSubtopics: "GET_ALL_SUBTOPICS",
    getAllLocations: "GET_ALL_LOCATIONS",
    getReportedQuestions: "GET_REPORTED_QUESTIONS",
    getReportedComments: "GET_REPORTED_COMMENTS",
    getPopularQuestions: "GET_POPULAR_QUESTIONS",
    getEditorsQuestions: "GET_EDITORS_QUESTIONS",
    getFeedbacks: "GET_FEEDBACKS",
}

export default ActionTypes
