import React from "react"
import Styles from "./CloseButton.style"
import CloseIcon from "@material-ui/icons/CloseRounded"
import { IconButton } from "@material-ui/core"

interface CloseButtonProps {
    onClose: () => void
}

const CloseButton: React.FC<CloseButtonProps> = props => {
    const styles = Styles.styles({})

    return (
        <div className={styles.container}>
            <IconButton onClick={props.onClose}>
                <CloseIcon />
            </IconButton>
        </div>
    )
}
export default CloseButton
