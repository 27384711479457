import ActionTypes from "../utils/ActionTypes"
import { ActionType } from "../types/ActionType"
import { FeedbackTypeResponse } from "../types/FeedbackType"

export interface FeedbackActionType extends ActionType {
    feedbacks: FeedbackTypeResponse[]
}

export const getFeedbacksAction = (feedbacks: FeedbackTypeResponse[]): FeedbackActionType => {
    return { type: ActionTypes.getFeedbacks, feedbacks }
}
