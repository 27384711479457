import ActionTypes from "../utils/ActionTypes"
import { Cache } from "../types/CacheType"
import {
    QuestionProposalActionType,
    ReportedQuestionActionType,
    QuestionsActionType
} from "../actions/QuestionsActions"
import { QuestionProposal, MinimalQuestionTypeResponse } from "../types/QuestionTypes"
import { ReportedQuestionTypeResponse } from "../types/FlagTypes"
import { EditorsQuestionsActionType } from "../actions/QuestionsActions"

export interface QuestionsReducerStateTypes {
    proposals: QuestionProposal[]
    proposalsCache: Cache
    reportedQuestions: ReportedQuestionTypeResponse[]
    reportedQuestionsCache: Cache
    popularQuestions: MinimalQuestionTypeResponse[]
    popularQuestionsCache: Cache
    editorsQuestions: MinimalQuestionTypeResponse[]
    editorsQuestionIds: number[]
    editorsQuestionsCache: Cache
}

const initialState: QuestionsReducerStateTypes = {
    proposals: [],
    proposalsCache: undefined,
    reportedQuestions: [],
    reportedQuestionsCache: undefined,
    popularQuestions: [],
    popularQuestionsCache: undefined,
    editorsQuestions: [],
    editorsQuestionIds: [],
    editorsQuestionsCache: undefined
}

const QuestionsReducer = (
    state = initialState,
    action: QuestionProposalActionType | ReportedQuestionActionType | QuestionsActionType | EditorsQuestionsActionType
): QuestionsReducerStateTypes => {
    switch (action.type) {
        case ActionTypes.getAllQuestionProposals: {
            const castedAction = action as QuestionProposalActionType
            return Object.assign({}, state, {
                proposals: castedAction.proposals,
                proposalsCache: new Date()
            })
        }
        case ActionTypes.getReportedQuestions: {
            const castedAction = action as ReportedQuestionActionType
            return Object.assign({}, state, {
                reportedQuestions: castedAction.reportedQuestions,
                reportedQuestionsCache: new Date()
            })
        }
        case ActionTypes.getPopularQuestions: {
            const castedAction = action as QuestionsActionType
            return Object.assign({}, state, {
                popularQuestions: castedAction.questions,
                popularQuestionsCache: new Date()
            })
        }
        case ActionTypes.getEditorsQuestions: {
            const castedAction = action as EditorsQuestionsActionType
            return Object.assign({}, state, {
                editorsQuestions: castedAction.editorsQuestions,
                editorsQuestionIds: castedAction.editorsQuestions.map(q => q.id),
                editorsQuestionsCache: new Date()
            })
        }
        default: {
            return state
        }
    }
}

export default QuestionsReducer
