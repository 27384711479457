import React, { useEffect, useState } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { RouteComponentProps } from "react-router"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import Button from "@material-ui/core/Button"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import Typography from "@material-ui/core/Typography"
import { executeCachedRequest } from "../../../utils/CacheValidator"
import QuestionsDataAccess from "../../../data_access/QuestionsDataAccess"
import { QuestionsReducerStateTypes } from "../../../reducers/QuestionsReducer"
import Styles from "./QuestionProposals.style"
import Locales from "../../../localization/Localization"
import { AnswerProps, AnswerLetter } from "../../../types/AnswerTypes"
import AnswersColumn from "../../common_components/AnswersColumn/AnswersColumn"
import AnswersGrid from "../../common_components/AnswersGrid/AnswersGrid"
import { QuestionProposal } from "../../../types/QuestionTypes"
import Endpoints from "../../../environments/endpoints"
import LockIcon from "@material-ui/icons/LockRounded"
import Loading from "../../common_components/Loading/Loading"
import { handleOpenModalClick } from "../../../utils/ModalMethods"
import DecisionModal from "../../common_components/DecisionModal/DecisionModal"
import { DecisionModalProps } from "../../../types/ModalTypes"
import { ExplanationTypeRequest } from "../../../types/FlagTypes"
import { addStyles, StaticMathField } from "react-mathquill"
import { checkIfMathExpression, unwrapMathInputFromTags } from "../../../utils/MathLatexHandlers"
import PaginationComponent from "../../common_components/PaginationComponent/PaginationComponent"

addStyles()

const NUMBER_OF_CHARS_FOR_WRAP = 64
const ANSWER_LETTERS: AnswerLetter[] = ["A", "B", "C", "D"]

const QuestionProposals: React.FC<RouteComponentProps> = props => {
    const dispatch = useDispatch()
    const allQuestions: QuestionsReducerStateTypes = useSelector((state: any) => state.QuestionsReducer, shallowEqual)

    const styles = Styles.styles({})
    const panelSummaryStyle = Styles.panelSummaryStyle({})
    const questionTypoStyle = Styles.questionTypoStyle({})
    const questionImageTypoStyle = Styles.questionImageTypoStyle({})
    const difficultyAuthorTitleTypoStyle = Styles.difficultyAuthorTitleTypoStyle({})
    const difficultyTypoStyle = Styles.difficultyTypoStyle({})
    const authorTypoStyle = Styles.authorTypoStyle({})
    const editButtonStyle = Styles.editButtonStyle({})
    const dismissButtonStyle = Styles.dismissButtonStyle({})
    const acceptButtonStyle = Styles.acceptButtonStyle({})

    const [pageState, setPageState] = useState({
        page: 0,
        rowsPerPage: 8
    })
    const sliceAllQuestions = (page: number, rowsPerPage: number) =>
        allQuestions.proposals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const [questions, setQuestions] = useState<QuestionProposal[]>(
        sliceAllQuestions(pageState.page, pageState.rowsPerPage)
    )

    const [loading, setLoading] = useState<boolean>(false)
    const hideLoader = () => setLoading(false)
    const showLoader = () => setLoading(true)

    const hideModal = () => {
        setModalState(prev => {
            return { ...prev, open: false }
        })
        hideLoader()
    }

    useEffect(() => {
        executeCachedRequest(QuestionsDataAccess.getAllQuestionProposals(dispatch), allQuestions.proposalsCache)
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        setQuestions(sliceAllQuestions(pageState.page, pageState.rowsPerPage))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allQuestions])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPageState({ ...pageState, page: newPage })
        setQuestions(sliceAllQuestions(newPage, pageState.rowsPerPage))
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const pageSize = +event.target.value
        setPageState({ rowsPerPage: pageSize, page: 0 })
        setQuestions(sliceAllQuestions(0, pageSize))
    }

    const [modalState, setModalState] = useState<DecisionModalProps>({
        open: false,
        callback: () => {},
        accept: undefined,
        type: "questionProposal",
        reporter: [],
        reported: undefined,
        handleClose: hideModal,
        flagId: undefined
    })

    const requiresWrapping = (answer: AnswerProps) => answer.answer.length > NUMBER_OF_CHARS_FOR_WRAP

    const calculateAnswersLayout = (answers: AnswerProps[], correctAnswer: AnswerProps) => {
        if (answers.some(requiresWrapping)) {
            return <AnswersColumn answers={answers} correctAnswer={correctAnswer.answerLetter} />
        }
        return <AnswersGrid answers={answers} correctAnswer={correctAnswer.answerLetter} type="text" />
    }

    const onEdit = (proposal: QuestionProposal) => () => {
        const proposalId: string = proposal.id.toString()
        sessionStorage.setItem(proposalId, JSON.stringify(proposal))
        props.history.push(Endpoints.appEndpoints.editQuestionProposal.replace(":proposalid", proposalId))
    }

    const handleClickDismiss = (proposal: QuestionProposal) => () => {
        handleOpenModalClick(proposal, setModalState, false, onDismiss)
    }

    const onDismiss = (proposalId: number, payload: ExplanationTypeRequest) => {
        showLoader()
        QuestionsDataAccess.declineQuestionProposal(dispatch)(proposalId, payload, hideModal)
    }

    const onAccept = (proposalId: number) => () => {
        showLoader()
        QuestionsDataAccess.approveQuestionProposal(dispatch)(proposalId, hideLoader)
    }

    return (
        <div>
            {questions.length > 0 ? (
                <>
                    {questions.map(proposal => {
                        const answers: AnswerProps[] = proposal.answers.map((answer, index) => {
                            return { answerLetter: ANSWER_LETTERS[index], answer: answer }
                        })
                        const correctAnswer: AnswerProps | undefined = answers.find(
                            answer => answer.answer === proposal.correctAnswer
                        )

                        return (
                            <ExpansionPanel key={proposal.id}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon color="primary" />}
                                    classes={panelSummaryStyle}
                                >
                                    {proposal.subtopic.isPrivate && <LockIcon color="secondary" />}
                                    {proposal.questionImage != null && proposal.questionText.length === 0 ? (
                                        <Typography
                                            classes={questionImageTypoStyle}
                                        >{`(${Locales.questionContainsImage})`}</Typography>
                                    ) : checkIfMathExpression(proposal.questionText) ? (
                                        <div className={styles.mathQuestionContainer}>
                                            <StaticMathField>
                                                {unwrapMathInputFromTags(proposal.questionText)}
                                            </StaticMathField>
                                        </div>
                                    ) : (
                                        <Typography
                                            classes={questionTypoStyle}
                                            onClick={event => event.stopPropagation()}
                                            onFocus={event => event.stopPropagation()}
                                        >
                                            {proposal.questionText}
                                        </Typography>
                                    )}
                                    <div className={styles.difficultyAuthorContainer}>
                                        <Typography
                                            classes={difficultyAuthorTitleTypoStyle}
                                        >{`${Locales.difficulty}:`}</Typography>
                                        <Typography classes={difficultyTypoStyle}>
                                            {proposal.predictedDifficulty.toFixed(2)}
                                        </Typography>
                                    </div>
                                    <div className={styles.difficultyAuthorContainer}>
                                        <Typography
                                            classes={difficultyAuthorTitleTypoStyle}
                                        >{`${Locales.author}:`}</Typography>
                                        <Typography
                                            classes={authorTypoStyle}
                                        >{`${proposal.user.firstName} ${proposal.user.lastName}`}</Typography>
                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div className={styles.detailsContainer}>
                                        <div className={styles.answersContainer}>
                                            {proposal.questionImage != null && (
                                                <img
                                                    src={proposal.questionImage}
                                                    alt="question"
                                                    className={styles.questionImage}
                                                />
                                            )}
                                            {proposal.imageAnswers ? (
                                                <AnswersGrid
                                                    answers={answers}
                                                    correctAnswer={correctAnswer!.answerLetter!}
                                                    type="image"
                                                />
                                            ) : (
                                                calculateAnswersLayout(answers, correctAnswer!)
                                            )}
                                        </div>
                                        <div className={styles.actionsContainer}>
                                            <div className={styles.topicSubtopicContainer}>
                                                <Typography
                                                    classes={difficultyAuthorTitleTypoStyle}
                                                >{`#${proposal.topic.name}`}</Typography>
                                                <div className={styles.dotDelimiter} />
                                                <Typography
                                                    classes={difficultyAuthorTitleTypoStyle}
                                                >{`#${proposal.subtopic.name}`}</Typography>
                                            </div>
                                            <div>
                                                <Button
                                                    classes={dismissButtonStyle}
                                                    onClick={handleClickDismiss(proposal)}
                                                >
                                                    {Locales.dismiss}
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    classes={editButtonStyle}
                                                    onClick={onEdit(proposal)}
                                                >
                                                    {Locales.edit}
                                                </Button>
                                                <Button classes={acceptButtonStyle} onClick={onAccept(proposal.id)}>
                                                    {Locales.accept}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    })}
                    <PaginationComponent
                        rows={allQuestions.proposals.length}
                        page={pageState.page}
                        rowsPerPage={pageState.rowsPerPage}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </>
            ) : (
                <Typography classes={questionTypoStyle}>{Locales.thereIsNoProposedQuestions}</Typography>
            )}
            {loading && <Loading fullScreen />}
            <DecisionModal {...modalState} />
        </div>
    )
}

export default QuestionProposals
