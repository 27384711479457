import ActionTypes from "../utils/ActionTypes"
import {
    EducationTypeResponse,
    PRIMARY_EDUCATION_ID,
    SECONDARY_EDUCATION_ID,
    TERTIARY_EDUCATION_ID
} from "../types/EducationTypes"
import { EducationalFacilitiesAction } from "../actions/EducationActions"
import { Cache } from "../types/CacheType"

export interface EducationalFacilitiesStateTypes {
    primaryEducation: EducationTypeResponse[]
    secondaryEducation: EducationTypeResponse[]
    tertiaryEducation: EducationTypeResponse[]
    educationCache: Cache
}

const initialState: EducationalFacilitiesStateTypes = {
    primaryEducation: [],
    secondaryEducation: [],
    tertiaryEducation: [],
    educationCache: undefined
}
const EducationReducer = (
    state = initialState,
    action: EducationalFacilitiesAction
): EducationalFacilitiesStateTypes => {
    switch (action.type) {
        case ActionTypes.getAllEducationalFacilities: {
            return Object.assign({}, state, {
                primaryEducation: action.educationalFacilities.filter(edu => edu.type === PRIMARY_EDUCATION_ID),
                secondaryEducation: action.educationalFacilities.filter(edu => edu.type === SECONDARY_EDUCATION_ID),
                tertiaryEducation: action.educationalFacilities.filter(edu => edu.type === TERTIARY_EDUCATION_ID),
                educationCache: new Date()
            })
        }
        default: {
            return state
        }
    }
}

export default EducationReducer
