import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import FontWeights from "../../../utils/FontWeights"
import Colors from "../../../utils/Colors"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: 136,
            padding: 0,
            borderRadius: 4,
            border: `1px solid ${Colors.questionCreationAnswerBorder}`,
            marginBottom: 28,
            [theme.breakpoints.down("xs")]: {
                width: "100%",
                marginTop: 8,
                marginBottom: 8
            }
        },
        imageStyle: {
            height: 134,
            width: 134,
            objectFit: "contain",
            borderRadius: 4,
            [theme.breakpoints.down("xs")]: {
                width: "100%"
            }
        },
        imageInput: {
            display: "none"
        },
        imageInputLabelElement: {
            marginBottom: 28,
            [theme.breakpoints.down("xs")]: {
                width: "100%"
            }
        },
        contentContainer: {
            width: "100%"
        },
        emptyImageContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: 134,
            width: 134,
            backgroundColor: Colors.questionCreationEmptyImageBackground,
            borderRadius: 4,
            [theme.breakpoints.down("xs")]: {
                width: "100%"
            }
        },
        footerContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "space-between"
        }
    })
)

const buttonStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            height: "100%",
            width: 136,
            padding: 0,
            border: `1px solid ${Colors.questionCreationAnswerBorder}`,
            [theme.breakpoints.down("xs")]: {
                width: "100%"
            }
        }
    })
)

const answerLetterTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 24,
            fontWeight: FontWeights.BOLD,
            textAlign: "left",
            paddingLeft: 16,
            overflow: "visible",
            color: Colors.questionCreationAnswerLetter
        }
    })
)

const emptyImageIconStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 56,
            height: 56,
            color: Colors.questionCreationEmptyImageIcon
        }
    })
)

const disabledIconStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.questionCreationDeleteImageDisabled,
            marginRight: 12
        }
    })
)

const iconButtonStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.questionCreationDeleteImageActive
        }
    })
)

export default {
    styles,
    buttonStyle,
    answerLetterTypoStyle,
    emptyImageIconStyle,
    disabledIconStyle,
    iconButtonStyle
}
