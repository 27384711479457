import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import Colors from "../../../../utils/Colors"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            display: "flex",
            flexDirection: "column"
        },
        dataContainer: {
            paddingLeft: 16,
            paddingRight: 16,
            display: "flex",
            flexDirection: "column"
        },
        closeBtnContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
        },
        btnContainer: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            paddingTop: 36,
            paddingBottom: 20
        }
    })
)

const infoLabelStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.dialogInfoLabelFont
        }
    })
)

const infoLabelTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: 16
        }
    })
)

const infoLabelLocationStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.dialogLocationLabelFont
        }
    })
)

export default {
    styles,
    infoLabelLocationStyle,
    infoLabelStyle,
    infoLabelTypoStyle
}
