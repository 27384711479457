import React from "react"
import TablePagination from "@material-ui/core/TablePagination"
import Locales from "../../../localization/Localization"
import Styles from "./PaginationComponent.style"

interface PaginationComponentsProps {
    rows: number
    page: number
    rowsPerPage: number
    onChangePage: (e: any, newPage: number) => void
    onChangeRowsPerPage: (e: any) => void
    button?: JSX.Element
}

const PaginationComponent: React.FC<PaginationComponentsProps> = props => {
    const styles = Styles.styles({})

    return (
        <div className={styles.paginationStyle}>
            <div className={styles.paginationButtonContainer}>{props.button}</div>

            <TablePagination
                rowsPerPageOptions={[8, 16, 24]}
                component="div"
                count={props.rows}
                rowsPerPage={props.rowsPerPage}
                labelRowsPerPage={Locales.rowsPerPage}
                page={props.page}
                backIconButtonProps={{
                    "aria-label": Locales.previousPage
                }}
                nextIconButtonProps={{
                    "aria-label": Locales.nextPage
                }}
                onChangePage={props.onChangePage}
                onChangeRowsPerPage={props.onChangeRowsPerPage}
            />
        </div>
    )
}

export default PaginationComponent
