import { Theme, makeStyles, createStyles } from "@material-ui/core/styles"
import Colors from "../../../utils/Colors"

const inputStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 324,
            height: 48
        }
    })
)

const iconStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.searchIcon
        }
    })
)

export default {
    inputStyle,
    iconStyle
}
