import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            textAlign: "center"
        },
        confirmButtonContainer: {
            display: "flex",
            justifyContent: "center",
            marginBottom: "2em"
        }
    })
)

const title = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "0 24px 8px 24px"
        }
    })
)
const description = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "0 24px 28px 24px"
        }
    })
)

export default {
    styles,
    title,
    description
}
