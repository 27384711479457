import axios from "axios"
import { Dispatch } from "redux"
import Endpoints from "../environments/endpoints"
import { getAllSubtopicsAction } from "../actions/SubtopicsActions"
import { AuthHeader } from "../utils/AuthHeader"
import { Token } from "../utils/StorageKeys"

const getAllSubtopics = (dispatch: Dispatch<any>) => () => {
    axios
        .get(`${Endpoints.apiEndpoint}/subtopics`, AuthHeader(localStorage.getItem(Token)))
        .then(response => dispatch(getAllSubtopicsAction(response.data)))
        .catch(error => {})
}

export default {
    getAllSubtopics
}
