import React, { useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import { Paper, DialogTitle, DialogContent, TextField, Snackbar, SnackbarContent } from "@material-ui/core"
import OrangeButton from "../../common_components/OrangeButton/OrangeButton"
import NotificationsDataAccess from "../../../data_access/NotificationsDataAccess"
import Locales from "../../../localization/Localization"
import ConfirmationDialog from "../../common_components/ConfirmationDialog/ConfirmationDialog"
import Styles from "./AdminMessage.style"

type SnackStateType = "success" | "error" | undefined

const AdminMessage: React.FC<RouteComponentProps> = props => {
    const input = Styles.input({})
    const paper = Styles.paper({})
    const text = Styles.text({})
    const snackSuccess = Styles.snackSuccess({})
    const snackError = Styles.snackError({})

    const [message, setMessage] = useState<string>("")
    const [dialogState, setDialogState] = useState<boolean>(false)
    const [snackState, setSnackState] = useState<SnackStateType>(undefined)

    const toggleDialog = () => {
        setDialogState(!dialogState)
    }

    const toggleSnack = (type: SnackStateType) => {
        setSnackState(type)
    }

    const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value)
    }

    const handleSnackOnClose = () => {
        toggleSnack(undefined)
    }

    const handleAccept = () => {
        if (message.length >= 3) {
            NotificationsDataAccess.sendGlobalNotification({ message }, onSuccess, onError)
        }
    }

    const onSuccess = () => {
        setMessage("")
        toggleSnack("success")
    }

    const onError = () => {
        toggleSnack("error")
    }

    const renderSnack = () => {
        if (snackState === "error") {
            return <SnackbarContent message={Locales.notificationError} classes={snackError} />
        } else if (snackState === "success") {
            return <SnackbarContent message={Locales.notificationSuccess} classes={snackSuccess} />
        }
        return null
    }

    return (
        <>
            <Paper classes={paper}>
                <DialogTitle classes={text}>{Locales.adminMessageTitle}</DialogTitle>
                <DialogContent classes={text}>{Locales.adminMessageText}</DialogContent>
                <TextField
                    classes={input}
                    variant="outlined"
                    onChange={handleMessageChange}
                    value={message}
                    placeholder={Locales.adminMessagePlaceholder}
                />
                <OrangeButton
                    label={Locales.adminMessageButton}
                    onClick={toggleDialog}
                    centered
                    disabled={message.length < 3}
                />
            </Paper>

            <ConfirmationDialog
                open={dialogState}
                onClose={toggleDialog}
                onAccept={handleAccept}
                title={Locales.adminMessageTitle}
                description={Locales.adminMessageWarning}
            />
            <Snackbar open={Boolean(snackState)} autoHideDuration={5000} onClose={handleSnackOnClose}>
                {renderSnack()}
            </Snackbar>
        </>
    )
}
export default AdminMessage
