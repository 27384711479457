import React from "react"
import { Provider } from "react-redux"
import Theme from "./utils/MaterialUIThemeConfig"
import { ThemeProvider } from "@material-ui/styles"
import { BrowserRouter, Route, RouteProps } from "react-router-dom"
import storeConstruction from "./store/Store"
import Endpoints from "./environments/endpoints"
import AppHolder from "./components/AppHolder"

const store = storeConstruction()

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={Theme}>
                <BrowserRouter>
                    <Route
                        path={Endpoints.appEndpoints.root}
                        render={(props: RouteProps) => <AppHolder {...props} />}
                    />
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    )
}

export default App
