import ActionTypes from "../utils/ActionTypes"
import { ActionType } from "../types/ActionType"
import { Location } from "../types/UserTypes"

export interface LocationActionType extends ActionType {
    locations: Location[]
}

export const getAllLocationsAction = (locations: Location[]): LocationActionType => {
    return { type: ActionTypes.getAllLocations, locations }
}
