import { combineReducers } from "redux"
import UserReducer from "./UserReducer"
import EducationReducer from "./EducationReducer"
import QuestionsReducer from "./QuestionsReducer"
import TopicsReducer from "./TopicsReducer"
import SubtopicsReducer from "./SubtopicsReducer"
import CommentsReducer from "./CommentsReducer"
import FeedbackReducer from "./FeedbackReducer"
import LocationsReducer from "./LocationsReducer"

const RootReducer = combineReducers({
    UserReducer,
    EducationReducer,
    QuestionsReducer,
    TopicsReducer,
    SubtopicsReducer,
    CommentsReducer,
    FeedbackReducer,
    LocationsReducer
} as any)

export default RootReducer
