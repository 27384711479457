import ActionTypes from "../utils/ActionTypes"
import { Cache } from "../types/CacheType"
import { Location } from "../types/UserTypes"
import { LocationActionType } from "../actions/LocationsActions"

export interface LocationsReducerStateTypes {
    locations: Location[]
    locationsCache: Cache
}

const initialState: LocationsReducerStateTypes = {
    locations: [],
    locationsCache: undefined
}

const LocationsReducer = (state = initialState, action: LocationActionType): LocationsReducerStateTypes => {
    switch (action.type) {
        case ActionTypes.getAllLocations: {
            return Object.assign({}, state, {
                locations: [...action.locations],
                locationsCache: new Date()
            })
        }
        default: {
            return state
        }
    }
}

export default LocationsReducer
