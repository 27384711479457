import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import Colors from "../../../utils/Colors"

const deleteIconStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.deleteIcon
        }
    })
)

export default {
    deleteIconStyle
}
