import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import Colors from "../../../utils/Colors"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        closeBtnContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginRight: "-4vh"
        },
        buttonContainer: {
            display: "flex",
            justifyContent: "center"
        }
    })
)

const container = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: "0vh 4vh 4vh 4vh",
            width: "50vh"
        }
    })
)

const title = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "0vh",
            marginTop: "-2vh",
            marginBottom: "1vh",
            "& h2": {
                fontWeight: "bold"
            }
        }
    })
)

const text = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: "0.9em",
            margin: "1vh 0 2vh 0",
            "& a": {
                textDecoration: "none",
                color: Colors.primary
            }
        }
    })
)

const formContainer = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: "0 0 3vh"
        }
    })
)

const radio = createStyles({
    root: {
        "&$checked": {
            color: Colors.alert
        },
        "&:hover": {
            backgroundColor: Colors.questionCreation
        }
    },
    checked: {}
})

const radioLabel = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            margin: "0 0 .5vh 0",
            borderRadius: "4px",
            "&:hover": {
                backgroundColor: Colors.questionCreation
            }
        },
        label: {
            width: "100%"
        }
    })
)

const radioInputLabel = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            margin: "0",
            borderRadius: "4px",
            "&:hover": {
                backgroundColor: Colors.questionCreation
            }
        },
        label: {
            width: "100%"
        }
    })
)

const otherInput = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: "0 0 3vh"
        }
    })
)

export default { styles, container, title, text, otherInput, formContainer, radio, radioLabel, radioInputLabel }
