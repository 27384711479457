import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {},
        datePicker: {
            display: "flex",
            marginTop: 16
        },
        buttonContainer: {
            margin: "16px 0px",
            display: "flex",
            justifyContent: "center"
        }
    })
)

const paperStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            padding: 12,
            marginBottom: 12
        }
    })
)

const resultsPaperStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            padding: 12,
            marginTop: 12,
            marginBottom: 32
        }
    })
)

const resultsTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 32,
            textAlign: "center"
        }
    })
)

export default {
    styles,
    paperStyle,
    resultsPaperStyle,
    resultsTypoStyle
}
