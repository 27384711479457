import axios from "axios"
import Endpoints from "../environments/endpoints"
import { AuthHeader } from "../utils/AuthHeader"
import { Token } from "../utils/StorageKeys"
import {
    StatisticsRequest,
    StatisticsWithEducationalFacilitiesRequest,
    ComparisonResponse,
    LocationScore
} from "../types/StatisticsType"

const getNumberOfAddedAnswers = (
    startTimeMillis: number,
    endTimeMillis: number,
    onSuccess: (results: number) => void
) => {
    axios
        .get(
            `${Endpoints.apiEndpoint}/statistics/answers?startTimeMillis=${startTimeMillis}&endTimeMillis=${endTimeMillis}`,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => onSuccess(response.data))
        .catch(error => {})
}

const getNumberOfAddedQuestions = (
    startTimeMillis: number,
    endTimeMillis: number,
    onSuccess: (results: number) => void
) => {
    axios
        .get(
            `${Endpoints.apiEndpoint}/statistics/questions?startTimeMillis=${startTimeMillis}&endTimeMillis=${endTimeMillis}`,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => onSuccess(response.data))
        .catch(error => {})
}

const getNumberOfAddedSubtopics = (
    startTimeMillis: number,
    endTimeMillis: number,
    onSuccess: (results: number) => void
) => {
    axios
        .get(
            `${Endpoints.apiEndpoint}/statistics/subtopics?startTimeMillis=${startTimeMillis}&endTimeMillis=${endTimeMillis}`,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => onSuccess(response.data))
        .catch(error => {})
}

const getNumberOfAddedTopics = (
    startTimeMillis: number,
    endTimeMillis: number,
    onSuccess: (results: number) => void
) => {
    axios
        .get(
            `${Endpoints.apiEndpoint}/statistics/topics?startTimeMillis=${startTimeMillis}&endTimeMillis=${endTimeMillis}`,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => onSuccess(response.data))
        .catch(error => {})
}

const getNumberOfFilteredUsers = (
    payload: StatisticsWithEducationalFacilitiesRequest,
    onSuccess: (results: number) => void
) => {
    axios
        .post(`${Endpoints.apiEndpoint}/statistics/users`, payload, AuthHeader(localStorage.getItem(Token)))
        .then(response => onSuccess(response.data))
        .catch(error => {})
}

const getUserStatsForSubtopic = (
    subtopicId: number,
    payload: StatisticsWithEducationalFacilitiesRequest,
    onSuccess: (results: number) => void
) => {
    axios
        .post(
            `${Endpoints.apiEndpoint}/statistics/users/subtopic/${subtopicId}`,
            payload,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => onSuccess(response.data))
        .catch(error => {})
}

const getUserStatsForTopic = (
    topicId: number,
    payload: StatisticsWithEducationalFacilitiesRequest,
    onSuccess: (results: number) => void
) => {
    axios
        .post(
            `${Endpoints.apiEndpoint}/statistics/users/topic/${topicId}`,
            payload,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => onSuccess(response.data))
        .catch(error => {})
}

const getEducationalFacilityComparisonForSubtopic = (
    subtopicId: number,
    payload: StatisticsRequest,
    onSuccess: (results: ComparisonResponse[]) => void
) => {
    axios
        .post(
            `${Endpoints.apiEndpoint}/statistics/users/educationalFacilities/subtopic/${subtopicId}`,
            payload,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => onSuccess(response.data))
        .catch(error => {})
}

const getEducationalFacilityComparisonForTopic = (
    topicId: number,
    payload: StatisticsRequest,
    onSuccess: (results: ComparisonResponse[]) => void
) => {
    axios
        .post(
            `${Endpoints.apiEndpoint}/statistics/users/educationalFacilities/topic/${topicId}`,
            payload,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => onSuccess(response.data))
        .catch(error => {})
}

const sortByHighestScore = (a: LocationScore, b: LocationScore) => b.score - a.score

const getComparisonForLocationForTopic = (topicId: number, onSuccess: (results: LocationScore[]) => void) => {
    axios
        .get(
            `${Endpoints.apiEndpoint}/statistics/users/topic/${topicId}/avg-score`,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => onSuccess(response.data.sort(sortByHighestScore)))
        .catch(error => {})
}

const getComparisonForLocationForSubtopic = (subtopicId: number, onSuccess: (results: LocationScore[]) => void) => {
    axios
        .get(
            `${Endpoints.apiEndpoint}/statistics/users/subtopic/${subtopicId}/avg-score`,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => onSuccess(response.data.sort(sortByHighestScore)))
        .catch(error => {})
}

export default {
    getNumberOfAddedAnswers,
    getNumberOfAddedQuestions,
    getNumberOfAddedSubtopics,
    getNumberOfAddedTopics,
    getNumberOfFilteredUsers,
    getUserStatsForSubtopic,
    getUserStatsForTopic,
    getEducationalFacilityComparisonForSubtopic,
    getEducationalFacilityComparisonForTopic,
    getComparisonForLocationForTopic,
    getComparisonForLocationForSubtopic
}
