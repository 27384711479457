import React from "react"
import { useDispatch } from "react-redux"
import Typography from "@material-ui/core/Typography"
import Locales from "../../../../localization/Localization"
import Styles from "./DeleteUserContent.style"
import { MinimalUserTypeResponse } from "../../../../types/UserTypes"
import DialogButton from "../../DialogButton/DialogButton"
import UsersDataAccess from "../../../../data_access/UsersDataAccess"

interface DeleteUserContentProps {
    data: MinimalUserTypeResponse
    onSuccess: () => void
    showLoader: () => void
}

const DeleteUserContent: React.FC<DeleteUserContentProps> = props => {
    const dispatch = useDispatch()
    const styles = Styles.styles({})
    const labelStyle = Styles.infoLabelStyle({})

    const onSave = () => {
        props.showLoader()
        UsersDataAccess.deleteUser(dispatch)(props.data.id, props.onSuccess)
    }

    return (
        <div>
            <Typography>{`${Locales.removeFromSystem}:`}</Typography>
            <Typography classes={labelStyle}>
                {`${props.data.firstName} ${props.data.lastName} (${props.data.email}):`}
            </Typography>
            <div className={styles.btnContainer}>
                <DialogButton label={Locales.remove} onClick={onSave} />
            </div>
        </div>
    )
}

export default DeleteUserContent
