declare global {
    interface Number {
        isBetween(lowerBound: number, upperBound: number): boolean
    }
}
//eslint-disable-next-line
Number.prototype.isBetween = function(lowerBound: number, upperBound: number): boolean {
    return lowerBound <= this && this <= upperBound
}

export {}
