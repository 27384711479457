import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import Colors from "../../../utils/Colors"
import SrednjaHrLogo from "../../../images/srednja-logo.svg"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex"
        },
        logo: {
            height: 38,
            width: 130,
            background: `url(${SrednjaHrLogo}) no-repeat`,
            backgroundSize: "contain",
            cursor: "pointer"
        }
    })
)

const appBarStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            zIndex: theme.zIndex.drawer + 1
        }
    })
)

const barStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            justifyContent: "space-between"
        }
    })
)

const cmsTitleTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignSelf: "center"
        }
    })
)

const iconStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.appBarIconStyle
        }
    })
)

export default {
    styles,
    appBarStyle,
    cmsTitleTypoStyle,
    barStyle,
    iconStyle
}
