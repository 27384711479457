import { MinimalSubtopicTypeResponse } from "../types/SubtopicTypes"
import { SubtopicActionType } from "../actions/SubtopicsActions"
import ActionTypes from "../utils/ActionTypes"
import { Cache } from "../types/CacheType"

export interface SubtopicReducerStateTypes {
    subtopics: MinimalSubtopicTypeResponse[]
    subtopicsCache: Cache
}

const initialState: SubtopicReducerStateTypes = {
    subtopics: [],
    subtopicsCache: undefined
}

const SubtopicsReducer = (state = initialState, action: SubtopicActionType): SubtopicReducerStateTypes => {
    switch (action.type) {
        case ActionTypes.getAllSubtopics: {
            const castedAction = action as SubtopicActionType
            return Object.assign({}, state, {
                subtopics: [...castedAction.subtopics],
                subtopicsCache: new Date()
            })
        }
        default: {
            return state
        }
    }
}

export default SubtopicsReducer
