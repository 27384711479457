import React, { HTMLAttributes } from "react"
import Typography from "@material-ui/core/Typography"
import TextField, { BaseTextFieldProps } from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import MenuItem from "@material-ui/core/MenuItem"
import { ValueContainerProps } from "react-select/src/components/containers"
import { ControlProps } from "react-select/src/components/Control"
import { MenuProps, NoticeProps } from "react-select/src/components/Menu"
import { OptionProps } from "react-select/src/components/Option"
import { SingleValueProps } from "react-select/src/components/SingleValue"
import { Suggestion, SuggestionWithIcon } from "./Autocomplete"
import Locales from "../../../localization/Localization";

type InputComponentProps = Pick<BaseTextFieldProps, "inputRef"> & HTMLAttributes<HTMLDivElement>

const inputComponent = ({ inputRef, ...props }: InputComponentProps) => {
    return <div ref={inputRef} {...props} />
}

const Control = (props: ControlProps<Suggestion>) => {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { classes, TextFieldProps }
    } = props
    const value: any = props.getValue()
    const textfieldValue: string = props.hasValue && value.length > 0 ? value[0].label : props.selectProps.inputValue

    return (
        <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            value={textfieldValue}
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps
                }
            }}
            {...TextFieldProps}
        />
    )
}

const Option = (props: OptionProps<Suggestion | SuggestionWithIcon>) => {
    console.log(props.data);
    return (
        <MenuItem ref={props.innerRef} selected={props.isFocused} component="div" {...props.innerProps}>
            {props.data.icon && (
                <img src={props.data.icon} alt="menu-icon" className={props.selectProps.classes.imageIcon} />
            )}
            {props.data.numberOfQuestions ? (
            <div>
                {props.data.label}
                <Typography className={props.selectProps.classes.subText}>
                    {Locales.numberOfQuestions}: {props.data.numberOfQuestions}
                </Typography>
            </div>
            ) : (
            props.children
            )}
        </MenuItem>
    )
}

const NoOptionsMessage = (props: NoticeProps<Suggestion>) => {
    return (
        <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
            {props.children}
        </Typography>
    )
}

const SingleValue = (props: SingleValueProps<Suggestion>) => {
    return (
        <Typography noWrap className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    )
}

const ValueContainer = (props: ValueContainerProps<Suggestion>) => {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

const Menu = (props: MenuProps<Suggestion>) => {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    )
}

const Components = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    SingleValue,
    ValueContainer
}

export default Components
