import React, { useEffect } from "react"
import Select from "react-select"
import NoSsr from "@material-ui/core/NoSsr"
import { ValueType } from "react-select/src/types"
import AddIcon from "../../../images/icon_plus.svg"
import Components from "./AutocompleteComponents"
import Styles from "./Autocomplete.style"

export interface Suggestion {
    label: string
    data?: any
    value: string | number
    numberOfQuestions?: number
}

export interface SuggestionWithIcon extends Suggestion {
    icon: string
}

const ADD_NEW_VALUE = "ADD_NEW"

interface AutocompleteProps {
    data: Suggestion[] | SuggestionWithIcon[]
    label: string
    value?: Suggestion | SuggestionWithIcon
    onChange: (selectedItem: Suggestion | SuggestionWithIcon) => void
    onAddNew?: () => void
    addNewText?: string
    helperText?: string
}

const Autocomplete: React.FC<AutocompleteProps> = props => {
    const styles = Styles.styles(props.helperText)({})

    const data = props.data

    useEffect(() => {
        if (props.onAddNew !== undefined && props.addNewText !== undefined && props.data.length > 0) {
            const addNewButton = { label: props.addNewText, value: ADD_NEW_VALUE, icon: AddIcon }
            data.push(addNewButton)
        }
        // eslint-disable-next-line
    }, [data])

    const handleChangeSingle = (value: ValueType<Suggestion | SuggestionWithIcon>) => {
        if (value != null) {
            const selectedValue = value as Suggestion | SuggestionWithIcon
            if (selectedValue.value === ADD_NEW_VALUE && props.onAddNew !== undefined) {
                props.onAddNew()
            } else {
                props.onChange(selectedValue)
            }
        }
    }

    return (
        <div className={styles.root}>
            <NoSsr>
                <Select
                    classes={styles}
                    inputId="react-select-single"
                    TextFieldProps={{
                        label: props.label,
                        helperText: props.helperText
                    }}
                    options={data}
                    placeholder={""}
                    components={Components}
                    value={props.value !== undefined ? props.value : null}
                    onChange={handleChangeSingle}
                />
            </NoSsr>
        </div>
    )
}

export default Autocomplete
