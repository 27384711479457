import axios from "axios"
import { Dispatch } from "redux"
import Endpoints from "../environments/endpoints"
import { AuthHeader } from "../utils/AuthHeader"
import { Token } from "../utils/StorageKeys"
import { getFeedbacksAction } from "../actions/FeedbacksActions"

const getFeedbacks = (dispatch: Dispatch<any>) => () => {
    axios
        .get(`${Endpoints.apiEndpoint}/feedback`, AuthHeader(localStorage.getItem(Token)))
        .then(response => dispatch(getFeedbacksAction(response.data)))
        .catch(error => {})
}

const resolveFeedback = (dispatch: Dispatch<any>) => (
    feedbackId: number,
    onSuccess: () => void
) => {
    axios
        .put(
            `${Endpoints.apiEndpoint}/feedback/${feedbackId}/approve`,
            undefined,
            AuthHeader(localStorage.getItem(Token))
        )
        .then(response => {
            getFeedbacks(dispatch)()
            onSuccess()
        })
        .catch(error => {})
}

export default { getFeedbacks, resolveFeedback }
