import { ExplanationTypeRequest } from "../types/FlagTypes"
import { DecisionModalProps } from "../types/ModalTypes"

export const handleOpenModalClick = (
    // ReportedCommentTypeResponse | ReportedQuestionTypeResponse | QuestionProposal
    subject: any,
    setState: React.Dispatch<React.SetStateAction<DecisionModalProps>>,
    accept: boolean,
    callback: (flagId: number, payload: ExplanationTypeRequest) => void
) => {
    if (subject.reports) {
        setState((prev: any) => {
            return {
                ...prev,
                open: true,
                accept: accept,
                reporter: subject.reports,
                reported: accept ? subject.owner : undefined,
                flagId: subject.flagId,
                callback: callback
            }
        })
    } else {
        setState((prev: any) => {
            return {
                ...prev,
                open: true,
                accept: accept,
                reported: subject.user,
                flagId: subject.id,
                callback: callback
            }
        })
    }
}
