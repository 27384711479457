import React from "react"
import { AnswersHolderProps, AnswerProps } from "../../../types/AnswerTypes"
import AnswerText from "../AnswerText/AnswerText"
import Styles from "./AnswersColumn.style"

const AnswersColumn: React.FC<AnswersHolderProps> = props => {
    const styles = Styles.styles({})

    const renderAnswer = (ans: AnswerProps, index: number) => {
        return (
            <div key={index} className={styles.answerContainer}>
                <AnswerText
                    answerLetter={ans.answerLetter}
                    answer={ans.answer}
                    correct={ans.answerLetter === props.correctAnswer ? "correct" : "neutral"}
                />
            </div>
        )
    }

    return <div className={styles.container}>{props.answers.map((answer, index) => renderAnswer(answer, index))}</div>
}

export default AnswersColumn
