import axios from "axios"
import Endpoints from "../environments/endpoints"
import { AuthHeader } from "../utils/AuthHeader"
import { Token } from "../utils/StorageKeys"
import { GlobalNotificationType } from "../types/NotificationTypes"

const sendGlobalNotification = (message: GlobalNotificationType, onSuccess?: () => void, onError?: () => void) => {
    axios
        .post(`${Endpoints.apiEndpoint}/notification/allUsers`, message, AuthHeader(localStorage.getItem(Token)))
        .then(response => {
            if (onSuccess) {
                onSuccess()
            }
        })
        .catch(error => {
            if (onError) {
                onError()
            }
        })
}

export default {
    sendGlobalNotification
}
