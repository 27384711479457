import axios from "axios"
import { Dispatch } from "redux"
import Endpoints from "../environments/endpoints"
import { getAllTopicsAction } from "../actions/TopicsActions"
import { AuthHeader } from "../utils/AuthHeader"
import { Token } from "../utils/StorageKeys"

const getAllTopics = (dispatch: Dispatch<any>) => () => {
    axios
        .get(`${Endpoints.apiEndpoint}/topics`, AuthHeader(localStorage.getItem(Token)))
        .then(response => dispatch(getAllTopicsAction(response.data)))
        .catch(error => {})
}

export default {
    getAllTopics
}
