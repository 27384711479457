import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import FontWeights from "../../../utils/FontWeights"
import Colors from "../../../utils/Colors"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        lowerLegendContainer: {
            display: "flex",
            flexDirection: "row",
            margin: "-8px 8px 8px -8px"
        }
    })
)

const textTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 20,
            color: theme.palette.primary.main,
            fontWeight: FontWeights.BOLD,
            marginBottom: 8
        }
    })
)

const gradeTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 36,
            color: theme.palette.secondary.main,
            fontWeight: FontWeights.BOLD,
            lineHeight: 1
        }
    })
)

const explanationTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 12,
            color: theme.palette.secondary.main,
            fontWeight: FontWeights.BOLD
        }
    })
)

const highSchoolTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.questionDifficultyPickerHighSchoolFont,
            fontSize: 12,
            fontWeight: FontWeights.BOLD,
            margin: "20px 0px -20px 0px"
        }
    })
)

const elementarySchoolTypo = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.questionDifficultyPickerElementarySchoolFont,
            fontSize: 12,
            fontWeight: FontWeights.BOLD,
            paddingRight: 50
        }
    })
)

const universityTypo = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.questionDifficultyPickerUniversityFont,
            fontSize: 12,
            fontWeight: FontWeights.BOLD,
            paddingLeft: 50
        }
    })
)

const sliderStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 250,
            color: theme.palette.secondary.main,
            marginLeft: 8
        }
    })
)

export default {
    styles,
    textTypoStyle,
    gradeTypoStyle,
    explanationTypoStyle,
    highSchoolTypoStyle,
    elementarySchoolTypo,
    universityTypo,
    sliderStyle
}
