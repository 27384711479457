import React, { useEffect, useState } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { RouteComponentProps } from "react-router"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import Button from "@material-ui/core/Button"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import Typography from "@material-ui/core/Typography"
import { executeCachedRequest } from "../../../utils/CacheValidator"
import CommentsDataAccess from "../../../data_access/CommentsDataAccess"
import Styles from "./ReportedComments.style"
import Locales from "../../../localization/Localization"
import { CommentsReducerStateTypes } from "../../../reducers/CommentsReducer"
import Link from "@material-ui/core/Link"
import Endpoints from "../../../environments/endpoints"
import Loading from "../../common_components/Loading/Loading"
import DecisionModal from "../../common_components/DecisionModal/DecisionModal"
import { handleOpenModalClick } from "../../../utils/ModalMethods"
import { DecisionModalProps } from "../../../types/ModalTypes"
import { ExplanationTypeRequest, ReportedCommentTypeResponse } from "../../../types/FlagTypes"

const ReportedComments: React.FC<RouteComponentProps> = props => {
    const dispatch = useDispatch()
    const comments: CommentsReducerStateTypes = useSelector((state: any) => state.CommentsReducer, shallowEqual)

    const styles = Styles.styles({})
    const questionTypoStyle = Styles.questionTypoStyle({})
    const difficultyAuthorTitleTypoStyle = Styles.difficultyAuthorTitleTypoStyle({})
    const authorTypoStyle = Styles.authorTypoStyle({})
    const reportTitleTypoStyle = Styles.reportTitleTypoStyle({})
    const dismissButtonStyle = Styles.dismissButtonStyle({})
    const acceptButtonStyle = Styles.acceptButtonStyle({})

    const [loading, setLoading] = useState<boolean>(false)
    const hideLoader = () => setLoading(false)
    const showLoader = () => setLoading(true)

    const hideModal = () => {
        setModalState({ ...modalState, open: false })
        hideLoader()
    }

    const [modalState, setModalState] = useState<DecisionModalProps>({
        open: false,
        callback: () => {},
        accept: undefined,
        type: "comment",
        reporter: [],
        reported: undefined,
        handleClose: hideModal,
        flagId: undefined
    })

    useEffect(() => {
        executeCachedRequest(CommentsDataAccess.getCommentReports(dispatch), comments.reportedCommentsCache)
        //eslint-disable-next-line
    }, [])

    const resolveReportReason = (reason: string) => {
        if (reason === "INCORRECT") {
            return Locales.incorrect
        }
        if (reason === "INAPPROPRIATE") {
            return Locales.inappropriate
        }
        if (reason === "SPAM") {
            return Locales.spam
        }
        if (reason === "HATE_SPEECH") {
            return Locales.hateSpeech
        }
        if (reason === "HARASSMENT") {
            return Locales.harassment
        }
    }

    const onDismiss = (flagId: number, payload: ExplanationTypeRequest) => {
        showLoader()
        CommentsDataAccess.dismissCommentReport(dispatch)(flagId, payload, hideModal)
    }

    const onAccept = (flagId: number, payload: ExplanationTypeRequest) => {
        showLoader()
        CommentsDataAccess.approveCommentReport(dispatch)(flagId, payload, hideModal)
    }

    const handleClickAccept = (reportedComment: ReportedCommentTypeResponse) => () => {
        handleOpenModalClick(reportedComment, setModalState, true, onAccept)
    }

    const handleClickDismiss = (reportedComment: ReportedCommentTypeResponse) => () => {
        handleOpenModalClick(reportedComment, setModalState, false, onDismiss)
    }

    return (
        <div>
            {comments.reportedComments.length > 0 ? (
                comments.reportedComments.map((reportedComment, index) => {
                    return (
                        <ExpansionPanel key={index}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                                <Typography classes={questionTypoStyle}>{reportedComment.commentText}</Typography>
                                <div className={styles.difficultyAuthorContainer}>
                                    <Typography
                                        classes={difficultyAuthorTitleTypoStyle}
                                    >{`${Locales.author}:`}</Typography>
                                    <Typography classes={authorTypoStyle}>
                                        {`${reportedComment.owner.firstName} ${reportedComment.owner.lastName}`}
                                    </Typography>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className={styles.detailsContainer}>
                                    {reportedComment.reports.map((report, index) => (
                                        <div key={index} className={styles.reportContainer}>
                                            <div className={styles.reportAuthorContainer}>
                                                <Typography
                                                    classes={difficultyAuthorTitleTypoStyle}
                                                >{`${Locales.commentReportedBy}:`}</Typography>
                                                <Typography classes={authorTypoStyle}>
                                                    {`${report.flaggedBy.firstName} ${report.flaggedBy.lastName}`}
                                                </Typography>
                                            </div>
                                            <div className={styles.reportAuthorContainer}>
                                                <Typography
                                                    classes={difficultyAuthorTitleTypoStyle}
                                                >{`${Locales.question}:`}</Typography>
                                                <Link
                                                    href={`${Endpoints.userAppEndpoint}/question/answer/${reportedComment.questionId}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    classes={authorTypoStyle}
                                                >
                                                    {"link"}
                                                </Link>
                                            </div>
                                            <div>
                                                <Typography
                                                    classes={reportTitleTypoStyle}
                                                >{`${Locales.reason}:`}</Typography>
                                                <Typography>{resolveReportReason(report.reason)}</Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    classes={reportTitleTypoStyle}
                                                >{`${Locales.description}:`}</Typography>
                                                <Typography>{report.description}</Typography>
                                            </div>
                                        </div>
                                    ))}
                                    <div className={styles.actionsContainer}>
                                        <div>
                                            <Button
                                                classes={dismissButtonStyle}
                                                onClick={handleClickDismiss(reportedComment)}
                                            >
                                                {Locales.dismiss}
                                            </Button>
                                            <Button
                                                classes={acceptButtonStyle}
                                                onClick={handleClickAccept(reportedComment)}
                                            >
                                                {Locales.accept}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )
                })
            ) : (
                <Typography classes={questionTypoStyle}>{Locales.thereIsNoReportedComments}</Typography>
            )}
            {loading && <Loading fullScreen />}
            <DecisionModal {...modalState} />
        </div>
    )
}

export default ReportedComments
