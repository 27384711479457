import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import Colors from "../../../utils/Colors"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        paginationStyle: {
            display: "flex",
            justifyContent: "space-between"
        }
    })
)

const tableBodyStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "unset"
        }
    })
)

const bigCellStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "60%",
            wordBreak: "break-all"
        }
    })
)

const locationTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: Colors.schoolLocationFont
        }
    })
)

export default {
    styles,
    bigCellStyle,
    locationTypoStyle,
    tableBodyStyle
}
