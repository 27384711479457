import axios from "axios"
import { Dispatch } from "redux"
import Endpoints from "../environments/endpoints"
import { AuthHeader } from "../utils/AuthHeader"
import { Token } from "../utils/StorageKeys"
import { getAllLocationsAction } from "../actions/LocationsActions"

const getAllLocations = (dispatch: Dispatch<any>) => () => {
    axios
        .get(`${Endpoints.apiEndpoint}/locations`, AuthHeader(localStorage.getItem(Token)))
        .then(response => dispatch(getAllLocationsAction(response.data)))
        .catch(error => {})
}

export default {
    getAllLocations
}
