import React, { useState, useEffect } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers"
import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import TopicsDataAccess from "../../../data_access/TopicsDataAccess"
import SubtopicsDataAccess from "../../../data_access/SubtopicsDataAccess"
import EducationDataAccess from "../../../data_access/EducationDataAccess"
import StatisticsDataAccess from "../../../data_access/StatisticsDataAccess"
import LocationsDataAccess from "../../../data_access/LocationsDataAccess"
import { executeCachedRequest } from "../../../utils/CacheValidator"
import { EducationalFacilitiesStateTypes } from "../../../reducers/EducationReducer"
import Autocomplete from "../../common_components/Autocomplete/Autocomplete"
import { Suggestion, SuggestionWithIcon } from "../../common_components/Autocomplete/Autocomplete"
import OrangeButton from "../../common_components/OrangeButton/OrangeButton"
import Styles from "./StatisticsScreen.style"
import {
    StatisticsRequest,
    StatisticsWithEducationalFacilitiesRequest,
    ComparisonResponse,
    LocationScore
} from "../../../types/StatisticsType"
import Loading from "../../common_components/Loading/Loading"
import { TopicReducerStateTypes } from "../../../reducers/TopicsReducer"
import { SubtopicReducerStateTypes } from "../../../reducers/SubtopicsReducer"
import { LocationsReducerStateTypes } from "../../../reducers/LocationsReducer"
import { MinimalTopicTypeResponse } from "../../../types/TopicTypes"
import { MinimalSubtopicTypeResponse } from "../../../types/SubtopicTypes"
import Typography from "@material-ui/core/Typography"
import Locales from "../../../localization/Localization"
import PaginationComponent from "../../common_components/PaginationComponent/PaginationComponent"
import { TableHead } from "@material-ui/core"

const NUMBER_OF_ANSWERS = "NUMBER_OF_ANSWERS"
const NUMBER_OF_QUESTIONS = "NUMBER_OF_QUESTIONS"
const NUMBER_OF_SUBTOPICS = "NUMBER_OF_SUBTOPICS"
const NUMBER_OF_TOPICS = "NUMBER_OF_TOPICS"
const NUMBER_OF_FILTERED_USERS = "NUMBER_OF_FILTERED_USERS"
const FILTERED_USERS_STATS_IN_TOPIC = "FILTERED_USERS_STATS_IN_TOPIC"
const FILTERED_USERS_STATS_IN_SUBTOPIC = "FILTERED_USERS_STATS_IN_SUBTOPIC"
const COMPARED_USERS_STATS_IN_TOPIC = "COMPARED_USERS_STATS_IN_TOPIC"
const COMPARED_USERS_STATS_IN_SUBTOPIC = "COMPARED_USERS_STATS_IN_SUBTOPIC"
const COMPARED_USERS_STATS_FOR_LOCATION_IN_TOPIC = "COMPARED_USERS_STATS_FOR_LOCATION_IN_TOPIC"
const COMPARED_USERS_STATS_FOR_LOCATION_IN_SUBTOPIC = "COMPARED_USERS_STATS_FOR_LOCATION_IN_SUBTOPIC"

interface StatisticsState {
    statisticsType: string
    startDate: Date
    endDate: Date
    topic: Suggestion | SuggestionWithIcon | undefined
    subtopic: Suggestion | SuggestionWithIcon | undefined
    location: Suggestion | SuggestionWithIcon | undefined
    educationalFacility: Suggestion | SuggestionWithIcon | undefined
}

interface ComparisonArrays {
    primary: ComparisonResponse[]
    secondary: ComparisonResponse[]
    tertiary: ComparisonResponse[]
}

interface StatisticsPaginationState {
    elementarySchoolPage: number
    highSchoolPage: number
    universityPage: number
    rowsPerPage: number
}

const ELEMENTARY_SCHOOLS_INDEX = 0
const HIGH_SCHOOLS_INDEX = 1
const UNIVERSITY_INDEX = 2

const StatisticsScreen: React.FC = () => {
    const dispatch = useDispatch()
    const education: EducationalFacilitiesStateTypes = useSelector((state: any) => state.EducationReducer, shallowEqual)
    const topics: TopicReducerStateTypes = useSelector((state: any) => state.TopicsReducer, shallowEqual)
    const subtopics: SubtopicReducerStateTypes = useSelector((state: any) => state.SubtopicsReducer, shallowEqual)
    const locations: LocationsReducerStateTypes = useSelector((state: any) => state.LocationsReducer, shallowEqual)

    const styles = Styles.styles({})
    const paperStyle = Styles.paperStyle({})
    const resultsPaperStyle = Styles.resultsPaperStyle({})
    const resultsTypoStyle = Styles.resultsTypoStyle({})

    const [state, setState] = useState<StatisticsState>({
        statisticsType: NUMBER_OF_ANSWERS,
        startDate: new Date(),
        endDate: new Date(),
        topic: undefined,
        subtopic: undefined,
        location: undefined,
        educationalFacility: undefined
    })
    const [value, setValue] = useState(ELEMENTARY_SCHOOLS_INDEX)
    const [pageState, setPageState] = useState<StatisticsPaginationState>({
        elementarySchoolPage: 0,
        highSchoolPage: 0,
        universityPage: 0,
        rowsPerPage: 8
    })
    const [results, setResults] = useState<number>()
    const [comparedResults, setComparedResults] = useState<ComparisonArrays>()
    const [locationResults, setLocationResults] = useState<LocationScore[]>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        executeCachedRequest(EducationDataAccess.getAllEducationalFacilities(dispatch), education.educationCache)
        executeCachedRequest(TopicsDataAccess.getAllTopics(dispatch), topics.topicsCache)
        executeCachedRequest(SubtopicsDataAccess.getAllSubtopics(dispatch), subtopics.subtopicsCache)
        executeCachedRequest(LocationsDataAccess.getAllLocations(dispatch), locations.locationsCache)
        //eslint-disable-next-line
    }, [])

    const showLoader = () => setLoading(true)
    const hideLoader = () => setLoading(false)

    const handleStatisticsType = (e: any) => {
        setResults(undefined)
        setState({ ...state, statisticsType: e.target.value })
    }

    const handleDateChange = (name: keyof StatisticsState) => (date: Date | null) => {
        if (date != null) {
            setState({ ...state, [name]: date })
        }
    }

    const handleLocationChange = (selectedItem: Suggestion | SuggestionWithIcon) => {
        setState({ ...state, location: selectedItem })
    }

    const handleTopicSubtopicChange = (name: keyof StatisticsState) => (
        selectedItem: Suggestion | SuggestionWithIcon
    ) => {
        setState({ ...state, [name]: selectedItem })
    }

    const handleEducationChange = (selectedItem: Suggestion | SuggestionWithIcon) => {
        setState({ ...state, educationalFacility: selectedItem })
    }

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue)
    }

    const handleChangePage = (name: keyof StatisticsPaginationState) => (event: unknown, newPage: number) => {
        setPageState({ ...pageState, [name]: newPage })
    }

    const handleChangeRowsPerPage = (name: keyof StatisticsPaginationState) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPageState({ ...pageState, rowsPerPage: +event.target.value, [name]: 0 })
    }

    const onResultsLoaded = (results: number) => {
        hideLoader()
        setResults(results)
    }

    const onComparedResultsLoaded = (results: ComparisonResponse[]) => {
        hideLoader()
        const sortDesc = (a: ComparisonResponse, b: ComparisonResponse) => b.averageScore - a.averageScore
        setComparedResults({
            primary: results.filter(res => res.type === "Primary").sort(sortDesc),
            secondary: results.filter(res => res.type === "Secondary").sort(sortDesc),
            tertiary: results.filter(res => res.type === "Tertiary").sort(sortDesc)
        })
    }

    const onComparedByLocationResultsLoaded = (results: LocationScore[]) => {
        hideLoader()
        setLocationResults(results)
    }

    const getStatistics = () => {
        showLoader()
        switch (state.statisticsType) {
            case NUMBER_OF_ANSWERS: {
                StatisticsDataAccess.getNumberOfAddedAnswers(
                    state.startDate.getTime(),
                    state.endDate.getTime(),
                    onResultsLoaded
                )
                break
            }
            case NUMBER_OF_QUESTIONS: {
                StatisticsDataAccess.getNumberOfAddedQuestions(
                    state.startDate.getTime(),
                    state.endDate.getTime(),
                    onResultsLoaded
                )
                break
            }
            case NUMBER_OF_SUBTOPICS: {
                StatisticsDataAccess.getNumberOfAddedSubtopics(
                    state.startDate.getTime(),
                    state.endDate.getTime(),
                    onResultsLoaded
                )
                break
            }
            case NUMBER_OF_TOPICS: {
                StatisticsDataAccess.getNumberOfAddedTopics(
                    state.startDate.getTime(),
                    state.endDate.getTime(),
                    onResultsLoaded
                )
                break
            }
            case NUMBER_OF_FILTERED_USERS: {
                const payload: StatisticsWithEducationalFacilitiesRequest = {
                    userDOBStartMillis: state.startDate.getTime(),
                    userDOBEndMillis: state.endDate.getTime(),
                    location: state.location!.label,
                    educationalFacilityIds: [state!.educationalFacility!.value as number]
                }
                StatisticsDataAccess.getNumberOfFilteredUsers(payload, onResultsLoaded)
                break
            }
            case FILTERED_USERS_STATS_IN_TOPIC: {
                const payload: StatisticsWithEducationalFacilitiesRequest = {
                    userDOBStartMillis: state.startDate.getTime(),
                    userDOBEndMillis: state.endDate.getTime(),
                    location: state.location!.label,
                    educationalFacilityIds: [state!.educationalFacility!.value as number]
                }
                StatisticsDataAccess.getUserStatsForTopic(state!.topic!.value as number, payload, onResultsLoaded)
                break
            }
            case FILTERED_USERS_STATS_IN_SUBTOPIC: {
                const payload: StatisticsWithEducationalFacilitiesRequest = {
                    userDOBStartMillis: state.startDate.getTime(),
                    userDOBEndMillis: state.endDate.getTime(),
                    location: state.location!.label,
                    educationalFacilityIds: [state!.educationalFacility!.value as number]
                }
                StatisticsDataAccess.getUserStatsForSubtopic(state!.subtopic!.value as number, payload, onResultsLoaded)
                break
            }

            case COMPARED_USERS_STATS_IN_TOPIC: {
                const payload: StatisticsRequest = {
                    userDOBStartMillis: state.startDate.getTime(),
                    userDOBEndMillis: state.endDate.getTime(),
                    location: state.location!.label
                }
                StatisticsDataAccess.getEducationalFacilityComparisonForTopic(
                    state!.topic!.value as number,
                    payload,
                    onComparedResultsLoaded
                )
                break
            }
            case COMPARED_USERS_STATS_IN_SUBTOPIC: {
                const payload: StatisticsRequest = {
                    userDOBStartMillis: state.startDate.getTime(),
                    userDOBEndMillis: state.endDate.getTime(),
                    location: state.location!.label
                }
                StatisticsDataAccess.getEducationalFacilityComparisonForSubtopic(
                    state!.subtopic!.value as number,
                    payload,
                    onComparedResultsLoaded
                )
                break
            }
            case COMPARED_USERS_STATS_FOR_LOCATION_IN_TOPIC: {
                StatisticsDataAccess.getComparisonForLocationForTopic(
                    state!.topic!.value as number,
                    onComparedByLocationResultsLoaded
                )
                break
            }
            case COMPARED_USERS_STATS_FOR_LOCATION_IN_SUBTOPIC: {
                StatisticsDataAccess.getComparisonForLocationForSubtopic(
                    state!.subtopic!.value as number,
                    onComparedByLocationResultsLoaded
                )
                break
            }
        }
    }

    const resolveTopicSubtopicAutocomplete = () => {
        let suggestions, label, value, onChange
        const suggestionsMapper = (item: MinimalTopicTypeResponse | MinimalSubtopicTypeResponse) => {
            return { label: item.name, value: item.id }
        }

        if (
            state.statisticsType === FILTERED_USERS_STATS_IN_TOPIC ||
            state.statisticsType === COMPARED_USERS_STATS_IN_TOPIC ||
            state.statisticsType === COMPARED_USERS_STATS_FOR_LOCATION_IN_TOPIC
        ) {
            suggestions = topics.topics.map(suggestionsMapper)
            label = Locales.topic
            value = state.topic
            onChange = handleTopicSubtopicChange("topic")
        } else {
            suggestions = subtopics.subtopics.map(suggestionsMapper)
            label = Locales.subtopic
            value = state.subtopic
            onChange = handleTopicSubtopicChange("subtopic")
        }
        return <Autocomplete data={suggestions} label={label} value={value} onChange={onChange} />
    }

    const renderTabPanel = (index: number, type: "elementarySchoolPage" | "highSchoolPage" | "universityPage") => {
        let data: ComparisonResponse[]
        let dataLength: number
        let page: number

        if (comparedResults == null) {
            return <></>
        }

        if (type === "elementarySchoolPage") {
            data = comparedResults.primary.slice(
                pageState.elementarySchoolPage * pageState.rowsPerPage,
                pageState.elementarySchoolPage * pageState.rowsPerPage + pageState.rowsPerPage
            )
            dataLength = comparedResults.primary.length
            page = pageState.elementarySchoolPage
        } else if (type === "highSchoolPage") {
            data = comparedResults.secondary.slice(
                pageState.highSchoolPage * pageState.rowsPerPage,
                pageState.highSchoolPage * pageState.rowsPerPage + pageState.rowsPerPage
            )
            dataLength = comparedResults.secondary.length
            page = pageState.highSchoolPage
        } else {
            data = comparedResults.tertiary.slice(
                pageState.universityPage * pageState.rowsPerPage,
                pageState.universityPage * pageState.rowsPerPage + pageState.rowsPerPage
            )
            dataLength = comparedResults.tertiary.length
            page = pageState.universityPage
        }

        return (
            <TabPanel value={value} index={index}>
                <Table>
                    <TableHead>
                        <TableCell>Škola</TableCell>
                        <TableCell>Mjesto</TableCell>
                        <TableCell>Broj korisnika</TableCell>
                        <TableCell align="right">Prosječan rezultat (Broj odgovora)</TableCell>
                    </TableHead>
                    <TableBody>
                        {data.map((row, rowIndex) => {
                            return (
                                <TableRow key={rowIndex}>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.location}</TableCell>
                                    <TableCell>{row.numberOfUsers}</TableCell>
                                    <TableCell align="right">{`${row.averageScore.toFixed(2)} (${
                                        row.numberOfAnsweredQuestions
                                    })`}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                <PaginationComponent
                    rows={dataLength}
                    page={page}
                    rowsPerPage={pageState.rowsPerPage}
                    onChangePage={handleChangePage(type)}
                    onChangeRowsPerPage={handleChangeRowsPerPage(type)}
                />
            </TabPanel>
        )
    }

    const renderLocationTable = () => {
        if (locationResults == null) {
            return <></>
        }

        return (
            <Table>
                <TableHead>
                    <TableCell>Lokacija</TableCell>
                    <TableCell>Broj korisnika</TableCell>
                    <TableCell align="right">Prosječan rezultat</TableCell>
                </TableHead>
                <TableBody>
                    {locationResults.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                            <TableCell>{row.location}</TableCell>
                            <TableCell>{row.numberOfUsers}</TableCell>
                            <TableCell align="right">{row.score.toFixed(2)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    const locationSuggestions = locations.locations.map(location => {
        return { label: location.name, value: location.name }
    })

    const educationSuggestions = [
        ...education.primaryEducation,
        ...education.secondaryEducation,
        ...education.tertiaryEducation
    ].map(edu => {
        return { label: edu.name, value: edu.id }
    })

    const comparedTopicsSubtopicsStatisticsType =
        state.statisticsType === COMPARED_USERS_STATS_IN_TOPIC ||
        state.statisticsType === COMPARED_USERS_STATS_IN_SUBTOPIC

    const comparedTopicsSubtopicsFromLocationStatisticsType =
        state.statisticsType === COMPARED_USERS_STATS_FOR_LOCATION_IN_TOPIC ||
        state.statisticsType === COMPARED_USERS_STATS_FOR_LOCATION_IN_SUBTOPIC

    const topicSubtopicStatisticsType =
        state.statisticsType === FILTERED_USERS_STATS_IN_TOPIC ||
        state.statisticsType === FILTERED_USERS_STATS_IN_SUBTOPIC

    const advancedStatisticsType =
        state.statisticsType === NUMBER_OF_FILTERED_USERS ||
        topicSubtopicStatisticsType ||
        comparedTopicsSubtopicsStatisticsType ||
        comparedTopicsSubtopicsFromLocationStatisticsType

    const requiresEducationalFacility = state.statisticsType === NUMBER_OF_FILTERED_USERS || topicSubtopicStatisticsType

    const renderResults = () => {
        if (comparedTopicsSubtopicsStatisticsType && comparedResults != null) {
            return (
                <Paper classes={resultsPaperStyle}>
                    <Tabs
                        value={value}
                        onChange={handleTabChange}
                        variant="fullWidth"
                        indicatorColor="secondary"
                        textColor="secondary"
                    >
                        <Tab label={Locales.elementarySchools} />
                        <Tab label={Locales.highSchools} />
                        <Tab label={Locales.universities} />
                    </Tabs>
                    {renderTabPanel(ELEMENTARY_SCHOOLS_INDEX, "elementarySchoolPage")}
                    {renderTabPanel(HIGH_SCHOOLS_INDEX, "highSchoolPage")}
                    {renderTabPanel(UNIVERSITY_INDEX, "universityPage")}
                </Paper>
            )
        }
        if (comparedTopicsSubtopicsFromLocationStatisticsType && locationResults != null) {
            return <Paper classes={resultsPaperStyle}>{renderLocationTable()}</Paper>
        }
        if (results != null) {
            return (
                <Paper classes={resultsPaperStyle}>
                    <Typography classes={resultsTypoStyle}>{isNaN(results) ? "N/A" : results}</Typography>
                </Paper>
            )
        }
        return <></>
    }

    return (
        <div>
            <Paper classes={paperStyle}>
                <Select
                    value={state.statisticsType}
                    onChange={handleStatisticsType}
                    input={<OutlinedInput labelWidth={0} />}
                >
                    <MenuItem value={NUMBER_OF_ANSWERS}>{Locales.numberOfAnswers}</MenuItem>
                    <MenuItem value={NUMBER_OF_QUESTIONS}>{Locales.numberOfAddedQuestions}</MenuItem>
                    <MenuItem value={NUMBER_OF_SUBTOPICS}>{Locales.numberOfAddedTopics}</MenuItem>
                    <MenuItem value={NUMBER_OF_TOPICS}>{Locales.numberOfAddedSubtopics}</MenuItem>
                    <MenuItem value={NUMBER_OF_FILTERED_USERS}>{Locales.numberOfUsers}</MenuItem>
                    <MenuItem value={FILTERED_USERS_STATS_IN_TOPIC}>{Locales.usersAverageScoreForTopic}</MenuItem>
                    <MenuItem value={FILTERED_USERS_STATS_IN_SUBTOPIC}>{Locales.usersAverageScoreForSubtopic}</MenuItem>
                    <MenuItem value={COMPARED_USERS_STATS_IN_TOPIC}>
                        {Locales.compareUsersAverageScoreForTopic}
                    </MenuItem>
                    <MenuItem value={COMPARED_USERS_STATS_IN_SUBTOPIC}>
                        {Locales.compareUsersAverageScoreForSubtopic}
                    </MenuItem>
                    <MenuItem value={COMPARED_USERS_STATS_FOR_LOCATION_IN_TOPIC}>
                        {Locales.compareUsersAverageScoreForTopicFromLocation}
                    </MenuItem>
                    <MenuItem value={COMPARED_USERS_STATS_FOR_LOCATION_IN_SUBTOPIC}>
                        {Locales.compareUsersAverageScoreForSubtopicFromLocation}
                    </MenuItem>
                </Select>
                {!comparedTopicsSubtopicsFromLocationStatisticsType && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className={styles.datePicker}>
                            <DateTimePicker
                                label={advancedStatisticsType ? Locales.dateOfBirthFrom : Locales.from}
                                inputVariant="outlined"
                                value={state.startDate}
                                ampm={false}
                                disableFuture
                                minutesStep={15}
                                format="dd.MM.yyyy. HH:mm"
                                onChange={handleDateChange("startDate")}
                            />
                        </div>
                        <div className={styles.datePicker}>
                            <DateTimePicker
                                label={advancedStatisticsType ? Locales.dateOfBirthTo : Locales.to}
                                inputVariant="outlined"
                                minDate={state.startDate}
                                value={state.endDate}
                                ampm={false}
                                disableFuture
                                minutesStep={15}
                                format="dd.MM.yyyy. HH:mm"
                                onChange={handleDateChange("endDate")}
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                )}
                {advancedStatisticsType && (
                    <>
                        {(topicSubtopicStatisticsType ||
                            comparedTopicsSubtopicsStatisticsType ||
                            comparedTopicsSubtopicsFromLocationStatisticsType) &&
                            resolveTopicSubtopicAutocomplete()}
                        {!comparedTopicsSubtopicsFromLocationStatisticsType && (
                            <Autocomplete
                                data={locationSuggestions}
                                label={Locales.location}
                                value={state.location}
                                onChange={handleLocationChange}
                            />
                        )}
                        {!(
                            comparedTopicsSubtopicsStatisticsType || comparedTopicsSubtopicsFromLocationStatisticsType
                        ) && (
                            <Autocomplete
                                data={educationSuggestions}
                                label={Locales.educationalFacility}
                                value={state.educationalFacility}
                                onChange={handleEducationChange}
                            />
                        )}
                    </>
                )}
                <div className={styles.buttonContainer}>
                    <OrangeButton
                        height={36}
                        fontSize={14}
                        bold
                        label={Locales.getStatisticsData}
                        disabled={
                            (requiresEducationalFacility && state.educationalFacility == null) ||
                            ((state.statisticsType === FILTERED_USERS_STATS_IN_TOPIC ||
                                state.statisticsType === COMPARED_USERS_STATS_IN_TOPIC ||
                                state.statisticsType === COMPARED_USERS_STATS_FOR_LOCATION_IN_TOPIC) &&
                                state.topic == null) ||
                            ((state.statisticsType === FILTERED_USERS_STATS_IN_SUBTOPIC ||
                                state.statisticsType === COMPARED_USERS_STATS_IN_SUBTOPIC ||
                                state.statisticsType === COMPARED_USERS_STATS_FOR_LOCATION_IN_SUBTOPIC) &&
                                state.subtopic == null)
                        }
                        onClick={getStatistics}
                    />
                </div>
            </Paper>
            {renderResults()}
            {loading && <Loading fullScreen />}
        </div>
    )
}

export default StatisticsScreen

interface TabPanelProps {
    children?: React.ReactNode
    index: any
    value: any
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    )
}
