import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import Colors from "../../../utils/Colors"
import FontWeights from "../../../utils/FontWeights"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        difficultyAuthorContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingLeft: 4,
            paddingRight: 4,
            [theme.breakpoints.down("xs")]: {
                paddingTop: 4,
                paddingBottom: 4,
                justifyContent: "flex-start"
            }
        },
        dotDelimiter: {
            height: 4,
            width: 4,
            borderRadius: 2,
            margin: 4,
            backgroundColor: Colors.difficultyAuthor
        },
        detailsContainer: {
            width: "100%"
        },
        mathQuestionContainer: {
            fontSize: 14,
            overflow: "auto",
            width: "70%",
            [theme.breakpoints.down("xs")]: {
                width: "100%"
            }
        },
        answersContainer: {
            width: "70%",
            [theme.breakpoints.down("xs")]: {
                width: "100%"
            }
        },
        actionsContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column"
            }
        },
        topicSubtopicContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            [theme.breakpoints.down("xs")]: {
                marginTop: 12,
                marginBottom: 12
            }
        },
        reportContainer: {
            marginTop: 8,
            marginBottom: 8,
            padding: 16,
            backgroundColor: Colors.reportBackground
        },
        reportAuthorContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        }
    })
)

const panelSummaryStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            userSelect: "auto"
        },
        content: {
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column"
            }
        }
    })
)

const questionTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 16,
            color: Colors.question,
            width: "70%",
            cursor: "text",
            [theme.breakpoints.down("xs")]: {
                width: "90%"
            }
        }
    })
)

const questionImageTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 14,
            color: Colors.question,
            width: "70%",
            fontStyle: "italic",
            [theme.breakpoints.down("xs")]: {
                width: "90%"
            }
        }
    })
)

const difficultyAuthorTitleTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 14,
            color: Colors.difficultyAuthor,
            marginRight: 4
        }
    })
)

const difficultyTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 14,
            color: Colors.difficultyFont,
            fontWeight: FontWeights.BOLD
        }
    })
)

const authorTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 14,
            color: Colors.authorFont,
            fontWeight: FontWeights.BOLD
        }
    })
)

const reportTitleTypoStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 14,
            color: Colors.reportTitleFont,
            fontWeight: FontWeights.BOLD,
            marginTop: 8
        }
    })
)

const dismissButtonStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 16,
            fontWeight: FontWeights.BOLD,
            color: Colors.dismissButton
        }
    })
)

const acceptButtonStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 16,
            fontWeight: FontWeights.BOLD,
            color: Colors.acceptButton
        }
    })
)

export default {
    styles,
    panelSummaryStyle,
    questionTypoStyle,
    questionImageTypoStyle,
    difficultyAuthorTitleTypoStyle,
    difficultyTypoStyle,
    authorTypoStyle,
    reportTitleTypoStyle,
    dismissButtonStyle,
    acceptButtonStyle
}
