import React, { useState, useEffect } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import Paper from "@material-ui/core/Paper"
import TableBody from "@material-ui/core/TableBody"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Locales from "../../../localization/Localization"
import Styles from "./SchoolManagementScreen.style"
import SchoolRowActions from "../../common_components/SchoolRowActions/SchoolRowActions"
import Table from "@material-ui/core/Table"
import PaginationComponent from "../../common_components/PaginationComponent/PaginationComponent"
import PaginationButton from "../../common_components/PaginationButton/PaginationButton"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import DeleteSchoolContent from "../../common_components/DialogContent/DeleteSchoolContent/DeleteSchoolContent"
import SchoolContent from "../../common_components/DialogContent/SchoolContent/SchoolContent"
import EducationDataAccess from "../../../data_access/EducationDataAccess"
import { executeCachedRequest } from "../../../utils/CacheValidator"
import { EducationalFacilitiesStateTypes } from "../../../reducers/EducationReducer"
import { EducationTypeResponse } from "../../../types/EducationTypes"
import Loading from "../../common_components/Loading/Loading"

interface SchoolManagementState {
    openDialog: boolean
    dialogContent?: JSX.Element
}

interface SchoolManagementPaginationState {
    elementarySchoolPage: number
    highSchoolPage: number
    universityPage: number
    rowsPerPage: number
}

const ELEMENTARY_SCHOOLS_INDEX = 0
const HIGH_SCHOOLS_INDEX = 1
const UNIVERSITY_INDEX = 2

const SchoolManagementScreen: React.FC = () => {
    const dispatch = useDispatch()
    const education: EducationalFacilitiesStateTypes = useSelector((state: any) => state.EducationReducer, shallowEqual)

    const bigCellStyle = Styles.bigCellStyle({})
    const locationTypoStyle = Styles.locationTypoStyle({})

    const [value, setValue] = useState(ELEMENTARY_SCHOOLS_INDEX)

    const [state, setState] = useState<SchoolManagementState>({
        openDialog: false,
        dialogContent: undefined
    })

    const [loading, setLoading] = useState<boolean>(false)

    const [pageState, setPageState] = useState<SchoolManagementPaginationState>({
        elementarySchoolPage: 0,
        highSchoolPage: 0,
        universityPage: 0,
        rowsPerPage: 8
    })

    useEffect(() => {
        executeCachedRequest(EducationDataAccess.getAllEducationalFacilities(dispatch), education.educationCache)
        //eslint-disable-next-line
    }, [])

    const handleChangePage = (name: keyof SchoolManagementPaginationState) => (event: unknown, newPage: number) => {
        setPageState({ ...pageState, [name]: newPage })
    }

    const handleChangeRowsPerPage = (name: keyof SchoolManagementPaginationState) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPageState({ ...pageState, rowsPerPage: +event.target.value, [name]: 0 })
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue)
    }

    const showLoader = () => setLoading(true)
    const hideLoader = () => setLoading(false)

    const openCreateDialog = (e: any) => {
        setState({
            openDialog: true,
            dialogContent: <SchoolContent open onClose={closeDialog} showLoader={showLoader} />
        })
    }

    const openDeleteDialog = (row: EducationTypeResponse) => (e: any) => {
        setState({
            openDialog: true,
            dialogContent: <DeleteSchoolContent open infoLabel={row} onClose={closeDialog} showLoader={showLoader} />
        })
    }

    const openEditDialog = (row: EducationTypeResponse) => (e: any) => {
        setState({
            openDialog: true,
            dialogContent: <SchoolContent open data={row} onClose={closeDialog} showLoader={showLoader} />
        })
    }

    const closeDialog = () => {
        hideLoader()
        setState({
            openDialog: false,
            dialogContent: undefined
        })
    }

    const slicedElementaryRows = education.primaryEducation.slice(
        pageState.elementarySchoolPage * pageState.rowsPerPage,
        pageState.elementarySchoolPage * pageState.rowsPerPage + pageState.rowsPerPage
    )

    const slicedHighSchoolRows = education.secondaryEducation.slice(
        pageState.highSchoolPage * pageState.rowsPerPage,
        pageState.highSchoolPage * pageState.rowsPerPage + pageState.rowsPerPage
    )

    const slicedUniversityRows = education.tertiaryEducation.slice(
        pageState.universityPage * pageState.rowsPerPage,
        pageState.universityPage * pageState.rowsPerPage + pageState.rowsPerPage
    )

    const renderTabPanel = (index: number, type: "elementarySchoolPage" | "highSchoolPage" | "universityPage") => {
        let data: EducationTypeResponse[]
        let dataLength: number
        let page: number

        if (type === "elementarySchoolPage") {
            data = slicedElementaryRows
            dataLength = education.primaryEducation.length
            page = pageState.elementarySchoolPage
        } else if (type === "highSchoolPage") {
            data = slicedHighSchoolRows
            dataLength = education.secondaryEducation.length
            page = pageState.highSchoolPage
        } else {
            data = slicedUniversityRows
            dataLength = education.tertiaryEducation.length
            page = pageState.universityPage
        }

        return (
            <TabPanel value={value} index={index}>
                <Table>
                    <TableBody>
                        {data.map((row, rowIndex) => {
                            return (
                                <TableRow key={rowIndex}>
                                    <TableCell classes={bigCellStyle}>{row.name}</TableCell>
                                    <TableCell classes={locationTypoStyle} align="right">
                                        {row.location}
                                    </TableCell>
                                    <TableCell align="right">
                                        <SchoolRowActions
                                            onDelete={openDeleteDialog(row)}
                                            onEdit={openEditDialog(row)}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                <PaginationComponent
                    button={<PaginationButton onOpenCreateDialog={openCreateDialog} />}
                    rows={dataLength}
                    page={page}
                    rowsPerPage={pageState.rowsPerPage}
                    onChangePage={handleChangePage(type)}
                    onChangeRowsPerPage={handleChangeRowsPerPage(type)}
                />
            </TabPanel>
        )
    }

    return (
        <div>
            <Paper>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="secondary"
                    textColor="secondary"
                >
                    <Tab label={Locales.elementarySchools} />
                    <Tab label={Locales.highSchools} />
                    <Tab label={Locales.universities} />
                </Tabs>
                {renderTabPanel(ELEMENTARY_SCHOOLS_INDEX, "elementarySchoolPage")}
                {renderTabPanel(HIGH_SCHOOLS_INDEX, "highSchoolPage")}
                {renderTabPanel(UNIVERSITY_INDEX, "universityPage")}
            </Paper>
            {state.openDialog && state.dialogContent}
            {/* <Dialog open={state.openDialog} content={state.dialogContent} onClose={closeDialog} /> */}
            {loading && <Loading fullScreen />}
        </div>
    )
}

export default SchoolManagementScreen

interface TabPanelProps {
    children?: React.ReactNode
    index: any
    value: any
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    )
}
