import React from "react"
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core"
import Locales from "../../../localization/Localization"
import Styles from "./ConfirmationDialog.style"
import DialogButton from "../DialogButton/DialogButton"
import CloseButton from "../CloseButton/CloseButton"

interface ConfirmationDialogProps {
    open: boolean
    onClose: () => void
    onAccept: () => void
    title: string
    description?: string
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = props => {
    const styles = Styles.styles({})
    const title = Styles.title({})
    const description = Styles.description({})

    const handleConfirm = () => {
        props.onAccept()
        props.onClose()
    }

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <CloseButton onClose={props.onClose} />
            <DialogTitle classes={title}>{props.title}</DialogTitle>
            {props.description && <DialogContent classes={description}>{props.description}</DialogContent>}
            <div className={styles.confirmButtonContainer}>
                <DialogButton alert label={Locales.confirmationDialogButton} onClick={handleConfirm} />
            </div>
        </Dialog>
    )
}
export default ConfirmationDialog
