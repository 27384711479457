import React from "react"
import Button from "@material-ui/core/Button"
import Styles from "./PaginationButton.style"
import Locales from "../../../localization/Localization"

interface PaginationButtonProps {
    onOpenCreateDialog: (e: any) => void
}

const PaginationButton: React.FC<PaginationButtonProps> = props => {
    const buttonStyle = Styles.buttonStyle({})

    return (
        <Button classes={buttonStyle} variant="outlined" onClick={props.onOpenCreateDialog}>
            {Locales.add}
        </Button>
    )
}

export default PaginationButton
