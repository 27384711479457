import React from "react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/MenuRounded"
import ExitIcon from "@material-ui/icons/ExitToAppRounded"
import Styles from "./AppBarComponent.style"
import Endpoints from "../../../environments/endpoints"

interface AppBarComponentProps {
    toggleDrawer: () => void
}

const AppBarComponent: React.FC<AppBarComponentProps> = props => {
    const styles = Styles.styles({})
    const barStyle = Styles.barStyle({})
    const iconStyle = Styles.iconStyle({})
    const appBarStyle = Styles.appBarStyle({})
    const cmsTitleTypoStyle = Styles.cmsTitleTypoStyle({})

    const goToApp = () => {
        window.location.assign(Endpoints.userAppEndpoint)
    }

    return (
        <AppBar position="fixed" classes={appBarStyle}>
            <Toolbar classes={barStyle}>
                <div className={styles.container}>
                    <IconButton classes={iconStyle} onClick={props.toggleDrawer}>
                        <MenuIcon />
                    </IconButton>
                    <div className={styles.logo} />
                    <Typography classes={cmsTitleTypoStyle}>CMS</Typography>
                </div>
                <IconButton classes={iconStyle} onClick={goToApp}>
                    <ExitIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}

export default AppBarComponent
