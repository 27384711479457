import React from "react"
import IconButton from "@material-ui/core/IconButton"
import EditIcon from "@material-ui/icons/EditRounded"
import DeleteIcon from "@material-ui/icons/ClearRounded"
import Styles from "./SchoolRowActions.style"

interface SchoolRowActionsProps {
    onEdit: (e: any) => void
    onDelete: (e: any) => void
}

const SchoolRowActions: React.FC<SchoolRowActionsProps> = props => {
    const iconStyle = Styles.deleteIconStyle({})
    return (
        <div>
            <IconButton onClick={props.onEdit}>
                <EditIcon />
            </IconButton>
            <IconButton classes={iconStyle} onClick={props.onDelete}>
                <DeleteIcon />
            </IconButton>
        </div>
    )
}

export default SchoolRowActions
