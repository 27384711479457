import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import Styles from "./Loading.style"

interface LoadingProps {
    fullScreen?: boolean
    white?: boolean
}

const Loading: React.FC<LoadingProps> = props => {
    const styles = Styles.styles({})
    const dialogStyle = Styles.dialogStyle({})
    const circularProgressStyle = Styles.circularProgressStyle(props.white)({})

    const loadingComponent = (
        <div className={styles.container}>
            <CircularProgress classes={circularProgressStyle} />
        </div>
    )

    const fullScreenLoading = (
        <Dialog open classes={dialogStyle}>
            {loadingComponent}
        </Dialog>
    )

    return props.fullScreen ? fullScreenLoading : loadingComponent
}

export default Loading
