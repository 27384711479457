import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import Colors from "../../../utils/Colors"

const buttonStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderColor: Colors.paginationButtonBorder
        },
        label: {
            textTransform: "none",
            color: Colors.paginationButtonFont
        }
    })
)

export default {
    buttonStyle
}
