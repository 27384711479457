import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Colors from "../../../utils/Colors"

const paper = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "24px 36px"
        }
    })
)

const text = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "0 0 16px 0"
        }
    })
)

const input = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            padding: "16px 0"
        }
    })
)

const snackSuccess = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: Colors.primary,
            color: Colors.white,
            justifyContent: "center"
        }
    })
)

const snackError = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: Colors.alert,
            color: Colors.white,
            justifyContent: "center"
        }
    })
)

export default {
    paper,
    text,
    input,
    snackSuccess,
    snackError
}
