import { ReportedCommentTypeResponse } from "../types/FlagTypes"
import { ActionType } from "../types/ActionType"
import ActionTypes from "../utils/ActionTypes"

export interface ReportedCommentActionType extends ActionType {
    reportedComments: ReportedCommentTypeResponse[]
}

export const getReportedCommentsAction = (
    reportedComments: ReportedCommentTypeResponse[]
): ReportedCommentActionType => {
    return { type: ActionTypes.getReportedComments, reportedComments }
}
