export function debounce<T extends Function>(cb: T, wait: number) {
    let h = 0
    let callable = (...args: any) => {
        clearTimeout(h)
        // @ts-ignore
        h = setTimeout(() => cb(...args), wait)
    }
    // @ts-ignore
    return (callable as any) as T
}
