import React from "react"
import Button from "@material-ui/core/Button"
import Styles from "./OrangeButton.style"

export interface OrangeButtonProps {
    label: string
    onClick: () => void
    height?: number
    fullWidth?: boolean
    bold?: boolean
    fontSize?: number
    disabled?: boolean
    centered?: boolean
}

const OrangeButton: React.FC<OrangeButtonProps> = props => {
    const buttonStyle = Styles.buttonStyles(props)({})

    return (
        <Button
            variant="contained"
            color="secondary"
            classes={buttonStyle}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {props.label}
        </Button>
    )
}

export default OrangeButton
