import React from "react"
import Button, { ButtonProps } from "@material-ui/core/Button"
import Styles from "./DialogButton.style"

export interface DialogButtonProps extends ButtonProps {
    alert?: boolean
    disabled?: boolean
    label: string
    onClick: () => void
}

const DialogButton: React.FC<DialogButtonProps> = props => {
    const buttonStyles = Styles.buttonStyle(props)({})

    return (
        <Button variant="contained" classes={buttonStyles} onClick={props.onClick} {...props}>
            {props.label}
        </Button>
    )
}

export default DialogButton
