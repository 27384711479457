export const PRIMARY_EDUCATION_ID = 0
export const SECONDARY_EDUCATION_ID = 1
export const TERTIARY_EDUCATION_ID = 2

export type EducationStrings = "Primary" | "Secondary" | "Tertiary"

export interface EducationTypeRequest {
    name: string
    location: string
    address: string
    type: number
}

export interface EducationTypeResponse extends EducationTypeRequest {
    id: number
}
