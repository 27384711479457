import { Theme, makeStyles, createStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            maxWidth: 250,
            overflowWrap: "break-word"
        },
        toolbarSpace: {
            padding: theme.spacing(4),
            overflowWrap: "break-word"
        }
    })
)

const listStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            overflowWrap: "break-word"
        }
    })
)

export default {
    styles,
    listStyle
}
