import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme: Theme) =>
    createStyles({
        paginationStyle: {
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center"
        },
        paginationButtonContainer: {
            justifyContent: "flex-start",
            paddingLeft: 20
        }
    })
)

export default {
    styles
}
