import React from "react"
import Typography from "@material-ui/core/Typography"
import Slider from "@material-ui/core/Slider"
//@ts-ignore
import ReactSpeedometer from "react-d3-speedometer"
import Styles from "./QuestionDifficultyPicker.style"
import Locales from "../../../localization/Localization"
import "../../../utils/ExtensionFunctions"
import Colors from "../../../utils/Colors"

interface QuestionDifficultyPickerProps {
    difficulty: number
    onChange: (newValue: number) => void
}

const MIN_DIFFICULTY = 1
const MAX_DIFFICULTY = 10
const NUMBER_OF_SEGMENTS = 12

const segmentValue = (MAX_DIFFICULTY - MIN_DIFFICULTY) / NUMBER_OF_SEGMENTS
const elementaryUpperBoundary = 1 + 3 * segmentValue
const elementaryHighUpperBoundary = elementaryUpperBoundary + segmentValue
const highSchoolUpperBoundary = elementaryHighUpperBoundary + 5 * segmentValue
const highUniversityUpperBoundary = highSchoolUpperBoundary + segmentValue

const QuestionDifficultyPicker: React.FC<QuestionDifficultyPickerProps> = props => {
    const styles = Styles.styles({})
    const textTypoStyle = Styles.textTypoStyle({})
    const gradeTypoStyle = Styles.gradeTypoStyle({})
    const explanationTypoStyle = Styles.explanationTypoStyle({})
    const highSchoolTypoStyle = Styles.highSchoolTypoStyle({})
    const elementarySchoolTypo = Styles.elementarySchoolTypo({})
    const universityTypo = Styles.universityTypo({})
    const sliderStyle = Styles.sliderStyle({})

    const handleSliderChange = (e: any, newValue: number | number[]) => {
        props.onChange((newValue as number) / 100)
    }

    return (
        <div className={styles.container}>
            <Typography classes={textTypoStyle} noWrap>
                {Locales.questionDifficulty + ":"}
            </Typography>
            <Typography classes={gradeTypoStyle} noWrap>
                {props.difficulty.toFixed(2)}
            </Typography>
            <Typography classes={explanationTypoStyle} noWrap>
                {setDifficultyText(props.difficulty)}
            </Typography>
            <Typography classes={highSchoolTypoStyle} noWrap>
                {Locales.highSchool}
            </Typography>
            <ReactSpeedometer
                minValue={MIN_DIFFICULTY}
                maxValue={MAX_DIFFICULTY}
                value={props.difficulty}
                segments={NUMBER_OF_SEGMENTS}
                width={264}
                height={140}
                needleColor={Colors.questionDifficultyPickerNeedle}
                needleHeightRatio={0.85}
                textColor={Colors.questionDifficultyPickerTextFont}
                maxSegmentLabels={0}
                segmentColors={[
                    Colors.questionDifficultyPickerElementarySchoolLevel,
                    Colors.questionDifficultyPickerElementarySchoolLevel,
                    Colors.questionDifficultyPickerElementarySchoolLevel,
                    Colors.questionDifficultyPickerUpperElementarySchoolLevel,
                    Colors.questionDifficultyPickerHighSchoolLevel,
                    Colors.questionDifficultyPickerHighSchoolLevel,
                    Colors.questionDifficultyPickerHighSchoolLevel,
                    Colors.questionDifficultyPickerHighSchoolLevel,
                    Colors.questionDifficultyPickerHighSchoolLevel,
                    Colors.questionDifficultyPickerUpperHighSchoolLevel,
                    Colors.questionDifficultyPickerUniversityLevel,
                    Colors.questionDifficultyPickerUniversityLevel,
                    Colors.questionDifficultyPickerUniversityLevel
                ]}
            />
            <div className={styles.lowerLegendContainer}>
                <Typography classes={elementarySchoolTypo} noWrap>
                    {Locales.elementarySchool}
                </Typography>
                <Typography classes={universityTypo} noWrap>
                    {Locales.university}
                </Typography>
            </div>
            <Slider
                classes={sliderStyle}
                value={props.difficulty * 100}
                onChange={handleSliderChange}
                min={MIN_DIFFICULTY * 100}
                max={MAX_DIFFICULTY * 100}
            />
        </div>
    )
}

const setDifficultyText = (difficulty: number) => {
    if (difficulty <= elementaryUpperBoundary) {
        return Locales.difficultyForElementarySchool
    }
    if (difficulty.isBetween(elementaryUpperBoundary, elementaryHighUpperBoundary)) {
        return Locales.difficultyBetweenElementaryAndHighSchool
    }
    if (difficulty.isBetween(elementaryHighUpperBoundary, highSchoolUpperBoundary)) {
        return Locales.difficultyForHighSchool
    }
    if (difficulty.isBetween(highSchoolUpperBoundary, highUniversityUpperBoundary)) {
        return Locales.difficultyBetweenHighSchoolAndUniversity
    }
    return Locales.difficultyForUniversity
}

export default QuestionDifficultyPicker
