import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps } from "react-router"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Radio from "@material-ui/core/Radio"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import UploadPhoto from "@material-ui/icons/InsertPhotoRounded"
import DeleteIcon from "@material-ui/icons/DeleteRounded"
import Button from "@material-ui/core/Button"
import { handleImageUpload, UploadedImage } from "../../../utils/ImageUtils"
import ImageAnswerUpload from "../../common_components/ImageAnswerUpload/ImageAnswerUpload"
import QuestionDifficultyPicker from "../../common_components/QuestionDifficultyPicker/QuestionDifficultyPicker"
import Locales from "../../../localization/Localization"
import { TopicReducerStateTypes } from "../../../reducers/TopicsReducer"
import { SubtopicReducerStateTypes } from "../../../reducers/SubtopicsReducer"
import TopicsDataAccess from "../../../data_access/TopicsDataAccess"
import SubtopicsDataAccess from "../../../data_access/SubtopicsDataAccess"
import { executeCachedRequest } from "../../../utils/CacheValidator"
import Autocomplete, { Suggestion, SuggestionWithIcon } from "../../common_components/Autocomplete/Autocomplete"
import OrangeButton from "../../common_components/OrangeButton/OrangeButton"
import { AnswerLetter } from "../../../types/AnswerTypes"
import Loading from "../../common_components/Loading/Loading"
import Styles from "./EditQuestionProposal.style"
import { QuestionProposal, QuestionProposalRequest } from "../../../types/QuestionTypes"
import QuestionsDataAccess from "../../../data_access/QuestionsDataAccess"

interface QuestionInput {
    value?: string
    b64image?: UploadedImage
}

interface AnswerInput {
    type: "text" | "image"
    value: string | UploadedImage
}

interface QuestionState {
    topic: Suggestion | undefined
    subtopic: Suggestion | undefined
    question: QuestionInput
    A: AnswerInput
    B: AnswerInput
    C: AnswerInput
    D: AnswerInput
    correctAnswer: AnswerLetter | undefined
    difficulty: number
}

const INITIAL_DIFFICULTY = 4.5
const DEFAULT_QUESTION_INPUT: QuestionInput = { value: "" }
const DEFAULT_ANSWER_INPUT: AnswerInput = { type: "text", value: "" }

const EditQuestionProposal: React.FC<RouteComponentProps> = props => {
    const dispatch = useDispatch()
    const topics: TopicReducerStateTypes = useSelector((state: any) => state.TopicsReducer, shallowEqual)
    const subtopics: SubtopicReducerStateTypes = useSelector((state: any) => state.SubtopicsReducer, shallowEqual)

    const styles = Styles.styles({})
    const answersInputStyle = Styles.answersInputStyle({})
    const correctTextAnswerTypoStyle = Styles.correctTextAnswerTypoStyle({})
    const correctImageAnswerTypoStyle = Styles.correctImageAnswerTypoStyle({})
    const correctAnswerRadioStyle = Styles.correctAnswerRadioStyle({})
    const imageInQuestionAdornment = Styles.imageInQuestionAdornment({})
    const iconButtonStyle = Styles.iconButtonStyle({})
    const cancelButtonStyle = Styles.cancelButtonStyle({})

    const [loading, setLoading] = useState<boolean>(false)
    const [questionState, changeQuestionState] = useState<QuestionState>({
        topic: undefined,
        subtopic: undefined,
        question: DEFAULT_QUESTION_INPUT,
        A: DEFAULT_ANSWER_INPUT,
        B: DEFAULT_ANSWER_INPUT,
        C: DEFAULT_ANSWER_INPUT,
        D: DEFAULT_ANSWER_INPUT,
        correctAnswer: undefined,
        difficulty: INITIAL_DIFFICULTY
    })

    useEffect(() => {
        executeCachedRequest(TopicsDataAccess.getAllTopics(dispatch), topics.topicsCache)
        executeCachedRequest(SubtopicsDataAccess.getAllSubtopics(dispatch), subtopics.subtopicsCache)
        const proposalId = props.location.pathname.split("/editquestionproposals/")[1]
        const proposalFromSessionStorage = sessionStorage.getItem(proposalId)
        if (proposalFromSessionStorage != null) {
            const proposal: QuestionProposal = JSON.parse(proposalFromSessionStorage)
            let images = []
            if (proposal.questionImage != null) {
                images.push(proposal.questionImage)
            }
            if (proposal.imageAnswers) {
                images.push(proposal.answers[0], proposal.answers[1], proposal.answers[2], proposal.answers[3])
            }
            if (images.length > 0) {
                QuestionsDataAccess.getAllImagesFromQuestionProposal(dispatch)(images, setQuestionProposal(proposal))
            } else {
                setQuestionProposal(proposal)()
            }
        }
        // eslint-disable-next-line
    }, [])

    const setQuestionProposal = (proposal: QuestionProposal) => (images?: UploadedImage[]) => {
        let topic = {
            label: `#${proposal.topic.name}`,
            data: proposal.topic,
            value: proposal.topic.existingTopicId != null ? proposal.topic.existingTopicId : -1
        }
        let subtopic = {
            label: `#${proposal.subtopic.name}`,
            data: proposal.subtopic,
            value: proposal.subtopic.existingSubtopicId != null ? proposal.subtopic.existingSubtopicId : -1
        }
        let question: QuestionInput = {
            value: proposal.questionText
        }
        if (proposal.questionImage != null && images != null && images.length > 0) {
            question.b64image = images[0]
        }
        let A: AnswerInput, B: AnswerInput, C: AnswerInput, D: AnswerInput
        if (proposal.imageAnswers && images != null) {
            if (images.length === 4) {
                A = { type: "image", value: images[0] }
                B = { type: "image", value: images[1] }
                C = { type: "image", value: images[2] }
                D = { type: "image", value: images[3] }
            } else {
                A = { type: "image", value: images[1] }
                B = { type: "image", value: images[2] }
                C = { type: "image", value: images[3] }
                D = { type: "image", value: images[4] }
            }
        } else {
            A = { type: "text", value: proposal.answers[0] }
            B = { type: "text", value: proposal.answers[1] }
            C = { type: "text", value: proposal.answers[2] }
            D = { type: "text", value: proposal.answers[3] }
        }
        let correctAnswer: AnswerLetter = indexToAnswerLetter(proposal.answers.indexOf(proposal.correctAnswer))
        changeQuestionState({
            topic: topic,
            subtopic: subtopic,
            question: question,
            A: A,
            B: B,
            C: C,
            D: D,
            correctAnswer: correctAnswer,
            difficulty: proposal.predictedDifficulty
        })
    }

    const showLoader = () => setLoading(true)
    const hideLoader = () => setLoading(false)

    const handleTextInput = (name: keyof QuestionState) => (e: any) => {
        changeQuestionState({
            ...questionState,
            [name]: Object.assign({}, questionState[name], { value: e.target.value })
        })
    }

    const handleImage = (reference: "Q" | AnswerLetter) => (image: UploadedImage) => {
        if (reference === "Q") {
            changeQuestionState({
                ...questionState,
                question: { ...questionState.question, b64image: image }
            })
        } else {
            changeQuestionState({
                ...questionState,
                [reference]: { type: "image", value: image }
            })
        }
    }

    const uploadImage = (reference: "Q" | AnswerLetter) => handleImageUpload(handleImage(reference))

    const discardImage = (reference: "Q" | AnswerLetter) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (reference === "Q") {
            changeQuestionState({ ...questionState, question: { ...questionState.question, b64image: undefined } })
        } else {
            changeQuestionState({ ...questionState, [reference]: DEFAULT_ANSWER_INPUT })
        }
    }

    const changeCorrectAnswer = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            const correctAnswer = e.target.value as AnswerLetter
            changeQuestionState({ ...questionState, correctAnswer: correctAnswer })
        }
    }

    const onChangeDifficulty = (newDifficulty: number) => {
        changeQuestionState({ ...questionState, difficulty: newDifficulty })
    }

    const imageAsTypeOfAnswer =
        questionState.A.type === "image" ||
        questionState.B.type === "image" ||
        questionState.C.type === "image" ||
        questionState.D.type === "image"

    const renderImageInQuestion = (
        <InputAdornment position="end" classes={imageInQuestionAdornment}>
            <div className={styles.imageInQuestionContainer}>
                {questionState.question.b64image && (
                    <img
                        className={styles.imageInQuestionStyle}
                        src={questionState.question.b64image.presentation}
                        alt={"Q"}
                    />
                )}
                <IconButton classes={iconButtonStyle} onClick={discardImage("Q")}>
                    <DeleteIcon />
                </IconButton>
            </div>
        </InputAdornment>
    )

    const renderImageButton = (reference: "Q" | AnswerLetter) => (
        <InputAdornment position="end">
            <input
                accept="image/*"
                className={styles.imageInput}
                id={"image-input-" + reference}
                type="file"
                onChange={uploadImage(reference)}
            />
            <label htmlFor={"image-input-" + reference}>
                <IconButton aria-label="toggle upload image" component="span">
                    <UploadPhoto color="primary" />
                </IconButton>
            </label>
        </InputAdornment>
    )

    const renderTextAnswerField = (answerLetter: AnswerLetter) => {
        return (
            <div className={styles.textAnswersInputContainer}>
                <TextField
                    label={Locales.answerX.replace("#answerLetter#", answerLetter)}
                    classes={answersInputStyle}
                    margin="normal"
                    multiline
                    variant="outlined"
                    value={questionState[answerLetter].value}
                    onChange={handleTextInput(answerLetter)}
                    InputProps={{ endAdornment: renderImageButton(answerLetter) }}
                />
                <Radio
                    value={answerLetter}
                    color="primary"
                    checked={questionState.correctAnswer === answerLetter}
                    classes={correctAnswerRadioStyle}
                    inputProps={{ "aria-label": answerLetter }}
                    onChange={changeCorrectAnswer}
                />
            </div>
        )
    }

    const renderImageAnswerField = (answerLetter: AnswerLetter) => {
        return (
            <div className={styles.imageAnswersInputContainer}>
                <ImageAnswerUpload
                    answerLetter={answerLetter}
                    image={
                        questionState[answerLetter].type === "image"
                            ? (questionState[answerLetter].value as UploadedImage).presentation
                            : undefined
                    }
                    onUploadImage={uploadImage(answerLetter)}
                    onDiscardImage={discardImage(answerLetter)}
                />
                <Radio
                    value={answerLetter}
                    color="primary"
                    checked={questionState.correctAnswer === answerLetter}
                    classes={correctAnswerRadioStyle}
                    inputProps={{ "aria-label": answerLetter }}
                    onChange={changeCorrectAnswer}
                />
            </div>
        )
    }

    const handleTopicChange = (selectedTopic: Suggestion | SuggestionWithIcon) => {
        changeQuestionState({ ...questionState, topic: selectedTopic as Suggestion, subtopic: undefined })
    }

    const handleSubtopicChange = (selectedSubtopic: Suggestion | SuggestionWithIcon) => {
        changeQuestionState({ ...questionState, subtopic: selectedSubtopic as Suggestion })
    }

    const topicSuggestions = topics.topics.map(topic => {
        return { label: `#${topic.name}`, value: topic.id, numberOfQuestions: topic.numberOfQuestions }
    })

    const subtopicSuggestions = subtopics.subtopics
        .filter(subtopic => questionState.topic !== undefined && subtopic.topicId === questionState.topic.value)
        .map(subtopic => {
            return { label: `#${subtopic.name}`, value: subtopic.id, numberOfQuestions: subtopic.numberOfQuestions }
        })

    const mapAnswers = () => {
        if (imageAsTypeOfAnswer) {
            const questionA = questionState.A.value as UploadedImage
            const questionB = questionState.B.value as UploadedImage
            const questionC = questionState.C.value as UploadedImage
            const questionD = questionState.D.value as UploadedImage
            return [
                { value: questionA.filename, b64Value: questionA.b64value },
                { value: questionB.filename, b64Value: questionB.b64value },
                { value: questionC.filename, b64Value: questionC.b64value },
                { value: questionD.filename, b64Value: questionD.b64value }
            ]
        } else {
            const questionA = questionState.A.value as string
            const questionB = questionState.B.value as string
            const questionC = questionState.C.value as string
            const questionD = questionState.D.value as string
            return [{ value: questionA }, { value: questionB }, { value: questionC }, { value: questionD }]
        }
    }

    const answerLetterToIndex = (correctAnswer: AnswerLetter) => {
        if (correctAnswer === "A") {
            return 0
        }
        if (correctAnswer === "B") {
            return 1
        }
        if (correctAnswer === "C") {
            return 2
        }
        if (correctAnswer === "D") {
            return 3
        }
        return 0
    }

    const indexToAnswerLetter = (correctAnswer: number) => {
        if (correctAnswer === 0) {
            return "A"
        }
        if (correctAnswer === 1) {
            return "B"
        }
        if (correctAnswer === 2) {
            return "C"
        }
        if (correctAnswer === 3) {
            return "D"
        }
        return "A"
    }

    const findTopic = (topicId: number) => topics.topics.find(t => t.id === topicId)
    const findSubtopic = (subtopicId: number) => subtopics.subtopics.find(s => s.id === subtopicId)

    const onCreateProposal = (approve: boolean) => () => {
        showLoader()
        const topic = findTopic(questionState!.topic!.value as number)
        const subtopic = findSubtopic(questionState!.subtopic!.value as number)
        let topicProposal = null
        let subtopicProposal = null
        if (topic !== undefined) {
            topicProposal = {
                ...topic,
                locale: "hr", //TODO: remove hardcoded locales
                existingTopicId: topic.id
            }
        } else {
            topicProposal = {
                name: questionState!.topic!.data!.name,
                locale: "hr",
                existingTopicId: null
            }
        }
        if (subtopic !== undefined) {
            subtopicProposal = {
                ...subtopic,
                existingSubtopicId: subtopic.id
            }
        } else {
            subtopicProposal = {
                name: questionState!.subtopic!.data!.name,
                isPrivate: questionState!.subtopic!.data!.isPrivate,
                existingSubtopicId: null
            }
        }
        const questionProposal: QuestionProposalRequest = {
            questionImage: questionState.question.b64image
                ? {
                      filename: questionState.question.b64image.filename,
                      bytesB64: questionState.question.b64image.b64value
                  }
                : null,
            questionText: questionState.question.value || "",
            imageAnswers: imageAsTypeOfAnswer,
            answers: mapAnswers(),
            correctAnswer: answerLetterToIndex(questionState!.correctAnswer!),
            predictedDifficulty: questionState.difficulty,
            topic: topicProposal,
            subtopic: subtopicProposal
        }
        const proposalId = parseInt(props.location.pathname.split("/editquestionproposals/")[1])
        if (approve) {
            saveAndApproveQuestionProposal(proposalId, questionProposal)
        } else {
            saveQuestionProposal(proposalId, questionProposal)
        }
    }

    const saveQuestionProposal = (proposalId: number, questionProposal: QuestionProposalRequest) => {
        QuestionsDataAccess.updateQuestionProposal(dispatch)(proposalId, questionProposal, false, goBack)
    }

    const saveAndApproveQuestionProposal = (proposalId: number, questionProposal: QuestionProposalRequest) => {
        QuestionsDataAccess.updateQuestionProposal(dispatch)(proposalId, questionProposal, true, () => {
            QuestionsDataAccess.approveQuestionProposal(dispatch)(proposalId, goBack)
        })
    }

    const goBack = () => {
        const proposalId = props.location.pathname.split("/editquestionproposals/")[1]
        sessionStorage.removeItem(proposalId)
        hideLoader()
        props.history.goBack()
    }

    const disableCreateButton = !(
        questionState.topic !== undefined &&
        questionState.subtopic !== undefined &&
        (questionState.question.value || questionState.question.b64image) &&
        questionState.A.value &&
        questionState.B.value &&
        questionState.C.value &&
        questionState.D.value &&
        questionState.correctAnswer
    )

    return (
        <>
            <div className={styles.container}>
                <div className={styles.upperContainer}>
                    <div className={styles.leftContainer}>
                        <div className={styles.topicSubtopicContainer}>
                            <div className={styles.topicSubtopicInputContainer}>
                                <Autocomplete
                                    data={topicSuggestions}
                                    label={Locales.topic}
                                    value={questionState.topic}
                                    onChange={handleTopicChange}
                                />
                            </div>
                            <div className={styles.dot} />
                            <div className={styles.topicSubtopicInputContainer}>
                                <Autocomplete
                                    data={subtopicSuggestions}
                                    label={Locales.subtopic}
                                    value={questionState.subtopic}
                                    onChange={handleSubtopicChange}
                                />
                            </div>
                        </div>
                        <TextField
                            label={Locales.question}
                            margin="normal"
                            multiline
                            variant="outlined"
                            value={questionState.question.value}
                            onChange={handleTextInput("question")}
                            InputProps={{
                                endAdornment: questionState.question.b64image ? renderImageInQuestion : undefined
                            }}
                        />
                        <div className={styles.answersContainer}>
                            {imageAsTypeOfAnswer ? (
                                <>
                                    <Typography classes={correctImageAnswerTypoStyle}>
                                        {Locales.correctAnswer + ":"}
                                    </Typography>
                                    {renderImageAnswerField("A")}
                                    {renderImageAnswerField("B")}
                                    {renderImageAnswerField("C")}
                                    {renderImageAnswerField("D")}
                                </>
                            ) : (
                                <>
                                    <Typography classes={correctTextAnswerTypoStyle}>
                                        {Locales.correctAnswer + ":"}
                                    </Typography>
                                    {renderTextAnswerField("A")}
                                    {renderTextAnswerField("B")}
                                    {renderTextAnswerField("C")}
                                    {renderTextAnswerField("D")}
                                </>
                            )}
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                        <QuestionDifficultyPicker difficulty={questionState.difficulty} onChange={onChangeDifficulty} />
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <Button variant="outlined" color="secondary" classes={cancelButtonStyle} onClick={goBack}>
                        {Locales.cancel}
                    </Button>
                    <div className={styles.saveButtonContainer}>
                        <OrangeButton
                            height={36}
                            fontSize={14}
                            bold
                            label={Locales.save.toUpperCase()}
                            onClick={onCreateProposal(false)}
                            disabled={disableCreateButton}
                        />
                    </div>
                    <OrangeButton
                        height={36}
                        fontSize={14}
                        bold
                        label={Locales.saveAndApprove.toUpperCase()}
                        onClick={onCreateProposal(true)}
                        disabled={disableCreateButton}
                    />
                </div>
            </div>
            {loading && <Loading fullScreen />}
        </>
    )
}

export default withRouter(EditQuestionProposal)
