import { createStore, applyMiddleware, DeepPartial } from "redux"
import thunk from "redux-thunk"
import reduxImmutableStateInvariant from "redux-immutable-state-invariant"
import RootReducer from "../reducers/RootReducer"

const devStoreConstruction = (initialState?: DeepPartial<unknown>) =>
    createStore(RootReducer, initialState, applyMiddleware(thunk, reduxImmutableStateInvariant()))

const prodStoreConstruction = (initialState?: DeepPartial<unknown>) =>
    createStore(RootReducer, initialState, applyMiddleware(thunk))

export default process.env.NODE_ENV === "production" ? prodStoreConstruction : devStoreConstruction
