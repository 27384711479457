import React, { useEffect, useState } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { RouteComponentProps } from "react-router"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import Button from "@material-ui/core/Button"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import Typography from "@material-ui/core/Typography"
import { executeCachedRequest } from "../../../utils/CacheValidator"
import QuestionsDataAccess from "../../../data_access/QuestionsDataAccess"
import { QuestionsReducerStateTypes } from "../../../reducers/QuestionsReducer"
import Styles from "./ReportedQuestions.style"
import Locales from "../../../localization/Localization"
import { AnswerProps, AnswerLetter } from "../../../types/AnswerTypes"
import AnswersColumn from "../../common_components/AnswersColumn/AnswersColumn"
import AnswersGrid from "../../common_components/AnswersGrid/AnswersGrid"
import Loading from "../../common_components/Loading/Loading"
import DecisionModal from "../../common_components/DecisionModal/DecisionModal"
import { handleOpenModalClick } from "../../../utils/ModalMethods"
import { DecisionModalProps } from "../../../types/ModalTypes"
import { ExplanationTypeRequest, ReportedQuestionTypeResponse } from "../../../types/FlagTypes"
import { addStyles, StaticMathField } from "react-mathquill"
import { checkIfMathExpression, unwrapMathInputFromTags } from "../../../utils/MathLatexHandlers"

addStyles()

const NUMBER_OF_CHARS_FOR_WRAP = 64
const ANSWER_LETTERS: AnswerLetter[] = ["A", "B", "C", "D"]

const ReportedQuestions: React.FC<RouteComponentProps> = props => {
    const dispatch = useDispatch()
    const questions: QuestionsReducerStateTypes = useSelector((state: any) => state.QuestionsReducer, shallowEqual)

    const styles = Styles.styles({})
    const panelSummaryStyle = Styles.panelSummaryStyle({})
    const questionTypoStyle = Styles.questionTypoStyle({})
    const questionImageTypoStyle = Styles.questionImageTypoStyle({})
    const difficultyAuthorTitleTypoStyle = Styles.difficultyAuthorTitleTypoStyle({})
    const difficultyTypoStyle = Styles.difficultyTypoStyle({})
    const authorTypoStyle = Styles.authorTypoStyle({})
    const reportTitleTypoStyle = Styles.reportTitleTypoStyle({})
    const dismissButtonStyle = Styles.dismissButtonStyle({})
    const acceptButtonStyle = Styles.acceptButtonStyle({})

    const [loading, setLoading] = useState<boolean>(false)
    const hideLoader = () => setLoading(false)
    const showLoader = () => setLoading(true)

    const hideModal = () => {
        setModalState({ ...modalState, open: false })
        hideLoader()
    }

    const [modalState, setModalState] = useState<DecisionModalProps>({
        open: false,
        callback: () => {},
        accept: undefined,
        type: "question",
        reporter: [],
        reported: undefined,
        handleClose: hideModal,
        flagId: undefined
    })

    useEffect(() => {
        executeCachedRequest(QuestionsDataAccess.getQuestionReports(dispatch), questions.reportedQuestionsCache)
        //eslint-disable-next-line
    }, [])

    const requiresWrapping = (answer: AnswerProps) => answer.answer.length > NUMBER_OF_CHARS_FOR_WRAP

    const calculateAnswersLayout = (answers: AnswerProps[], correctAnswer: AnswerProps) => {
        if (answers.some(requiresWrapping)) {
            return <AnswersColumn answers={answers} correctAnswer={correctAnswer.answerLetter} />
        }
        return <AnswersGrid answers={answers} correctAnswer={correctAnswer.answerLetter} type="text" />
    }

    const resolveReportReason = (reason: string) => {
        if (reason === "INCORRECT") {
            return Locales.incorrect
        }
        if (reason === "INAPPROPRIATE") {
            return Locales.inappropriate
        }
        if (reason === "SPAM") {
            return Locales.spam
        }
        if (reason === "HATE_SPEECH") {
            return Locales.hateSpeech
        }
        if (reason === "HARASSMENT") {
            return Locales.harassment
        }
    }

    const onDismiss = (flagId: number, payload: ExplanationTypeRequest) => {
        showLoader()
        QuestionsDataAccess.dismissQuestionReport(dispatch)(flagId, payload, hideModal)
    }

    const onAccept = (flagId: number, payload: ExplanationTypeRequest) => {
        showLoader()
        QuestionsDataAccess.approveQuestionReport(dispatch)(flagId, payload, hideModal)
    }

    const handleClickAccept = (reportedQuestion: ReportedQuestionTypeResponse) => () => {
        handleOpenModalClick(reportedQuestion, setModalState, true, onAccept)
    }

    const handleClickDismiss = (reportedQuestion: ReportedQuestionTypeResponse) => () => {
        handleOpenModalClick(reportedQuestion, setModalState, false, onDismiss)
    }

    return (
        <div>
            {questions.reportedQuestions.length > 0 ? (
                questions.reportedQuestions.map((reportedQuestion, index) => {
                    const answers: AnswerProps[] = reportedQuestion.questionAnswers.map((answer, index) => {
                        return { answerLetter: ANSWER_LETTERS[index], answer: answer }
                    })
                    const correctAnswer: AnswerProps | undefined = answers.find(
                        answer => answer.answer === reportedQuestion.questionCorrectAnswer
                    )

                    return (
                        <ExpansionPanel key={index}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon color="primary" />}
                                classes={panelSummaryStyle}
                            >
                                {reportedQuestion.questionImage != null &&
                                reportedQuestion.questionText.length === 0 ? (
                                    <Typography
                                        classes={questionImageTypoStyle}
                                    >{`(${Locales.questionContainsImage})`}</Typography>
                                ) : checkIfMathExpression(reportedQuestion.questionText) ? (
                                    <div className={styles.mathQuestionContainer}>
                                        <StaticMathField>
                                            {unwrapMathInputFromTags(reportedQuestion.questionText)}
                                        </StaticMathField>
                                    </div>
                                ) : (
                                    <Typography
                                        classes={questionTypoStyle}
                                        onClick={event => event.stopPropagation()}
                                        onFocus={event => event.stopPropagation()}
                                    >
                                        {reportedQuestion.questionText}
                                    </Typography>
                                )}
                                <div className={styles.difficultyAuthorContainer}>
                                    <Typography
                                        classes={difficultyAuthorTitleTypoStyle}
                                    >{`${Locales.difficulty}:`}</Typography>
                                    <Typography classes={difficultyTypoStyle}>
                                        {reportedQuestion.questionDifficulty.toFixed(2)}
                                    </Typography>
                                </div>
                                <div className={styles.difficultyAuthorContainer}>
                                    <Typography
                                        classes={difficultyAuthorTitleTypoStyle}
                                    >{`${Locales.author}:`}</Typography>
                                    <Typography classes={authorTypoStyle}>
                                        {reportedQuestion.owner != null
                                            ? `${reportedQuestion.owner.firstName} ${reportedQuestion.owner.lastName}`
                                            : "Srednja.hr"}
                                    </Typography>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className={styles.detailsContainer}>
                                    <div className={styles.answersContainer}>
                                        {reportedQuestion.questionImage != null && (
                                            <img src={reportedQuestion.questionImage} alt="question" />
                                        )}
                                        {reportedQuestion.questionImageAnswers ? (
                                            <AnswersGrid
                                                answers={answers}
                                                correctAnswer={correctAnswer!.answerLetter!}
                                                type="image"
                                            />
                                        ) : (
                                            calculateAnswersLayout(answers, correctAnswer!)
                                        )}
                                    </div>
                                    {reportedQuestion.reports.map((report, index) => (
                                        <div key={index} className={styles.reportContainer}>
                                            <div className={styles.reportAuthorContainer}>
                                                <Typography
                                                    classes={difficultyAuthorTitleTypoStyle}
                                                >{`${Locales.questionReportedBy}:`}</Typography>
                                                <Typography classes={authorTypoStyle}>
                                                    {`${report.flaggedBy.firstName} ${report.flaggedBy.lastName}`}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    classes={reportTitleTypoStyle}
                                                >{`${Locales.reason}:`}</Typography>
                                                <Typography>{resolveReportReason(report.reason)}</Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    classes={reportTitleTypoStyle}
                                                >{`${Locales.description}:`}</Typography>
                                                <Typography>{report.description}</Typography>
                                            </div>
                                        </div>
                                    ))}
                                    <div className={styles.actionsContainer}>
                                        <div className={styles.topicSubtopicContainer}>
                                            <Typography
                                                classes={difficultyAuthorTitleTypoStyle}
                                            >{`#${reportedQuestion.questionTopic.name}`}</Typography>
                                            <div className={styles.dotDelimiter} />
                                            <Typography
                                                classes={difficultyAuthorTitleTypoStyle}
                                            >{`#${reportedQuestion.questionSubtopic.name}`}</Typography>
                                        </div>
                                        <div>
                                            <Button
                                                classes={dismissButtonStyle}
                                                onClick={handleClickDismiss(reportedQuestion)}
                                            >
                                                {Locales.dismiss}
                                            </Button>
                                            <Button
                                                classes={acceptButtonStyle}
                                                onClick={handleClickAccept(reportedQuestion)}
                                            >
                                                {Locales.accept}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )
                })
            ) : (
                <Typography classes={questionTypoStyle}>{Locales.thereIsNoReportedQuestions}</Typography>
            )}
            {loading && <Loading fullScreen />}
            <DecisionModal {...modalState} />
        </div>
    )
}

export default ReportedQuestions
